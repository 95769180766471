import React, { useState } from 'react';
import {
  ImageInput,
  InputHeader,
  Modal
} from '@bindystreet/bindystreet.kit.react';
import { readImageFile } from '@bindystreet/bindystreet.kit.react';
import ImageViewer from './ImageViewer';

type Props = {
  resolution?: { width: number; height: number };
  onUploadComplete: (image: File) => void;
  label?: string;
  isFullWidth?: boolean;
  customPlaceholderSize?: number;
  customHeightPx?: string;
  customWidthPx?: string;
  fit?: 'fill' | 'cover' | 'contain';
  isMandatory?: boolean;
  autoMargins?: boolean;
  imageUrl?: string;
  deleteImage: () => void;
  isLoading?: boolean;
  autoOpen?: boolean;
  placeholderOrientation?: 'horizontal' | 'vertical';
  onCancel?: () => void;
  error?: string | false;
  accept?: string | string[];
  isEnabled?: boolean;
  progress: number;
  isUploadEnabled?: boolean;
  handleDrag?: React.DragEventHandler<HTMLDivElement>;
  handleDrop?: React.DragEventHandler<HTMLDivElement>;
};

function ImageUploaderInput(props: Props) {
  const {
    resolution,
    onUploadComplete,
    label,
    isFullWidth,
    customHeightPx,
    customWidthPx,
    fit,
    isMandatory = true,
    autoMargins,
    imageUrl,
    deleteImage,
    isLoading = false,
    autoOpen = false,
    placeholderOrientation = 'vertical',
    onCancel,
    error,
    customPlaceholderSize,
    accept,
    isEnabled = true,
    isUploadEnabled = true,
    progress,
    handleDrag,
    handleDrop
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(autoOpen);
  const [imageToBeViewed, setImageToBeViewed] = useState<string | undefined>(
    imageUrl
  );

  const onDrop = async (acceptedFiles: File[]) => {
    setIsModalOpen(false);
    const imageFile = acceptedFiles[0];
    const imageUrl = await readImageFile(imageFile);
    setImageToBeViewed(imageUrl);
    onUploadComplete(imageFile);
  };

  const onDeleteImage = () => {
    setIsModalOpen(false);
    setImageToBeViewed(undefined);
    if (onCancel) {
      onCancel();
    }
    if (deleteImage) {
      deleteImage();
    }
  };

  return (
    <div className="flex flex-col">
      <InputHeader label={label} isMandatory={isMandatory} />
      <div>
        {isModalOpen && !!imageToBeViewed && (
          <Modal
            centerTop="10%"
            padding="none"
            position="fixedCenter"
            size="2xl"
            isMenuOpen={isModalOpen && !!imageToBeViewed}
            overlay
            bgColour="bg-transparent"
          >
            <ImageViewer
              onClose={() => {
                setIsModalOpen(false);
                if (onCancel) {
                  onCancel();
                }
                setImageToBeViewed(undefined);
              }}
              imageSrc={imageToBeViewed}
            />
          </Modal>
        )}
        <div
          id={imageUrl}
          className={`${isEnabled ? '' : 'opacity-40'}`}
          draggable={!!imageUrl}
          onDragOver={(ev) => ev.preventDefault()}
          onDragStart={handleDrag}
          onDrop={handleDrop}
        >
          <ImageInput
            autoMargins={autoMargins}
            fit={fit}
            isFullWidth={isFullWidth}
            customHeightPx={customHeightPx}
            customWidthPx={customWidthPx}
            imageUrl={imageUrl}
            onDrop={onDrop}
            isLoading={isLoading}
            isOldVersion={false}
            deleteImage={onDeleteImage}
            progress={progress}
            isResizing={false}
            resolution={resolution}
            customPlaceholderSize={customPlaceholderSize}
            placeholderOrientation={placeholderOrientation}
            error={error}
            accept={accept}
            isUploadEnabled={isUploadEnabled}
            onClick={() => {
              setIsModalOpen(true);
              setImageToBeViewed(imageUrl);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ImageUploaderInput;
