import {
  ClaimRequestStatus,
  IBaseListingRequest
} from 'Colugo/interfaces/business/IBaseListingRequest';
import EmptyCard from 'components/shared/EmptyCard';
import AcceptedRejectedRequests from './AcceptedRejectedRequests';
import NewRequests from './NewRequest';

type Props<T> = {
  searchResults: T[];
};

function RequestsSearchResult<T extends IBaseListingRequest>(props: Props<T>) {
  const { searchResults } = props;
  const acceptedRejectedRequestsSearchResults = searchResults.filter(
    (searchResult) =>
      searchResult.status === ClaimRequestStatus.Approved ||
      searchResult.status === ClaimRequestStatus.Rejected
  );

  const pendingRequestsearchResults = searchResults.filter(
    (searchResult) => searchResult.status === ClaimRequestStatus.Requested
  );

  const allResults = acceptedRejectedRequestsSearchResults.concat(
    pendingRequestsearchResults
  );

  return (
    <div>
      {allResults.length === 0 ? (
        <EmptyCard text="No results found" />
      ) : (
        <>
          {pendingRequestsearchResults.length > 0 && (
            <NewRequests requests={pendingRequestsearchResults} />
          )}
          {acceptedRejectedRequestsSearchResults.length > 0 && (
            <AcceptedRejectedRequests
              requests={acceptedRejectedRequestsSearchResults}
            />
          )}
        </>
      )}
    </div>
  );
}

export default RequestsSearchResult;
