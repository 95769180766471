import { Lifecycle, scoped } from 'tsyringe';
import {
  swrRequestResponse,
  useGetHttpClient
} from '../../provider/useGetHttpClient';
import AbstractOperations from '../AbstractOperations';
import { requestResponse } from 'Colugo/provider/HttpClient';
import { IPromotion } from 'Colugo/interfaces/promotions/IPromotion';
import { IOrderedItem } from 'Colugo/interfaces/common';

@scoped(Lifecycle.ContainerScoped)
class PromotionOperations extends AbstractOperations {
  protected basePath: string = 'promotion';

  public async createAsync(promotion: IPromotion): requestResponse<IPromotion> {
    return await this.executePost(``, promotion);
  }

  public async getAsync(promotionId: string): requestResponse<IPromotion> {
    return await this.executeGet(`${promotionId}`);
  }

  public async updateAsync(promotion: IPromotion): requestResponse {
    return await this.executePut(`${promotion.id}`, promotion);
  }

  public async updateListingPromotionsOrderAsync(
    listingId: string,
    promotionId: string,
    order: number
  ): requestResponse<IOrderedItem[]> {
    return await this.executePut(
      `/listing/${listingId}/promotion/${promotionId}/order/${order}`
    );
  }

  public async updateEventPromotionsOrderAsync(
    eventId: string,
    promotionId: string,
    order: number
  ): requestResponse<IOrderedItem[]> {
    return await this.executePut(
      `/event/${eventId}/promotion/${promotionId}/order/${order}`
    );
  }

  public async searchPromotionsAsync(
    query?: string
  ): requestResponse<IPromotion[]> {
    return await this.executeGet(`search?query=${query}`);
  }

  public async deleteAsync(promotionId: string): requestResponse {
    return await this.executeDelete(`${promotionId}`);
  }
}

const useReqListPromotionsAsync = (): swrRequestResponse<IPromotion[]> => {
  return useGetHttpClient(
    'promotion/recentlyUpdated',
    {
      revalidateOnFocus: true
    },
    false
  );
};

const useReqGetPromotionAsync = (
  promotionId?: string
): swrRequestResponse<IPromotion> => {
  return useGetHttpClient(
    `promotion/${promotionId}`,
    {
      revalidateOnFocus: true
    },
    false
  );
};

export default PromotionOperations;
export { useReqListPromotionsAsync, useReqGetPromotionAsync };
