import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import EventOperations from 'Colugo/operations/events/EventOperations';
import EventCard from 'components/events/EventCard';
import ConfirmationPopup from 'components/shared/ConfirmationPopup';
import EmptyCard from 'components/shared/EmptyCard';
import HintBox from 'components/shared/HintBox';
import { ReactComponent as HintActive } from 'images/dashboard/infoActive.svg';
import { ManagerContext } from 'provider/manager/managerProvider';
import { useContext, useEffect, useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { getEmailLink } from 'utility/constants/constants';
import { useErrorToast } from 'utility/hooks/useErrorToast';

export const maximumEventCount = 1;

const eventOperations = container.resolve(EventOperations);

function ManageEvent() {
  const { errorToast } = useErrorToast();
  const { listing, isListingLoading } = useContext(ManagerContext);
  const [localEvents, setLocalEvents] = useState<IEvent[]>(
    listing?.events || []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [eventToBeDeleted, setEventToBeDeleted] = useState<
    IEvent | undefined
  >();

  useEffect(() => {
    setLocalEvents(listing?.events || []);
  }, [listing?.events]);

  async function handleDeleteEventAsync() {
    if (!eventToBeDeleted?.id) {
      errorToast('No event found to be deleted. Please try again.');
      return;
    }
    setIsLoading(true);
    const { error } = await eventOperations.deleteAsync(eventToBeDeleted.id);
    setIsLoading(false);
    if (error) {
      errorToast('Something went wrong, please try again.');
      return;
    }
    toast.success('Event deleted.');
    setEventToBeDeleted(undefined);
    setLocalEvents(
      localEvents?.filter((e) => e.id !== eventToBeDeleted.id) || []
    );
  }

  const confirmDeleteModal = (
    <ConfirmationPopup
      isModalOpen={!!eventToBeDeleted}
      handleClickConfirmButton={handleDeleteEventAsync}
      closeModal={() => setEventToBeDeleted(undefined)}
      popupLabel={`Delete this Event?`}
      confirmButtonText={`Delete Event`}
      isErrorButton={true}
      isLoading={isLoading}
    >
      <div className="font-nunito text-lg font-normal">
        Please note, once an event is permanently deleted, this action cannot be
        undone. Are you sure you want to delete this event?
      </div>
    </ConfirmationPopup>
  );
  const eventCards =
    localEvents && localEvents.length > 0 ? (
      localEvents.map((e) => {
        return (
          <EventCard
            key={e.id}
            event={e}
            onClickDelete={() => setEventToBeDeleted(e)}
            hasPromotion={(e.promotions && e.promotions.length > 0) || false}
          />
        );
      })
    ) : (
      <EmptyCard text="No Events found." />
    );

  const isWithinEventLimit =
    !localEvents || localEvents.length < maximumEventCount;

  if (isListingLoading) {
    return <Spinner />;
  }

  const header = (
    <div>
      <div className="flex flex-row">
        <p className="font-inter text-background text-3xl font-bold mb-4">
          Manage Events
        </p>
        <div className=" flex-grow" />
        <div
          className={`bg-primary text-white flex flex-row rounded-lg py-1.5 px-3 cursor-pointer`}
          style={{ height: '38px' }}
          onClick={() => {
            toast.dismiss();
            if (isWithinEventLimit) {
              navigate(PuffinRoutes.ManagerCreateEvent);
            } else {
              errorToast(
                `Maximum of ${maximumEventCount} Event${
                  maximumEventCount > 1 ? 's' : ''
                }`
              );
            }
          }}
        >
          Create Event <AiFillPlusCircle size={18} className="ml-2 mt-1" />
        </div>
      </div>
      <HintBox padding="10px">
        <div className="flex flex-row">
          <HintActive className="ml-2" width={25} />
          <p className="w-full ml-2 mt-1">
            Maximum of {maximumEventCount} event
            {maximumEventCount > 1 ? 's' : ''}, please{' '}
            <span
              className="underline cursor-pointer"
              onClick={() =>
                (window.location.href = getEmailLink('RE:Upgrade Account'))
              }
            >
              contact us
            </span>{' '}
            if you would like to upgrade your account
          </p>
        </div>
      </HintBox>
    </div>
  );
  return (
    <>
      <div className="mt-10 pb-10 w-full h-auto" style={{ width: '1162px' }}>
        {header}
        {eventCards}
        {confirmDeleteModal}
      </div>
    </>
  );
}

export default ManageEvent;
