import { IAddress } from 'Colugo/interfaces/listing/IAddress';
import dayjs from 'dayjs';

export function formatAddress(address: IAddress) {
  const {
    houseName,
    houseNumber,
    street,
    city,
    postcode,
    addressString,
    country
  } = address;
  if (!houseName && !houseNumber && !street && !city && !postcode) {
    return addressString;
  }
  const formattedPostcode = postcode
    ? `${postcode?.slice(0, -3)} ${postcode?.slice(-3)}`
    : '';
  const formattedAddress = `${houseName ? houseName + ',' : ''} ${
    !houseName && houseNumber ? houseNumber : ''
  } ${street || ''}${houseNumber || street ? ',' : ''} ${
    city ? city + ',' : ''
  }  ${formattedPostcode ? formattedPostcode + ',' : ''} ${country || ''}`;

  return formattedAddress.replace(/\s{2,}/g, ' ');
}

export function formatDateWithTime(updatedAt: string) {
  const localDate = dayjs(updatedAt);
  return localDate.format('DD MMMM YYYY HH:mm');
}

export function addSpacesBeforeCapitalLetters(inputString: string) {
  return inputString.replace(/([A-Z])/g, ' $1').trim();
}

export function formatDate(updatedAt: string, includeYear: boolean = true) {
  const localDate = dayjs(updatedAt);
  if (includeYear) {
    return localDate.format('DD MMMM, YYYY');
  } else {
    return localDate.format('DD MMMM');
  }
}
export function formatDateForCard(dateString: string) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  const formattedDate = new Date(dateString).toLocaleString('en-GB', options);
  return formattedDate.replace('at', '@');
}

export function formatPhoneNumber(phoneNumber: string) {
  if (phoneNumber) {
    return (
      phoneNumber.substring(0, 5) +
      ' ' +
      phoneNumber.substring(5, phoneNumber.length - 1)
    );
  }
}
