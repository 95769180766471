import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { IUser } from 'Colugo/interfaces/identity';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { ReactComponent as ProfileIcon } from 'images/dashboard/profileIcon.svg';
import React from 'react';

type Props = {
  item: IListing | IEvent;
  setBusinessUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
};

function AccountNameProfileButton({ setBusinessUser, item }: Props) {
  const businessOwner =
    (item as IListing).businessOwner ?? (item as IEvent).listing?.businessOwner;

  return (
    <div
      onClick={businessOwner ? () => setBusinessUser(businessOwner) : undefined}
      className="flex flex-row justify-center items-center w-auto cursor-pointer rounded-full border-outline border py-2 px-3 font-inter text-black"
    >
      <ProfileIcon height={22} width={22} className="mt-0.5 mr-0.5 " />
      <label className="cursor-pointer">
        {businessOwner?.firstName || businessOwner?.lastName
          ? `${businessOwner?.firstName ?? ''} ${businessOwner?.lastName ?? ''}`
          : 'Ember Business'}
      </label>
    </div>
  );
}

export default AccountNameProfileButton;
