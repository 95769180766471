import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { IIoiValidationError } from 'Colugo/interfaces/IIoiValidationError';
import { useReqListCategories } from 'Colugo/operations/categories/CategoryOperations';
import EventOperations from 'Colugo/operations/events/EventOperations';
import DangerZone from 'components/listings/general/DangerZone';
import ImageSettings from 'components/listings/general/ImageSettings';
import { UserContext } from 'provider/user/userProvider';
import { useContext } from 'react';
import { useAsyncDebounce } from 'react-table';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import GeneralEventSettings from './GeneralEventSettings';

const eventOperations = container.resolve(EventOperations);

type Props = {
  event: IEvent;
  setLocalEvent: (localEvent: IEvent) => void;
  updateIsActiveForInvalidEventAsync: () => Promise<boolean>;
  updateEventAsync: (updatedEvent: IEvent) => Promise<boolean>;
  validationErrors: IIoiValidationError[];
  mutate: (event: IEvent, revalidate?: boolean) => void;
};

function GeneralEventTabContainer(props: Props) {
  const {
    event,
    setLocalEvent,
    updateEventAsync,
    updateIsActiveForInvalidEventAsync,
    validationErrors,
    mutate
  } = props;
  const { errorToast } = useErrorToast();
  const { data: categories, isLoading } = useReqListCategories();
  const { isSuperAdmin } = useContext(UserContext);

  const debouncedAddCategoryAsync = useAsyncDebounce(
    async (localEventId: string, categoryId: string) => {
      return await eventOperations.addCategoryAsync(localEventId, categoryId);
    },
    200
  );

  const debouncedRemoveCategoryAsync = useAsyncDebounce(
    async (localEventId: string, categoryId: string) => {
      return await eventOperations.removeCategoryAsync(
        localEventId,
        categoryId
      );
    },
    200
  );

  async function handleAddCategoryAsync(categoryId: string) {
    if (!categories || categories.length === 0) {
      toast.warn('No categories found.');
      return false;
    }
    const categoryToAdd = categories.find((c) => c.id === categoryId);

    if (!categoryToAdd) {
      toast.warn('No category selected');
      return false;
    }
    const updatedEvent = {
      ...event,
      categories: [...(event?.categories || []), categoryToAdd]
    };

    setLocalEvent(updatedEvent);

    const { error } = await debouncedAddCategoryAsync(event.id!, categoryId);

    if (error) {
      errorToast('Failed to add category, try again later');
      setLocalEvent(event);
      return false;
    }

    mutate(updatedEvent);
    return true;
  }

  async function handleRemoveCategoryAsync(index: number, categoryId: string) {
    if (!categories || !event.categories) {
      toast.warn('No category selected');
      return;
    }
    const categoryToRemove = categories[index];
    if (!categoryToRemove) {
      errorToast('No category found, please refresh.');
      return;
    }
    const newCategories = event.categories!.filter(
      (c) => c.id !== categoryToRemove.id
    );
    let updatedEvent = {
      ...event,
      categories: newCategories
    };
    setLocalEvent(updatedEvent);

    const { error } = await debouncedRemoveCategoryAsync(event.id!, categoryId);

    if (error) {
      errorToast('Failed to remove category, try again later');
      setLocalEvent(event);
      return;
    }

    if (newCategories.length === 0) {
      updatedEvent = {
        ...event,
        isActive: false,
        categories: newCategories
      };
      setLocalEvent(updatedEvent);
      await updateIsActiveForInvalidEventAsync();
    }
    mutate(updatedEvent);
  }

  async function addEventImageAsync(imageUrl: string) {
    let newEventImages: string[];
    if (!imageUrl) {
      errorToast('No Image was selected, Please try again');
      return;
    }
    if (event.images) {
      newEventImages = [...event.images, imageUrl];
    } else {
      newEventImages = [imageUrl];
    }
    setLocalEvent({ ...event, images: newEventImages });
    const isSuccessful = await updateEventAsync({
      ...event,
      images: newEventImages
    });

    if (!isSuccessful) {
      setLocalEvent(event);
    }
  }

  async function deleteImageAsync(index: number) {
    const newEventImages = event.images?.filter((_, i) => i !== index) || [];
    setLocalEvent({ ...event, images: newEventImages });
    const isSuccessful = await updateEventAsync({
      ...event,
      images: newEventImages
    });
    if (!isSuccessful) {
      setLocalEvent(event);
    }
  }

  if (!categories || isLoading) {
    return <Spinner />;
  }

  async function updateImageOrderAsync(images: string[]) {
    const isSuccessful = await updateEventAsync({
      ...event,
      images
    });
    if (!isSuccessful) {
      setLocalEvent(event);
    }
  }

  return (
    <div className="flex flex-col ml-auto mr-auto" style={{ width: '80vw' }}>
      <div className="h-auto pb-10 w-full mt-8 flex flex-col items-start px-8 border-b-2 border-gray">
        <GeneralEventSettings
          localEvent={event}
          setLocalEvent={setLocalEvent}
          updateEventAsync={updateEventAsync}
          categories={categories}
          handleRemoveCategoryAsync={handleRemoveCategoryAsync}
          handleAddCategoryAsync={handleAddCategoryAsync}
          validationErrors={validationErrors}
        />
      </div>
      <div className="h-auto pb-10 w-full mt-8 flex flex-col items-start px-8 border-b-2 border-gray">
        <ImageSettings
          localEntity={event}
          addImageAsync={addEventImageAsync}
          deleteImageAsync={deleteImageAsync}
          validationErrors={validationErrors}
          label="Events"
          updateImagesOrderAsync={updateImageOrderAsync}
        />
      </div>
      {isSuperAdmin && (
        <div className="h-auto pb-8 w-full mt-4 flex flex-col items-start px-8">
          <DangerZone
            localEntity={event}
            label="Event"
            onDeleteAsync={async () =>
              await eventOperations.deleteAsync(event.id!)
            }
          />
        </div>
      )}
    </div>
  );
}

export default GeneralEventTabContainer;
