import { TiWarning } from 'react-icons/ti';
import { toast } from 'react-toastify';

export const useErrorToast = () => {
  const errorToast = (message: string) => {
    toast.info(
      <div className="flex items-center">
        <TiWarning className="text-primary" size={24} />
        <span className="text-onSurface font-inter ml-2">{message}</span>
      </div>,
      {
        className: 'font-nunito'
      }
    );
  };

  return {
    errorToast
  };
};
