import { IPromotion } from 'Colugo/interfaces/promotions/IPromotion';
import { ITag } from 'Colugo/interfaces/tags';
import PromotionCard from 'components/shared/PromotionCard';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

interface IDragItem {
  index: number;
  id: string;
  type: string;
}

const ItemTypes = {
  ROW: 'promotion'
};

type Props = {
  promotion: IPromotion;
  index: number;
  movePromotionAsync: (dragIndex: number, hoverIndex: number) => Promise<void>;
  onClickEdit: () => void;
  onClickDelete: () => void;
  tags: ITag[];
};

function DraggablePromotion(props: Props) {
  const {
    index,
    promotion,
    onClickEdit,
    onClickDelete,
    movePromotionAsync,
    tags
  } = props;

  const dragRef = useRef<HTMLDivElement | null>(null);
  const dropRef = useRef<HTMLDivElement | null>(null);

  const [{ isOver }, drop] = useDrop<
    IDragItem,
    void,
    { canDrop: boolean; isOver: boolean }
  >({
    accept: ItemTypes.ROW,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver()
    }),
    drop: (item: IDragItem) => {
      movePromotionAsync(item.index, index);
    }
  });

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.ROW,
      item: () => {
        return { index: index };
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging()
      }),
      canDrag: true
    }),
    []
  );

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <div ref={dropRef} style={{ opacity }} className={`cursor-grabbing`}>
      <div
        ref={dragRef}
        className={`whitespace-no-wrap h-full relative rounded-xl mt-2 ${
          isOver && `border-2 border-navyLight`
        } cursor-grab`}
      >
        <PromotionCard
          promotion={promotion}
          onClickDelete={onClickDelete}
          onClickEdit={onClickEdit}
          tags={tags}
          width="full"
          canDrag={true}
        />
      </div>
    </div>
  );
}

export default DraggablePromotion;
