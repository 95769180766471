import { IListing } from 'Colugo/interfaces/listing/IListing';
import ListingOperations from 'Colugo/operations/listings/ListingOperations';
import ListingCard from 'components/shared/ListingCard';
import { ListingsContext } from 'provider/listings/listingsProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { container } from 'tsyringe';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import EntitySearch, { SearchedEntityType } from './EntitySearch';

const listingOperations = container.resolve(ListingOperations);

function RecentListingsEditor() {
  const { errorToast } = useErrorToast();
  const { listings } = useContext(ListingsContext);
  const [localListings, setLocalListings] = useState<IListing[]>(listings);
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!!listings) {
      setLocalListings(listings);
    }
  }, [listings]);

  const onSearchListings = useCallback(
    async (query: string) => {
      if (!query) {
        return;
      }
      const { data, error } = await listingOperations.searchListingsAsync(
        query
      );

      setIsSearchMode(true);
      if (!data || error) {
        setIsSearchMode(false);
        errorToast('An error occured, please try again');
        return;
      }
      setLocalListings(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setLocalListings, setIsSearchMode]
  );

  const onClearSearchQuery = useCallback(() => {
    setLocalListings(listings);
    setIsSearchMode(false);
  }, [listings, setIsSearchMode, setLocalListings]);

  return (
    <div className="flex flex-col pt-12">
      <div style={{ marginLeft: '152px' }}>
        <EntitySearch
          setIsSearchMode={setIsSearchMode}
          onSearchAsync={onSearchListings}
          onClearSearchQuery={onClearSearchQuery}
          activeSearchType={SearchedEntityType.Listing}
          onClickCreateButton={() => navigate(PuffinRoutes.CreateListing)}
        />
      </div>
      <div className="pr-8">
        <div
          className={`font-inter font-bold text-2xl ${
            isSearchMode && 'border-grayBorder border-b-2'
          } py-1`}
          style={{ marginLeft: '152px', lineHeight: '27px', fontSize: '18px' }}
        >
          {isSearchMode ? 'Business Search' : 'Recently edited businesses'}
        </div>
      </div>
      <div className="flex flex-row flex-wrap pl-36 mt-1">
        {localListings
          ?.slice(0, isSearchMode ? localListings.length : 9)
          .map((listing, i) => (
            <Link
              className="w-auto cursor-pointer"
              key={i}
              style={{ margin: '10px' }}
              to={`listing/${listing.id}/general`}
            >
              <ListingCard listing={listing} />
            </Link>
          ))}
      </div>
      <div className="pr-8">
        {isSearchMode && localListings.length > 0 && (
          <div className="flex flex-row mt-4 ml-36 py-12 items-center justify-center bg-theme3 rounded-lg border font-nunito text-base font-normal border-grayBorder ">
            Can't find what you're looking for? Update your search query to see
            more results
          </div>
        )}
      </div>
      <div className="pr-8">
        {isSearchMode && localListings.length === 0 && (
          <div
            className="flex flex-row items-center justify-center ml-36 mt-2 font-nunito text-lg text-center border-dashed rounded bg-lightGreen border-2 border-primaryTeal"
            style={{ height: '371px' }}
          >
            <span>No results found, please search again or </span>
            <span
              className="underline font-bold text-lg ml-1 font-nunito cursor-pointer"
              onClick={() => navigate(PuffinRoutes.CreateListing)}
            >
              create a new Business
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default RecentListingsEditor;
