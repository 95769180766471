import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { FaSave } from 'react-icons/fa';

interface TimerState {
  lastSaveTime: dayjs.Dayjs;
  timeSince: number;
}

type Props = {
  entitySaved: boolean;
  setListingSaved: (listingSaved: boolean) => void;
};

function AutoSaveTimer(props: Props) {
  const { entitySaved: listingSaved, setListingSaved } = props;

  const [timer, setTimer] = useState<TimerState>({
    lastSaveTime: dayjs(),
    timeSince: 0
  });

  useEffect(() => {
    const startTimer = () => {
      return setInterval(() => {
        setTimer({
          lastSaveTime: timer.lastSaveTime,
          timeSince: dayjs().diff(timer.lastSaveTime, 's')
        });
      }, 5000);
    };

    if (listingSaved) {
      setListingSaved(false);
      setTimer({
        timeSince: 0,
        lastSaveTime: dayjs()
      });
    }
    const interval = startTimer();
    return () => clearInterval(interval);
  }, [timer.lastSaveTime, listingSaved, setListingSaved]);

  const minutes = Math.floor(timer.timeSince / 60);

  return (
    <div className="bg-black px-4 py-3 rounded-lg flex flex-row items-center justify-center text-white">
      <div>
        <FaSave size={16} />
      </div>
      <div className="ml-3">
        {minutes === 0
          ? `AutoSaved ${timer.timeSince} sec ago`
          : `AutoSaved ${minutes} ${minutes > 1 ? 'mins' : 'min'} ago`}
      </div>
    </div>
  );
}

export default AutoSaveTimer;
