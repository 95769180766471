import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initializeFirebaseAnalytics } from 'utility/analytics/firebaseAnalytics';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AuthProvider from 'provider/auth/authProvider';
import UserProvider from 'provider/user/userProvider';
require('typeface-lato');

initializeFirebaseAnalytics();
ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </UserProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
