import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { RiMoneyPoundBoxFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { formatDateWithTime } from 'utility/general/formatHelpers';
import { IListing } from '../../../Colugo/interfaces/listing/IListing';
import ToggleIsActiveComponent from '../../shared/ToggleIsActiveComponent';

type Props = {
  listing: IListing;
};

function BusinessHeroCard(props: Props) {
  const navigate = useNavigate();
  const { listing } = props;

  const hasPromotion = listing?.promotions && listing?.promotions?.length > 0;

  function handleGoTo(url: string) {
    navigate(PuffinRoutes.BusinessDetails.split(':')[0] + listing?.id + url);
  }

  return (
    <div
      className={
        'bg-surface border border-outline rounded-xl w-full flex flex-col relative cursor-pointer hover:shadow-lg transition-all duration-200'
      }
      onClick={() => handleGoTo('/general')}
    >
      <div
        className={
          'flex flex-row p-6 gap-6 border-b border-outline cursor-pointer'
        }
      >
        <div className={'flex-shrink-0 rounded-lg w-52 h-52 overflow-hidden'}>
          {listing.images && listing.images.length > 0 ? (
            <img
              className={'w-52 h-52'}
              src={listing.images[0]}
              alt="HeroImage"
              style={{
                objectFit: 'cover'
              }}
            />
          ) : (
            <div className="bg-surfaceContainer w-52 h-52 rounded-lg"></div>
          )}
        </div>
        <div className={'gap-2 flex flex-col justify-center'}>
          <label className={'heading-bold-2xl-onSurface cursor-pointer'}>
            {listing.name}
          </label>
          <p className={'body-regular-csSpecial-onSurfaceVariant'}>
            {listing.description}
          </p>
          <div className={'absolute top-8 right-9'}>
            <div
              className="flex flex-row border rounded-lg border-grayBorder py-1 px-1.5 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleGoTo('/promotions');
              }}
            >
              <RiMoneyPoundBoxFill
                className="rounded mr-0.5"
                size={28}
                style={{ color: '#EF8088' }}
              />
              <span className="mt-0.5 ml-0.5">
                {hasPromotion ? '' : 'No'} Promotions
              </span>
              <MdKeyboardArrowRight size={24} className="mt-0.5" />
            </div>
          </div>
        </div>
      </div>
      <div
        className={'px-8 py-4 flex flex-row w-full gap-2'}
        onClick={() => handleGoTo('/general')}
      >
        <ToggleIsActiveComponent isActive={listing.isActive} />
        {listing.updatedAt && (
          <p className="text-onSurfaceVariant font-normal text-sm">
            {' '}
            {`Last Edited: ${formatDateWithTime(listing.updatedAt)}`}
          </p>
        )}
      </div>
    </div>
  );
}

export default BusinessHeroCard;
