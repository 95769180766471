import { IListingSuggestionBucket } from 'Colugo/interfaces/suggestions/IListingSuggestionBucket';
import { ListingSuggestionStatus } from 'Colugo/interfaces/suggestions/ListingSuggestionStatus';
import EntityImage from 'components/shared/EntityImage';
import { Link } from 'react-router-dom';
import { formatDateForCard } from 'utility/general/formatHelpers';

type Props = {
  suggestionBucket: IListingSuggestionBucket;
};

function SuggestionBucketCard(props: Props) {
  const { suggestionBucket } = props;

  return (
    <div
      className={`${
        suggestionBucket.status === ListingSuggestionStatus.Reported
          ? 'bg-lighterTeal hover:border-primary'
          : 'bg-theme1 hover:border-black hover:border-opacity-10'
      } my-2 font-nunito rounded-md text-background cursor-pointer border transition-all duration-200`}
      key={suggestionBucket.id}
    >
      <Link to={`/suggestions/${suggestionBucket.id}`}>
        <div className="flex flex-row w-full py-4">
          <div className="pl-3">
            <EntityImage
              entity={suggestionBucket.listing}
              imageProportion={80}
            />
          </div>
          <div className="ml-4">
            <div className="font-bold">
              New suggestion(s) for{' '}
              <span className="text-primaryTeal">
                {suggestionBucket.listing?.name}
              </span>
            </div>
            <div className="font-bold">
              User:{' '}
              <span className="font-normal text-lightCharcoal">
                {suggestionBucket.user.username}
              </span>
            </div>
            <div className="font-bold">
              Submitted:{' '}
              <span className="font-normal text-lightCharcoal">
                {formatDateForCard(suggestionBucket.createdAt!)}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
export default SuggestionBucketCard;
