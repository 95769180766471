import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import { ReactComponent as BuildingBusinessSelection } from 'images/dashboard/buildingBusinessSelection.svg';
import { ManagerContext } from 'provider/manager/managerProvider';
import { useContext } from 'react';
import { IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ImprovedFlyoutMenu, { FlyoutMenuItem } from './ImprovedFlyoutMenu';

function HeaderDropdown() {
  const { businessOwnsClaimsRequests, setSelectedOwnedId, selectedOwnedId } =
    useContext(ManagerContext);
  const navigate = useNavigate();
  const flyoutMenuItems: FlyoutMenuItem[] = businessOwnsClaimsRequests.owns.map(
    (o) => {
      return { label: o.name, value: o.id! };
    }
  );
  const selectedFlyoutMenuItem = flyoutMenuItems.find(
    (fmi) => fmi.value === selectedOwnedId
  );
  const icon = <BuildingBusinessSelection className="mt-1" />;

  function handleUpdateSelectedBusiness(flyoutMenuItem: FlyoutMenuItem) {
    setSelectedOwnedId(flyoutMenuItem.value);
    toast.dismiss();
    toast.success('Business Switched', {
      hideProgressBar: true,
      closeButton: (
        <IoClose color={'#FFFFFF'} size={24} style={{ marginTop: '6px' }} />
      )
    });
  }

  return selectedOwnedId ? (
    <ImprovedFlyoutMenu
      selectedItem={selectedFlyoutMenuItem}
      flyoutMenuItems={flyoutMenuItems}
      onChange={(flyoutMenuItem) => {
        handleUpdateSelectedBusiness(flyoutMenuItem);
        navigate('/');
      }}
      bottomFlyoutMenuItem={'Manage Businesses'}
      onSelectBottomFlyoutMenuItem={() => navigate(PuffinRoutes.ManageBusiness)}
      icon={icon}
    />
  ) : (
    <div></div>
  );
}

export default HeaderDropdown;
