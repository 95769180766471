import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import EventOperations from 'Colugo/operations/events/EventOperations';
import EventCard from 'components/events/EventCard';
import ConfirmationPopup from 'components/shared/ConfirmationPopup';
import { EventsContext } from 'provider/events/eventProvider';
import { useContext, useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';

export const eventsFilterGroupName = 'Events';

const eventOperations = container.resolve(EventOperations);

function RecentEventsEditor() {
  const { errorToast } = useErrorToast();
  const { events, mutateEvents } = useContext(EventsContext);
  const [eventIdToDelete, setEventIdToDelete] = useState<string | undefined>(
    undefined
  );
  const navigate = useNavigate();

  async function handleDeleteEventAsync() {
    const eventToBeRemoved = events.find((p) => p.id === eventIdToDelete);
    if (!eventIdToDelete || !eventToBeRemoved) {
      errorToast('Cannot find event, please try again.');
      return;
    }

    const updatedEvents = events.filter((p) => p.id !== eventIdToDelete);

    const { error } = await eventOperations.deleteAsync(eventIdToDelete);

    if (error) {
      errorToast('Failed to delete event, please try again');
      setEventIdToDelete(undefined);
      return false;
    }

    mutateEvents(updatedEvents);
    setEventIdToDelete(undefined);
  }

  return (
    <div
      className="flex flex-col pt-12 w-full h-full mr-12"
      style={{ width: '672px', marginLeft: '152px' }}
    >
      <div className="flex flex-row w-full py-1">
        <div
          className={`font-inter font-bold text-2xl`}
          style={{ lineHeight: '27px', fontSize: '18px' }}
        >
          Recently edited events
        </div>
        <div className="flex-grow" />
        <div
          className="text-primary hover:text-green-700 font-nunito font-bold cursor-pointer flex-row flex"
          onClick={() => navigate(PuffinRoutes.CreateEvent)}
        >
          Create new Event
          <AiFillPlusCircle size={24} className={`text-xl mx-2`} />
        </div>
      </div>
      <div className="flex flex-row flex-wrap mt-1">
        {events.slice(0, 2).map((event, i) => (
          <div key={i}>
            <EventCard
              event={event}
              listing={event.listing}
              onClickDelete={() => setEventIdToDelete(event.id)}
              width="672px"
            />
          </div>
        ))}
      </div>
      {!!eventIdToDelete && (
        <ConfirmationPopup
          isModalOpen={!!eventIdToDelete}
          handleClickConfirmButton={async () => await handleDeleteEventAsync()}
          closeModal={() => setEventIdToDelete(undefined)}
          popupLabel={`Delete this Event?`}
          confirmButtonText="Delete Event"
          isErrorButton={true}
          height="200px"
        >
          <div className="font-nunito text-primaryCharcoal font-normal text-base">
            If an Event is permanently deleted, it cannot be undone. Are you
            sure you want to delete this Event?
          </div>
        </ConfirmationPopup>
      )}
    </div>
  );
}

export default RecentEventsEditor;
