import DashboardSideBarContainer from 'components/sidebar/DashboardSideBarContainer';
import SuggestionBucketCard from './SuggestionBucketCard';
import { IListingSuggestionBucket } from 'Colugo/interfaces/suggestions/IListingSuggestionBucket';

export type Props = {
  suggestionBuckets: IListingSuggestionBucket[];
  isListingSpecific: boolean;
};

export default function SuggestionBucketsComponent(props: Props) {
  const { suggestionBuckets, isListingSpecific } = props;
  return (
    <div className="flex flex-row h-screen w-screen">
      <DashboardSideBarContainer />
      <div className="overflow-visible flex overflow-x-hidden overflow-y-visible bg-theme1 w-screen">
        <div
          className="flex flex-col h-full mx-auto mt-4 overflow-y-visible"
          style={{ width: 900 }}
        >
          <div className="text-2xl font-extrabold font-inter text-background">
            Notifications{' '}
            {isListingSpecific && (
              <span>
                {' '}
                for{' '}
                <span className="text-primaryTeal">
                  {suggestionBuckets[0].listing.name}
                </span>
              </span>
            )}
          </div>
          <div>
            <div
              className="w-full bg-black my-4 opacity-20"
              style={{ height: '1px' }}
            />
            {suggestionBuckets.map((suggestionBucket) => (
              <SuggestionBucketCard
                suggestionBucket={suggestionBucket}
                key={suggestionBucket.id}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
