import { HoverIcon } from '@bindystreet/bindystreet.kit.react';
import { useNavigate } from 'react-router-dom';
import { addSpacesBeforeCapitalLetters } from 'utility/general/formatHelpers';
import { SidebarMenuName } from './DashboardSidebar';

type Props = {
  handleIconHover?: (iconName: SidebarMenuName, isHovered: boolean) => void;
  icon: JSX.Element;
  isActiveLink: (newActiveLinkName: string) => boolean;
  itemName: SidebarMenuName;
  itemPath: string;
  setActiveLink: (activeLink: string) => void;
};

function SidebarItem(props: Props) {
  const {
    handleIconHover,
    icon,
    isActiveLink,
    itemPath,
    itemName,
    setActiveLink
  } = props;
  const navigate = useNavigate();

  function handleNavigation(itemPath: string) {
    setActiveLink(itemPath);
    navigate(`/${itemPath}`.replace('//', '/'));
  }

  return (
    <div>
      <div
        className="mt-2"
        onClick={() => handleNavigation(itemPath)}
        onMouseEnter={() => handleIconHover?.(itemName, true)}
        onMouseLeave={() => handleIconHover?.(itemName, false)}
      >
        <HoverIcon
          icon={icon}
          text={addSpacesBeforeCapitalLetters(itemName)}
          isActive={isActiveLink(itemPath)}
        />
      </div>
    </div>
  );
}

export default SidebarItem;
