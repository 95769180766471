import { readImageFile } from '@bindystreet/bindystreet.kit.react';
import Jimp from 'jimp';
import centerCropImage from './centerCropImage';
import JPEG from 'jpeg-js';

export type Resizing = {
  isCrop: boolean;
  resolution: { width: number; height: number };
};

Jimp.decoders['image/jpeg'] = (data) =>
  JPEG.decode(data, {
    maxMemoryUsageInMB: 6144,
    maxResolutionInMP: 600
  });

// NOTE: This document cannot call any code not available to workers.
// Any imported libraries cannot use code that is not available to workers.

// NOTE: We remove the exif buffer off all images when uploading.
// This is due to inconsistent behaviour as the reading of exif data
// is not standarized across viewers
const removeExifBuffer = (jimpImage: any) =>
  (jimpImage.bitmap.exifBuffer = undefined);

const resizeImageToFit = async (
  file: File,
  resolution: { width: number; height: number }
) => {
  const imageUrl = await readImageFile(file);
  const jimpImage = await Jimp.read(imageUrl);
  const width = jimpImage.bitmap.width;
  const height = jimpImage.bitmap.height;

  if (width <= resolution.width && height <= resolution.height) {
    return file;
  }

  const heightRatio = resolution.height / height;
  let newHeight = resolution.height;
  let newWidth = width * heightRatio;

  if (newWidth > resolution.width) {
    const widthRatio = resolution.width / newWidth;
    newWidth = resolution.width;
    newHeight = newHeight * widthRatio;
  }

  const mime = jimpImage.getMIME();
  removeExifBuffer(jimpImage);
  const imageBuffer = await jimpImage
    .resize(newWidth, newHeight)
    .getBufferAsync(mime);
  const newFile = new File([imageBuffer as BlobPart], file.name, {
    type: mime
  });
  return newFile;
};

export const resizeImage = async (imageUrl: string, resizing: Resizing) => {
  let jimpImage: Jimp | undefined = undefined;

  try {
    jimpImage = await Jimp.read(imageUrl);
  } catch (error) {
    console.error(error);
    console.error('Failed to read image');
    return undefined;
  }

  if (!jimpImage) {
    return undefined;
  }

  const mime = jimpImage.getMIME();
  removeExifBuffer(jimpImage);

  const buffer = await jimpImage.getBufferAsync(mime);
  let file: File;
  try {
    file = new File(
      [buffer as BlobPart],
      `csvImage.${jimpImage.getExtension()}`,
      { type: mime }
    );
  } catch (error) {
    console.error(error);
    return undefined;
  }

  try {
    file = resizing.isCrop
      ? await centerCropImage(file, resizing.resolution)
      : await resizeImageToFit(file, resizing.resolution);
  } catch (error) {
    console.error(`Failed to resize ${file.name}`);
    console.error(error);
    return undefined;
  }
  return file;
};

const imageWorker = { resizeImage };

export type ResizeImageType = typeof imageWorker;

// Makes the object/function available when using spawn targeting this file
