import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import Sidebar, { ISidebarItem } from 'components/shared/Sidebar';
import { ReactComponent as CreateEvent } from 'images/sidebar/admin/createEvent.svg';
import { ReactComponent as CreateListing } from 'images/sidebar/admin/createListing.svg';
import { ReactComponent as Home } from 'images/sidebar/admin/home.svg';
import { ReactComponent as ManageAccount } from 'images/sidebar/admin/manageAccounts.svg';
import { useState } from 'react';
import ManageAccountSidebar from './ManageAccountSidebar';
import { useLocation } from 'react-router-dom';

export enum AdminSidebarNames {
  Home = 'Home',
  ManageAccounts = 'ManageAccounts',
  CreateListing = 'CreateListing',
  CreateEvents = 'CreateEvents'
}

const adminSideBarItems: ISidebarItem[] = [
  { name: AdminSidebarNames.Home, Icon: Home, path: '/' },
  {
    name: AdminSidebarNames.ManageAccounts,
    Icon: ManageAccount,
    path:
      PuffinRoutes.ManageAccounts +
      '/' +
      PuffinRoutes.BusinessClaims.slice(0, -1) +
      PuffinRoutes.NewBusinessClaimOrRequests
  },
  {
    name: AdminSidebarNames.CreateListing,
    Icon: CreateListing,
    path: PuffinRoutes.CreateListing
  },
  {
    name: AdminSidebarNames.CreateEvents,
    Icon: CreateEvent,
    path: PuffinRoutes.CreateEvent
  }
];

type Props = {
  onClickSignout: (e: React.MouseEvent) => void;
};

function AdminSidebar(props: Props) {
  const { onClickSignout } = props;
  const location = useLocation();
  const [activeNestedSidebarItemName, setActiveNestedSidebarItemName] =
    useState<string | undefined>(undefined);

  function handleOnClickNestedRouteMenu(sideBarItemName: string) {
    switch (sideBarItemName) {
      case AdminSidebarNames.ManageAccounts:
        setActiveNestedSidebarItemName(
          activeNestedSidebarItemName === AdminSidebarNames.ManageAccounts
            ? undefined
            : AdminSidebarNames.ManageAccounts
        );
        break;
      default:
        break;
    }
  }

  return (
    <div className="flex flex-row">
      <Sidebar
        onClickSignout={onClickSignout}
        sideBarItems={adminSideBarItems}
        onClickNestedRoute={handleOnClickNestedRouteMenu}
        activeNestedSidebarNames={[activeNestedSidebarItemName ?? '']}
        setActiveNestedSidebarItemName={setActiveNestedSidebarItemName}
      />
      {location.pathname?.includes(PuffinRoutes.ManageAccounts) && (
        <ManageAccountSidebar />
      )}
    </div>
  );
}

export default AdminSidebar;
