import { APP_ENV } from '../constants/constants';
import app from 'firebase/app';
import 'firebase/analytics';

declare global {
  interface Window {
    gtag: any;
  }
}

let analytics: app.analytics.Analytics | undefined;
export const initializeFirebaseAnalytics = () => {
  const firebaseConfig =
    APP_ENV === 'STAGING'
      ? {
          apiKey: "AIzaSyBfl4OxukC8S9c3t3U7OKGqacE2JT7d-4Y",
          authDomain: "puffinstaging.firebaseapp.com",
          projectId: "puffinstaging",
          storageBucket: "puffinstaging.appspot.com",
          messagingSenderId: "686699122389",
          appId: "1:686699122389:web:5e7390acceec8e7034dc5c",
          measurementId: "G-23BGZYS3GW"
        }
      : APP_ENV === 'PRODUCTION'
      ? {
          apiKey: "AIzaSyBsGoElfzftH2g0rJIYNh-s5xzyQERTcys",
          authDomain: "puffin-production.firebaseapp.com",
          projectId: "puffin-production",
          storageBucket: "puffin-production.appspot.com",
          messagingSenderId: "34575775610",
          appId: "1:34575775610:web:439682135e69568b9888c3",
          measurementId: "G-B0RERSLZCN"
        }
      : null;

  if (firebaseConfig) {
    app.initializeApp(firebaseConfig);
    analytics = app.analytics();
  }
};

export const signOutEvent = (label?: string) => {
  analytics?.logEvent('sign_out', {
    event_label: label
  });
};

export const signInEvent = (label?: string) => {
  analytics?.logEvent('sign_in', {
    event_label: label
  });
};