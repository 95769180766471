import { ClaimRequestStatus } from 'Colugo/interfaces/business/IBaseListingRequest';
import { IBusinessClaim } from 'Colugo/interfaces/business/IBusinessClaim';
import { IBusinessRequest } from 'Colugo/interfaces/business/IBusinessRequest';
import { ManageAccountSection } from 'components/dashboard/ListingClaimsOrRequests';
import { ReactComponent as RightArrow } from 'images/dashboard/arrowRight.svg';
import { ReactComponent as ProfileIcon } from 'images/dashboard/profileIcon.svg';
import { useNavigate } from 'react-router-dom';
import {
  formatAddress,
  formatPhoneNumber
} from 'utility/general/formatHelpers';
import { getBaseListingRoute } from 'utility/general/routeHelpers';
import EntityImage from './EntityImage';

type Props = {
  request: IBusinessRequest | IBusinessClaim;
  claimRequestStatus: ClaimRequestStatus;
  onClickAccept: (request: IBusinessRequest | IBusinessClaim) => void;
  onClickReject: (request: IBusinessRequest | IBusinessClaim) => void;
  onClickBusinessAccount: (request: IBusinessRequest | IBusinessClaim) => void;
  section: ManageAccountSection | undefined;
};

function PendingRequestCard(props: Props) {
  const {
    request,
    onClickAccept,
    onClickReject,
    onClickBusinessAccount,
    section
  } = props;
  const navigate = useNavigate();
  function handleClickAccept(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onClickAccept(request);
  }

  function handleClickReject(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onClickReject(request);
  }

  function onNavigateToListing() {
    navigate(getBaseListingRoute(request.listing, true) + '/general');
  }

  return (
    <div
      className={`bg-surface border rounded-xl border-outline w-full flex flex-col ${
        section === ManageAccountSection.BusinessClaims
          ? 'cursor-pointer'
          : 'cursor-default'
      }`}
      style={{ height: '235px' }}
      onClick={
        section === ManageAccountSection.BusinessClaims
          ? () => onNavigateToListing()
          : undefined
      }
    >
      <div className="flex flex-row justify-between border-b py-4 px-6 border-outline">
        <div className="flex flex-row items-center gap-4">
          <div>
            <EntityImage entity={request.listing} imageProportion={80} />
          </div>
          <div className="flex flex-col gap-1">
            <p className="heading-bold-m text-primaryDark">
              {request.listing.name}
            </p>
            <p className="heading-regular-av text-onSurfaceVariant">
              {request.listing.address &&
              Object.keys(request.listing.address).length > 3
                ? formatAddress(request.listing.address)
                : 'No Address Provided'}
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-4 items-center">
          <button
            type="button"
            className="border border-green rounded-lg flex flex-row justify-center items-center text-green body-bold-l py-2 px-4 hover:opacity-60 h-auto"
            onClick={(e) => handleClickAccept(e)}
          >
            Accept
          </button>
          <button
            type="button"
            className="border border-red rounded-lg flex flex-row justify-center items-center text-red body-bold-l py-2 px-4 hover:opacity-60"
            onClick={(e) => handleClickReject(e)}
          >
            Reject
          </button>
        </div>
      </div>

      <div className="w-full h-full flex flex-row px-6 py-3">
        <div
          className="flex flex-col border-r border-outline pr-4 gap-2 cursor-pointer"
          style={{ width: '224px' }}
          onClick={() => onClickBusinessAccount(request)}
        >
          <div className="flex flex-row items-center gap-1">
            <ProfileIcon />
            <span className="heading-semibold-sm text-onSurface">
              {request.user.firstName} {request.user.lastName}
            </span>
            <RightArrow className="mt-1" />
          </div>
          <p className="heading-regular-sm text-onSurfaceVariant">
            {request.user.email}
          </p>
          <p className="heading-regular-sm text-onSurfaceVariant">
            {formatPhoneNumber(request.user.phoneNumber)}
          </p>
        </div>
        <div className="w-full h-full px-4 flex flex-col gap-1">
          <p className="heading-regular-sm text-onSurface">
            {request.isYourBusiness
              ? 'Yes • It’s their Businesss'
              : `No • ${request.businessConnection ?? ''}`}
          </p>
          <p className="heading-regular-sm text-onSurface">
            {request.hasAlreadyContacted
              ? 'Yes • Has Corresponded'
              : 'No • Has Not Corresponded Before'}
          </p>
          <p className="body-regular-m text-onSurfaceVariant text-justify">
            {request.clarification}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PendingRequestCard;
