import { getTailwindColour } from '@bindystreet/bindystreet.kit.react';
import { ReactElement, useEffect, useState } from 'react';
import { Stage, Layer, Group, Circle, Rect, Path } from 'react-konva';

type Props = {
  id?: string;
  name?: string;
  disabled?: boolean;
  checked?: boolean;
  textColor?: string;
  fontSize?: string;
  onChange?: () => void;
  label?: string;
  children?: ReactElement[] | ReactElement | string;
  className?: string;
  type?: 'checkbox' | 'radio';
  skin?: 'checkbox' | 'radio';
  size?: 'md' | 'lg' | 'xl';
  // the color of the thick label in checkbox
  selectedColour?:
    | 'primaryButton'
    | 'primary'
    | 'secondaryButton'
    | 'uniqueButton'
    | 'white'
    | 'black';
  unselectedColour?: string;
  unselectedBackgroundColour?: string;
  selectedBackgroundColour?: string;
  disabledColour?: string;
  selectedBorderColour?: string;
  unselectedBorderColour?: string;
  labelNoWrap?: boolean;
};
const ImprovedCheckbox = (props: Props) => {
  const {
    id,
    name,
    disabled,
    onChange = () => {},
    checked,
    label,
    children,
    className,
    type = 'checkbox',
    skin = type || 'checkbox',
    size = 'md',
    unselectedBackgroundColour = 'white',
    selectedBackgroundColour = 'white',
    selectedColour = 'primaryButton',
    unselectedColour = 'black',
    disabledColour = 'gray-400',
    selectedBorderColour = 'primaryButton',
    unselectedBorderColour = 'black',
    labelNoWrap = true
  } = props;

  const inputStyle = {
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer',
    height: 0,
    width: 0,
    top: '-9999px'
  } as React.CSSProperties;

  //TODO: make other size and so checkbox is also affected by size
  const sizeDict = {
    md: 25,
    lg: 30,
    xl: 40
  };

  const selectedHoverColour =
    selectedColour === 'primaryButton'
      ? 'secondaryButtonHover'
      : selectedColour === 'uniqueButton'
      ? 'uniqueButtonHover'
      : 'white';

  const [isHover, setIsHover] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    if (!isHover && isPressed) {
      setIsPressed(false);
    }
  }, [isPressed, isHover, setIsPressed]);

  return (
    <div
      onClick={!disabled ? onChange : undefined}
      onMouseLeave={() => setIsHover(false)}
      onMouseEnter={() => setIsHover(true)}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      className={`text-left align-middle flex flex-row ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      style={{ minWidth: 0, opacity: disabled ? '0.4' : '1.0' }}
      aria-checked={checked}
      role="checkbox"
    >
      <input
        id={id}
        name={name}
        type={type}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        className={`mx-1 ${className}
          ${disabled ? `cursor-not-allowed` : ``}
        `}
        style={inputStyle}
      />
      {skin === 'checkbox' && (
        <Stage width={sizeDict[size]} height={sizeDict[size]}>
          <Layer>
            <Group x={sizeDict[size] * 0.1} y={sizeDict[size] * 0.1}>
              <Rect
                width={sizeDict[size] * 0.8}
                height={sizeDict[size] * 0.8}
                cornerRadius={4}
                fill={getTailwindColour(
                  checked
                    ? selectedBackgroundColour
                    : isHover || isPressed
                    ? selectedHoverColour
                    : unselectedBackgroundColour
                )}
                strokeWidth={1}
                stroke={
                  checked
                    ? getTailwindColour(selectedBorderColour)
                    : isHover || isPressed
                    ? 'black'
                    : getTailwindColour(unselectedBorderColour)
                }
              />
              {checked && (
                <Path
                  x={sizeDict[size] * 0.15}
                  y={sizeDict[size] * 0.15}
                  width={sizeDict[size] * 1}
                  height={sizeDict[size] * 1}
                  scaleX={sizeDict[size] / 30}
                  scaleY={sizeDict[size] / 30}
                  data={
                    'M5.6 8.6001L2.4 5.4001L0 7.8001L5.6 13.4001L16 3.0001L13.6 0.600098L5.6 8.6001Z'
                  }
                  fill={getTailwindColour(selectedColour)}
                />
              )}
            </Group>
          </Layer>
        </Stage>
      )}
      {skin === 'radio' && (
        <Stage width={sizeDict[size]} height={sizeDict[size]}>
          <Layer>
            <Group x={sizeDict[size] * 0.5} y={sizeDict[size] * 0.5}>
              <Circle
                strokeWidth={1}
                fill={getTailwindColour(
                  (isHover || isPressed) && !checked
                    ? selectedHoverColour
                    : unselectedBackgroundColour
                )}
                stroke={getTailwindColour(
                  disabled
                    ? disabledColour
                    : checked
                    ? selectedColour
                    : isHover || isPressed
                    ? 'black'
                    : unselectedColour
                )}
                radius={sizeDict[size] * 0.4}
              />
              {checked && (
                <Circle
                  fill={getTailwindColour(
                    disabled ? disabledColour : selectedColour
                  )}
                  radius={sizeDict[size] * 0.24}
                />
              )}
            </Group>
          </Layer>
        </Stage>
      )}

      {(label || children) && (
        <label htmlFor={id} className={`ml-3`}>
          <div className={`${disabled && 'text-gray-400'}`}>
            <div
              className={`${size === 'xl' && 'text-2xl font-bold mt-1'} ${
                labelNoWrap && 'whitespace-no-wrap'
              }`}
            >
              {label}
            </div>
          </div>
          {children}
        </label>
      )}
    </div>
  );
};

export default ImprovedCheckbox;
