import { ClaimRequestStatus } from 'Colugo/interfaces/business/IBaseListingRequest';
import { IBusinessRequest } from 'Colugo/interfaces/business/IBusinessRequest';
import { requestResponse } from 'Colugo/provider/HttpClient';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';
import { EmptyGuid } from 'Colugo/interfaces/constants';

@scoped(Lifecycle.ContainerScoped)
class BusinessRequestOperations extends AbstractOperations {
  protected basePath: string = 'businessRequest';

  public async createAsync(
    businessRequest: IBusinessRequest
  ): requestResponse<IBusinessRequest> {
    return await this.executePost(``, businessRequest);
  }

  public async handleRequestAsync(
    businessRequestId: string,
    claimRequestStatus: ClaimRequestStatus,
    rejectionReason?: string
  ): requestResponse<IBusinessRequest> {
    return await this.executePut(
      `${businessRequestId}/handle/${claimRequestStatus}?rejectionReason=${rejectionReason}`
    );
  }

  public async deleteAsync(id: string): requestResponse {
    return await this.executeDelete(`${id}`);
  }
}

const useReqListBusinessRequestsAsync = (
  claimRequestStatus: ClaimRequestStatus,
  take: number = 100,
  cursorId: string = EmptyGuid
): swrRequestResponse<IBusinessRequest[]> => {
  return useGetHttpClient(
    claimRequestStatus
      ? `businessRequest/list/${claimRequestStatus}?take=${take}&cursorId=${cursorId}`
      : null,
    {
      revalidateOnFocus: false
    },
    false
  );
};

export default BusinessRequestOperations;
export { useReqListBusinessRequestsAsync };
