import { IListing } from 'Colugo/interfaces/listing/IListing';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import ListingCard from 'components/shared/ListingCard';
import { useNavigate } from 'react-router-dom';

type Props = {
  results: IListing[];
};
function BusinessResultsList(props: Props) {
  const { results } = props;
  const navigate = useNavigate();

  return (
    <div>
      {results.map((listing) => (
        <ListingCard
          listing={listing}
          key={listing.id}
          styles={{ width: '100%' }}
          containerClassNames="py-4 border-0 mb-3 justify-between items-center"
          isBusinessCard
          onClickBusinessBtn={() =>
            navigate(PuffinRoutes.SubmitClaim, { state: listing })
          }
        />
      ))}
      <div
        className="border rounded-lg border-onSurface border-dashed mt-3"
        style={{ padding: '45px', backgroundColor: 'rgba(239, 239, 247, 1)' }}
      >
        <p className="text-onSurface text-center">
          Can't see your business? Search again or{' '}
          <span
            className="underline font-bold cursor-pointer"
            onClick={() => navigate(PuffinRoutes.SubmitBusinessRequest)}
          >
            Submit a Business Request
          </span>
        </p>
      </div>
    </div>
  );
}

export default BusinessResultsList;
