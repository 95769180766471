import { Checkbox, Input } from '@bindystreet/bindystreet.kit.react';
import { InputFormEvent } from '@bindystreet/bindystreet.kit.react/dist/components/Input';
import {
  ErrorType,
  IIoiValidationError
} from 'Colugo/interfaces/IIoiValidationError';
import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { useState } from 'react';
import { currentIsoDateString } from 'utility/constants/constants';
import { useErrorToast } from 'utility/hooks/useErrorToast';

type Props = {
  isSingleDate: boolean;
  localEvent: IEvent;
  updateEventAsync: (updatedEvent: IEvent) => Promise<boolean>;
  setLocalEvent: (event: IEvent) => void;
  validationErrors: IIoiValidationError[];
  updateIsActiveForInvalidEventAsync: () => Promise<boolean>;
};

const durationSideNode = `Please enter the date(s) of your event.`;
const openingTimesSideNote = `If your event has varied times, please tick the box to add a written explanation instead.`;

function DateOptionsEditor(props: Props) {
  const {
    isSingleDate,
    localEvent,
    updateEventAsync,
    setLocalEvent,
    validationErrors,
    updateIsActiveForInvalidEventAsync
  } = props;
  const { errorToast } = useErrorToast();

  const [isEventExplanationOnly, setIsEventExplanationOnly] = useState<boolean>(
    localEvent.time!.isExplanationOnly
  );
  const [eventOpeningTime, setEventOpeningTime] = useState<string>(
    localEvent.time?.times?.times[0].openingTime || ''
  );
  const [eventClosingTime, setEventClosingTime] = useState<string>(
    localEvent.time?.times?.times[0].closingTime || ''
  );
  const [eventEndDate, setEventEndDate] = useState<string>(
    localEvent.endDate?.split('T')[0] || ''
  );
  const [eventStartDate, setEventStartDate] = useState<string>(
    localEvent.startDate?.split('T')[0] || ''
  );
  const [eventTimeExplanation, setEventTimeExplanation] = useState<string>(
    localEvent.time?.timeExplanation || ''
  );

  function getValidationErrorMessage(errorType: ErrorType): string | undefined {
    return validationErrors.find((ve) => ve.type === errorType)?.tabMessage;
  }

  async function handleChangeStartDateAsync(e: InputFormEvent) {
    if (!e.currentTarget.value) {
      errorToast('Cannot remove start date');
      return;
    }
    setEventStartDate(e.currentTarget.value);
    const updatedEvent = { ...localEvent, startDate: e.currentTarget.value };
    setLocalEvent(updatedEvent);
    const isSuccesful = await updateEventAsync(updatedEvent);
    if (!isSuccesful) {
      setLocalEvent(localEvent);
    }
  }

  async function handleChangeEndDateAsync(e: InputFormEvent) {
    const endDate = e.currentTarget.value;
    let updatedEvent: IEvent | undefined;

    if (endDate < currentIsoDateString) {
      errorToast(
        `End date cannot be set to a date prior to today's current date`
      );
      return;
    } else if (endDate < eventStartDate && endDate !== eventStartDate) {
      errorToast(`End date cannot be set to be before the start date`);
      return;
    } else {
      setEventEndDate(endDate);
      updatedEvent = { ...localEvent, endDate };
    }

    if (updatedEvent) {
      const isSuccessful = await updateEventAsync(updatedEvent);
      if (!isSuccessful) {
        setLocalEvent(localEvent);
      } else {
        setLocalEvent(updatedEvent);
      }
    }
  }

  async function handleChangeIsEventTimeExplanationOnlyAsync() {
    const updatedEvent: IEvent = {
      ...localEvent,
      isActive: false,
      time: {
        timeExplanation: '',
        times: undefined,
        isExplanationOnly: !isEventExplanationOnly
      }
    };

    const isSuccesful = await updateEventAsync(updatedEvent);
    if (!isSuccesful) {
      setLocalEvent(localEvent);
      return;
    }
    setEventOpeningTime('');
    setEventClosingTime('');

    await updateIsActiveForInvalidEventAsync();

    setLocalEvent(updatedEvent);
  }

  async function handleChangeEventTimeExplanationAsync() {
    const updatedEvent: IEvent = {
      ...localEvent,
      time: {
        isExplanationOnly: localEvent.time!.isExplanationOnly,
        timeExplanation: eventTimeExplanation
      }
    };
    setLocalEvent(updatedEvent);

    const isSuccesful = await updateEventAsync(updatedEvent);
    if (!isSuccesful) {
      setLocalEvent(localEvent);
    }
  }

  async function handleChangeOpeningTimeAsync(e: InputFormEvent) {
    setEventOpeningTime(e.currentTarget.value);
    const updatedEvent: IEvent = {
      ...localEvent,
      time: {
        isExplanationOnly: localEvent.time!.isExplanationOnly,
        times: {
          times: [
            {
              ...localEvent?.time?.times?.times[0],
              openingTime: e.currentTarget.value
            }
          ]
        }
      }
    };
    setLocalEvent(updatedEvent);

    const isSuccesful = await updateEventAsync(updatedEvent);
    if (!isSuccesful) {
      setLocalEvent(localEvent);
    }
  }

  async function handleChangeClosingTimeAsync(e: InputFormEvent) {
    setEventClosingTime(e.currentTarget.value);
    const updatedEvent: IEvent = {
      ...localEvent,
      time: {
        isExplanationOnly: localEvent.time!.isExplanationOnly,
        times: {
          times: [
            {
              ...localEvent?.time?.times?.times[0],
              closingTime: e.currentTarget.value
            }
          ]
        }
      }
    };
    setLocalEvent(updatedEvent);

    const isSuccesful = await updateEventAsync(updatedEvent);
    if (!isSuccesful) {
      errorToast('Could not update event');
      setLocalEvent(localEvent);
    }
  }

  return (
    <div>
      <div className="flex flex-col w-full">
        <div className="mt-3 text-base font-inter font-bold text-primaryCharcoal">
          Event Date*
        </div>
        <div className="flex flex-row w-full my-2" style={{ height: '84px' }}>
          <div className="flex " style={{ width: '85%' }}>
            <div
              className={`${
                !!getValidationErrorMessage(ErrorType.StartDate) && '-mb-1'
              }`}
              style={{ width: '40%' }}
            >
              <Input
                onChange={handleChangeStartDateAsync}
                name="eventDate"
                type="date"
                value={eventStartDate}
                error={getValidationErrorMessage(ErrorType.StartDate)}
              />
            </div>
            <div
              className={`${
                !!getValidationErrorMessage(ErrorType.EndDate) && '-mb-1'
              } ml-4`}
              style={{ width: '40%' }}
            >
              {!isSingleDate && (
                <Input
                  onChange={handleChangeEndDateAsync}
                  name="eventDate"
                  type="date"
                  value={eventEndDate}
                  error={getValidationErrorMessage(ErrorType.EndDate)}
                />
              )}
            </div>
          </div>
          <div className="border-r-2 border-r-gray ml-6"></div>
          <div
            style={{ width: '247px' }}
            className="ml-6 w-full text-sm font-inter font-normal"
          >
            {durationSideNode}
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full -mt-1">
        <div className="text-base font-inter font-bold text-primaryCharcoal">
          Event Times*
        </div>
        <div className="flex flex-row items-center mt-2">
          <Checkbox
            onChange={async () => {
              setEventTimeExplanation('');
              setIsEventExplanationOnly(!isEventExplanationOnly);
              await handleChangeIsEventTimeExplanationOnlyAsync();
            }}
            unselectedBorderColour={'hot'}
            checked={isEventExplanationOnly}
            selectedBackgroundColour="primaryTeal"
            selectedColour="white"
          />
          <div className="text-sm font-inter font-noraml ml-2 text-primaryCharcoal">
            Add Explanation Instead of Times
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full" style={{ height: '84px' }}>
        {isEventExplanationOnly ? (
          <div style={{ width: '85%' }}>
            <Input
              type="text"
              value={eventTimeExplanation}
              onChange={(e: InputFormEvent) =>
                setEventTimeExplanation(e.currentTarget.value)
              }
              onBlurWithinCharacterLimit={handleChangeEventTimeExplanationAsync}
              error={
                !localEvent.time?.timeExplanation &&
                getValidationErrorMessage(ErrorType.TimeExplanation)
              }
            />
          </div>
        ) : (
          <div className="flex flex-row" style={{ width: '85%' }}>
            <div
              className="flex flex-row justify-start"
              style={{ width: '50%' }}
            >
              <div
                className={`flex flex-row justify-between rounded-md w-full`}
              >
                <Input
                  onChange={handleChangeOpeningTimeAsync}
                  name="firstEventTime"
                  type="time"
                  value={eventOpeningTime}
                  error={
                    !localEvent.time?.times?.times[0].openingTime &&
                    getValidationErrorMessage(ErrorType.OpeningTime)
                  }
                />
              </div>
            </div>
            <div
              className="flex flex-row justify-start ml-2"
              style={{ width: '50%' }}
            >
              <div
                className={`relative flex flex-row justify-between hover:border-primary rounded-md w-full transition-all duration-200`}
              >
                <Input
                  onChange={handleChangeClosingTimeAsync}
                  name="firstEventTime"
                  type="time"
                  value={eventClosingTime}
                  error={
                    !localEvent.time?.times?.times[0].closingTime &&
                    getValidationErrorMessage(ErrorType.ClosingTime)
                  }
                />
              </div>
            </div>
          </div>
        )}

        <div className="border-r-2 border-r-gray ml-6"></div>
        <div
          style={{ width: '247px' }}
          className="ml-6 w-full text-sm font-inter font-normal"
        >
          {openingTimesSideNote}
        </div>
      </div>
    </div>
  );
}

export default DateOptionsEditor;
