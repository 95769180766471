import {
  Button,
  Form,
  InputFormEvent
} from '@bindystreet/bindystreet.kit.react';
import { UserOperations } from 'Colugo/operations/identity';
import {
  AuthContext,
  initialCreateUserState
} from 'provider/auth/authProvider';
import { UserContext } from 'provider/user/userProvider';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { container } from 'tsyringe';
import * as firebaseAnalytics from 'utility/analytics/firebaseAnalytics';
import Validator from 'utility/general/RegexValidator';
import localStore from 'utility/general/localStore';
import ImprovedInput, { ImprovedInputType } from '../shared/ImprovedInput';
import Layout from './Layout';
import { FormError } from './SignUp';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';

const userOperations = container.resolve(UserOperations);

function SignIn() {
  const {
    handleSignInAsync,
    inputs,
    setInputs,
    errors,
    resetState,
    setToken,
    isLoading
  } = useContext(AuthContext);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const { setUser } = useContext(UserContext);

  function validateFields() {
    let isSubmitValid = true;
    let errors: FormError[] = [];

    if (!Validator.isEmailValid(inputs.email)) {
      errors.push({
        field: 'email',
        message: 'Please type in your email address'
      });
      isSubmitValid = false;
    }

    if (!inputs.password) {
      errors.push({
        field: 'password',
        message: 'Please type in your password'
      });
      isSubmitValid = false;
    }

    setFormErrors(errors);
    return isSubmitValid;
  }

  async function handleSubmitAsync(e: React.FormEvent<HTMLFormElement>) {
    const isSubmitValid = validateFields();
    if (!isSubmitValid) {
      return;
    }
    const isSuccessful = await handleSignInAsync();
    if (isSuccessful) {
      const { data: user } = await userOperations.getUserAsync();

      if (user) {
        const token = localStore.getItem('token');
        setUser(user);
        setToken(token);
        setInputs(initialCreateUserState);
      }
      firebaseAnalytics.signInEvent(inputs.email);
    }
  }

  function handleChange(e: InputFormEvent) {
    const { name, value } = e.target as any;
    setInputs((prev: any) => ({ ...prev, [name]: value }));
    if (name === 'email') {
      setIsEmailValid(Validator.isEmailValid(value));
    }
  }

  function displayError(error: string) {
    if (error.indexOf('Budgie.MS.Common.Models') > 1) {
      error = 'Email does not exist, please create an account.';
    }
    return <div>{error}</div>;
  }

  return (
    <Layout>
      <div className="flex-col px-40 items-center justify-center">
        <div className="mt-4">
          <h2 className="heading-bold-2xl-onSurface">
            Welcome to Ember Business
          </h2>
        </div>
        <div className="mt-2 heading-regular-l-onSurfaceVariant">
          <div>Start managing your business, events and</div>
          <div>promotions on the Ember app.</div>
        </div>
        <div className="mt-4" style={{ maxWidth: '556px' }}>
          <Form
            onSubmit={handleSubmitAsync}
            formClassName={'flex flex-col gap-6'}
          >
            <ImprovedInput
              name={'email'}
              value={inputs.email}
              label={'Email address'}
              labelClassName={'heading-semibold-av-onSurface'}
              placeHolder={'Enter your email address'}
              className={
                'body-regular-l-onSurface placeholder-surfaceContainerHighest focus:outline-none'
              }
              errorClassName={'heading-semibold-sm-red'}
              onChange={handleChange}
              isError={
                (inputs.email.length > 0 && !isEmailValid) ||
                formErrors.some((e) => e.field === 'email')
              }
              error={'Please provide a valid email address'}
            />
            <ImprovedInput
              name={'password'}
              value={inputs.password}
              inputType={ImprovedInputType.Password}
              label={'Password'}
              labelClassName={'heading-semibold-av-onSurface'}
              placeHolder={'Enter your password'}
              className={
                'body-regular-l-onSurface placeholder-surfaceContainerHighest focus:outline-none'
              }
              errorClassName={'heading-semibold-sm-red'}
              onChange={handleChange}
              isError={
                (inputs.email.length > 0 && !isEmailValid) ||
                formErrors.some((e) => e.field === 'password')
              }
              error={formErrors.find((fe) => fe.field === 'password')?.message}
              attachedComponent={
                <Link
                  className={
                    'hover:heading-regular-av-primary underline heading-regular-av-onSurfaceVariant w-auto mt-2'
                  }
                  to={PuffinRoutes.ForgotPassword}
                  onClick={resetState}
                >
                  Forgot password?
                </Link>
              }
            />
            {errors?.length > 0 ? (
              <div>
                {errors.map((error: string, idx: number) => (
                  <p key={idx} className="heading-semibold-sm-red">
                    {displayError(error)}
                  </p>
                ))}
              </div>
            ) : null}
            <Button
              size="lg"
              type="submit"
              isLoading={isLoading}
              skin={'transparent'}
              className="flex flex-row justify-center font-nunito items-center w-auto px-6 py-3 mr-auto bg-primary"
            >
              <label className={'body-bold-av-onPrimary'}>Log in</label>
            </Button>
            <span className="heading-regular-av-onSurfaceVariant block">
              Don't have an account yet?{' '}
              <Link
                className="hover:text-primary underline"
                to={PuffinRoutes.SignUp}
                onClick={resetState}
              >
                <span className=""> Create One </span>
              </Link>
            </span>
          </Form>
        </div>
      </div>
    </Layout>
  );
}

export default SignIn;
