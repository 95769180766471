import { useReqListBusinessRequestsAsync } from 'Colugo/operations/businessRequest/BusinessRequestOperations';
import ListingClaimsOrRequests, {
  ManageAccountSection
} from './ListingClaimsOrRequests';

function BusinessRequests() {
  return (
    <ListingClaimsOrRequests
      pageName="Business Requests"
      useListingRequestsAsync={useReqListBusinessRequestsAsync}
      accountSection={ManageAccountSection.BusinessRequests}
    />
  );
}

export default BusinessRequests;
