import { IModelReference } from 'Colugo/interfaces';

//Will need block item type
export interface IAbstractBlockItem extends IModelReference {
  $type: BlockItemTypeEnum;
  centre: {
    x: number;
    y: number;
  };
  size: {
    x: number;
    y: number;
  };
}
export enum BlockItemTypeEnum {
  Header = 'Header',
  Abstract = 'Abstract',
  Text = 'Dippy.Models.Games.Blocks.TextBlockItem, Dippy.Models',
  Image = 'Dippy.Models.Games.Blocks.ImageBlockItem, Dippy.Models',
  Icon = 'Dippy.Models.Games.Blocks.IconsBlockItem, Dippy.Models',
  Tabbed = 'Dippy.Models.Games.Blocks.TabbedBlockItem, Dippy.Models',
  Carousel = 'Dippy.Models.Games.Blocks.CarouselBlockItem, Dippy.Models',
  ColourSwatch = 'Dippy.Models.Games.Blocks.ColourSwatchesBlockItem, Dippy.Models',
  Link = 'Dippy.Models.Games.Blocks.LinkBlockItem, Dippy.Models',
  Table = 'Dippy.Models.Games.Blocks.TableBlockItem, Dippy.Models',
  ItemOfInterest = 'Dippy.Models.Games.Blocks.ItemOfInterestBlockItem, Dippy.Models',
  GameLink = 'Dippy.Models.Games.Blocks.GameLinkBlockItem, Dippy.Models',
  SpotLink = 'Dippy.Models.Games.Blocks.SpotLinkBlockItem, Dippy.Models',
  ZoneLink = 'Dippy.Models.Games.Blocks.ZoneLinkBlockItem, Dippy.Models',
  Ratings = 'Dippy.Models.Games.Blocks.RatingsBlockItem, Dippy.Models',
  Audio = 'Dippy.Models.Games.Blocks.AudioBlockItem, Dippy.Models'
}
