import onboardingImage from 'images/onboarding/onboardingImage.png';
import React from 'react';

type Props = {
  children: React.ReactNode;
  isFooterVisible?: boolean;
  hasImage?: boolean;
  customImage?: string;
};

function Layout(props: Props) {
  const {
    children,
    isFooterVisible = true,
    hasImage = true,
    customImage
  } = props;

  return (
    <div className="flex flex-col w-full h-auto">
      <div className="flex flex-row">
        <div className="flex flex-col w-1/2 h-auto">
          <div className="h-full flex flex-col justify-center">{children}</div>
          {isFooterVisible && (
            <div className="h-auto mb-6 ml-40 w-80  flex flex-row items-center justify-between font-inter text-sm font-normal text-onSurfaceVariant">
              <span>{`© ${new Date().getFullYear()} Bindy Street.`}</span>
              <a
                href="https://www.ember.london/end-user-licence-agreement"
                target="_blank"
                rel="noreferrer"
                className="hover:text-primary underline cursor-pointer"
              >
                EULA,
              </a>
              <a
                href="https://www.ember.london/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="hover:text-primary underline cursor-pointer"
              >
                Privacy
              </a>
              and
              <a
                href="https://www.ember.london/terms-of-service"
                target="_blank"
                rel="noreferrer"
                className="hover:text-primary underline cursor-pointer"
              >
                Terms
              </a>
            </div>
          )}
        </div>
        {hasImage && (
          <div className="w-1/2 h-screen">
            <img
              className="w-full h-screen"
              src={customImage ? customImage : onboardingImage}
              alt="onboardingImage"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Layout;
