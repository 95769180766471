import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import {
  ClaimRequestStatus,
  IBaseListingRequest
} from 'Colugo/interfaces/business/IBaseListingRequest';
import { IUser } from 'Colugo/interfaces/identity';
import BusinessRequestOperations from 'Colugo/operations/businessRequest/BusinessRequestOperations';
import ClaimOperations from 'Colugo/operations/claims/ClaimOperations';
import ConfirmationPopup from 'components/shared/ConfirmationPopup';
import EmptyCard from 'components/shared/EmptyCard';
import ImprovedTextArea from 'components/shared/ImprovedTextarea';
import PendingRequestCard from 'components/shared/PendingRequestCard';
import { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { MutatorCallback } from 'swr/dist/types';
import { container } from 'tsyringe';
import { defaultDate } from 'utility/constants/constants';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import { ManageAccountSection } from './ListingClaimsOrRequests';
import ProfileModal from './ProfileModal';
const businessRequestsOperations = container.resolve(BusinessRequestOperations);
const businessClaimsOperations = container.resolve(ClaimOperations);

type Props<T> = {
  manageAccountSection?: ManageAccountSection;
  requests: T[] | undefined;
  isLoadingRequests?: boolean;
  isError?: boolean | undefined;
  mutateNewRequests?: (
    data?: T[] | Promise<T[]> | MutatorCallback<T[]> | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<T[] | undefined>;
  mutateAcceptedRequests?: (
    data?: T[] | Promise<T[]> | MutatorCallback<T[]> | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<T[] | undefined>;
  mutateRejectedRequests?: (
    data?: T[] | Promise<T[]> | MutatorCallback<T[]> | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<T[] | undefined>;
};

type RequestsState = {
  requestIdToAccept?: string;
  requestIdToReject?: string;
};

function NewRequests<T extends IBaseListingRequest>(props: Props<T>) {
  const {
    manageAccountSection,
    requests,
    isLoadingRequests,
    isError,
    mutateNewRequests,
    mutateAcceptedRequests,
    mutateRejectedRequests
  } = props;
  const [requestedId, setRequestedId] = useState<RequestsState>({
    requestIdToAccept: undefined,
    requestIdToReject: undefined
  });
  const { errorToast } = useErrorToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rejectionReason, setRejectionReason] = useState<string>('');
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [businessUser, setBusinessUser] = useState<IUser | undefined>(
    undefined
  );

  async function handleOnClickAcceptAsync() {
    if (!requestedId.requestIdToAccept) {
      errorToast('No request found to be accepted, please try again');
      return;
    }
    setIsLoading(true);
    let response;

    if (manageAccountSection === ManageAccountSection.BusinessRequests) {
      response = await businessRequestsOperations.handleRequestAsync(
        requestedId.requestIdToAccept,
        ClaimRequestStatus.Approved
      );
    } else {
      response = await businessClaimsOperations.handleRequestAsync(
        requestedId.requestIdToAccept,
        ClaimRequestStatus.Approved
      );
    }

    if (response.error) {
      setIsLoading(false);
      errorToast('Failed to accept request, please try again later');
      return false;
    }

    setIsLoading(false);
    const updatedNewRequests = requests?.filter(
      (data) => data.id !== requestedId.requestIdToAccept
    );
    const newlyAcceptedRequest = requests?.find(
      (data) => data.id === requestedId.requestIdToAccept
    );
    mutateNewRequests && mutateNewRequests(updatedNewRequests);
    if (newlyAcceptedRequest) {
      mutateAcceptedRequests &&
        mutateAcceptedRequests((data = []) => [...data, newlyAcceptedRequest]);
    }

    toast.success(
      manageAccountSection === ManageAccountSection.BusinessRequests
        ? 'Business Request Accepted'
        : 'Business Claim Accepted',
      {
        hideProgressBar: true,
        closeButton: (
          <IoClose color={'#FFFFFF'} size={24} style={{ marginTop: '6px' }} />
        )
      }
    );
    setRequestedId((prevState) => ({
      ...prevState,
      requestIdToAccept: undefined
    }));
  }

  async function handleOnClickRejectAsync() {
    if (!rejectionReason) {
      setFormError('Please provide a reason for rejection');
      return;
    }

    if (!requestedId.requestIdToReject) {
      errorToast('No request found to be declines, please try again');
      return;
    }
    setIsLoading(true);

    let response;

    if (manageAccountSection === ManageAccountSection.BusinessRequests) {
      response = await businessRequestsOperations.handleRequestAsync(
        requestedId.requestIdToReject,
        ClaimRequestStatus.Rejected,
        rejectionReason
      );
    } else {
      response = await businessClaimsOperations.handleRequestAsync(
        requestedId.requestIdToReject,
        ClaimRequestStatus.Rejected,
        rejectionReason
      );
    }

    if (response.error) {
      setIsLoading(false);
      errorToast('Failed to decline request, please try again later');
      return;
    }

    setIsLoading(false);
    const updatedNewRequests = requests?.filter(
      (data) => data.id !== requestedId.requestIdToReject
    );

    mutateNewRequests && mutateNewRequests(updatedNewRequests);

    const newlyRejectedRequest = requests?.find(
      (data) => data.id === requestedId.requestIdToReject
    );
    if (newlyRejectedRequest) {
      mutateRejectedRequests &&
        mutateRejectedRequests((data = []) => [...data, newlyRejectedRequest]);
    }
    toast.success(
      manageAccountSection === ManageAccountSection.BusinessRequests
        ? 'Business Request Rejected'
        : 'Business Claim Rejected',
      {
        hideProgressBar: true,
        closeButton: (
          <IoClose color={'#FFFFFF'} size={24} style={{ marginTop: '6px' }} />
        )
      }
    );
    setRequestedId((prevState) => ({
      ...prevState,
      requestIdToReject: undefined
    }));
    setRejectionReason('');
  }

  if (isLoadingRequests) {
    return (
      <div className="flex w-full h-screen flex-col items-center justify-center">
        <Spinner />;
      </div>
    );
  }

  if (isError) {
    return (
      <ErrorPage>
        <span>{`Unable to load  ${
          manageAccountSection === ManageAccountSection.BusinessClaims
            ? 'business claims'
            : 'business requests'
        } from server.`}</span>
      </ErrorPage>
    );
  }
  const sortedRequests = requests?.sort((a, b) => {
    const dateA = new Date(a.createdAt ?? defaultDate.toISOString());
    const dateB = new Date(b.createdAt ?? defaultDate.toISOString());
    return dateB.getTime() - dateA.getTime();
  });

  return (
    <>
      {sortedRequests && sortedRequests?.length > 0 ? (
        sortedRequests.map((sortedRequest, i) => (
          <div className="flex flex-col w-full mb-4" key={sortedRequest.id}>
            <PendingRequestCard
              request={sortedRequest}
              claimRequestStatus={ClaimRequestStatus.Requested}
              onClickBusinessAccount={(request) => {
                setBusinessUser(request.user);
              }}
              onClickAccept={(request) =>
                setRequestedId((prevState) => ({
                  ...prevState,
                  requestIdToAccept: request.id
                }))
              }
              onClickReject={(request) =>
                setRequestedId((prevState) => ({
                  ...prevState,
                  requestIdToReject: request.id
                }))
              }
              section={manageAccountSection}
            />
          </div>
        ))
      ) : (
        <EmptyCard
          text={
            manageAccountSection === ManageAccountSection.BusinessClaims
              ? 'No Business Claims'
              : 'No Business Requests'
          }
        />
      )}

      {!!requestedId.requestIdToAccept && (
        <ConfirmationPopup
          isModalOpen={!!requestedId.requestIdToAccept}
          handleClickConfirmButton={async () => {
            await handleOnClickAcceptAsync();
          }}
          closeModal={() =>
            setRequestedId((prevState) => ({
              ...prevState,
              requestIdToAccept: undefined
            }))
          }
          popupLabel={`Are you sure?`}
          confirmButtonText="Confirm"
          isErrorButton={true}
          isLoading={isLoading}
          position="fixedCenter"
          height={'200px'}
        >
          <div className="body-regular-csSpecial text-onSurfaceVariant">
            Once accepted, you can't undo this action.
          </div>
        </ConfirmationPopup>
      )}
      {!!requestedId.requestIdToReject && (
        <ConfirmationPopup
          isModalOpen={!!requestedId.requestIdToReject}
          handleClickConfirmButton={async () => {
            await handleOnClickRejectAsync();
          }}
          closeModal={() => {
            setFormError(undefined);
            setRequestedId((prevState) => ({
              ...prevState,
              requestIdToReject: undefined
            }));
          }}
          popupLabel={`Are you sure?`}
          confirmButtonText="Confirm"
          isErrorButton={true}
          isLoading={isLoading}
          position="fixedCenter"
          height={'370px'}
        >
          <div className="flex flex-col">
            <div className="body-regular-csSpecial text-onSurfaceVariant">
              People who are not approved will be able to appeal your decision.
            </div>
            <div className="mt-4">
              <div
                className="font-inter text-base font-bold text-onSurface"
                style={{ lineHeight: '24px' }}
              >
                Reason for rejection
              </div>
              <ImprovedTextArea
                value={rejectionReason}
                className="h-full py-4 px-4 focus:outline-none"
                wrapperClassName="border mt-3 rounded-lg bg-white flex-row items-center focus:border-onSurface px-2"
                placeHolder="State reason for rejection"
                onChange={(e) => setRejectionReason(e.currentTarget.value)}
                error={formError}
                isError={!!formError}
                maxCharacterCount={280}
              />
            </div>
          </div>
        </ConfirmationPopup>
      )}

      {!!businessUser && (
        <ProfileModal
          businessUser={businessUser}
          onCloseModal={() => setBusinessUser(undefined)}
        />
      )}
    </>
  );
}

export default NewRequests;
