import { requestResponse } from 'Colugo/provider/HttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import {
  swrRequestResponse,
  useGetHttpClient
} from '../../provider/useGetHttpClient';
import AbstractOperations from '../AbstractOperations';
import { IListingSuggestionBucket } from 'Colugo/interfaces/suggestions/IListingSuggestionBucket';

@scoped(Lifecycle.ContainerScoped)
class SuggestionOperations extends AbstractOperations {
  protected basePath: string = 'listingSuggestionBucket';

  public async createAsync(
    listingSuggestionBucket: IListingSuggestionBucket
  ): requestResponse<IListingSuggestionBucket> {
    return await this.executePost(``, listingSuggestionBucket);
  }

  public async manageBucketAsync(
    listingSuggestionBucketId: string,
    isAccepting: boolean
  ): requestResponse {
    return await this.executePut(
      `${listingSuggestionBucketId}/manage?isAccepting=${isAccepting}`
    );
  }

  public async manageAsync(
    listingSuggestionId: string,
    isAccepting: boolean
  ): requestResponse {
    return await this.executePut(
      `/listingSuggestion/${listingSuggestionId}/manage?isAccepting=${isAccepting}`
    );
  }
}

const useReqListSuggestionsAsync = (
  listingId?: string
): swrRequestResponse<IListingSuggestionBucket[]> => {
  return useGetHttpClient(
    `listingSuggestionBucket/list${listingId ? '?listingId=' + listingId : ''}`,
    {
      revalidateOnFocus: true
    },
    false
  );
};

const useReqGetSuggestionAsync = (
  listingSuggestionId?: string
): swrRequestResponse<IListingSuggestionBucket> => {
  return useGetHttpClient(
    `listingSuggestionBucket/${listingSuggestionId}`,
    {
      revalidateOnFocus: true
    },
    false
  );
};

export default SuggestionOperations;
export { useReqListSuggestionsAsync, useReqGetSuggestionAsync };
