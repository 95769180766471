import { Modal } from '@bindystreet/bindystreet.kit.react';

type Props = {
  isConfirmModalOpen: boolean;
  isAccepting: boolean;
  setIsConfirmModalOpen: (isOpen: boolean) => void;
  onConfirm: () => void;
};

function ConfirmManageModal(props: Props) {
  const { isConfirmModalOpen, setIsConfirmModalOpen, onConfirm, isAccepting } =
    props;

  return (
    <Modal
      position="center"
      className="rounded-lg"
      overlay
      size="xl"
      isMenuOpen={isConfirmModalOpen}
    >
      <div className="m-6">
        <div className="w-full flex-row flex">
          <div className="font-bold text-2xl">
            {isAccepting
              ? 'Apply all suggestions to this Listing?'
              : 'Reject all Suggestions?'}
          </div>
          <div
            className="flex ml-auto cursor-pointer mr-1 text-secondaryText font-light"
            onClick={() => setIsConfirmModalOpen(false)}
          >
            X
          </div>
        </div>
        <div className="text-md text-primaryCharcoal mt-4">
          {isAccepting
            ? "All suggestions on this submission will be applied and there is no way to undo this action. Are you sure that you'd like to apply all suggestions made by this user to this Listing?"
            : 'Rejecting all suggestions will remove these user generated suggestions permanently and they will not be able to be restored. Are you sure that you would like to reject all the user’s suggestions?'}
        </div>
        <div className="w-full flex-row flex text-white mt-2.5">
          <div className="flex-grow" />
          <div
            className="bg-background rounded-lg cursor-pointer px-12 py-3 font-nunito"
            onClick={() => setIsConfirmModalOpen(false)}
          >
            Cancel
          </div>
          <div
            className={`${
              isAccepting ? 'bg-primaryTeal' : 'bg-error1'
            } rounded-lg ml-4 cursor-pointer px-12 py-3 font-nunito`}
            onClick={onConfirm}
          >
            {isAccepting ? 'Accept All' : 'Delete Suggestions'}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmManageModal;
