import { IPromotion } from 'Colugo/interfaces/promotions/IPromotion';
import EventSearch from 'components/events/EventSearch';
import CreatePromotionModal from 'components/promotions/CreateOrEditPromotionModal';
import PromotionSearch from 'components/promotions/PromotionSearch';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchedEntityType } from './EntitySearch';
import RecentEventsEditor from './RecentEventsEditor';
import RecentListingsEditor from './RecentListingsEditor';
import RecentPromotionsEditor from './RecentPromotionsEditor';

function DashboardContainer() {
  const navigate = useNavigate();
  const [isCreatePromotionModalOpen, setIsCreatePromotionModalOpen] =
    useState(false);

  const [promotionToEdit, setPromotionToEdit] = useState<
    IPromotion | undefined
  >();
  const [promotionIdToDelete, setPromotionIdToDelete] = useState<
    string | undefined
  >(undefined);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isCreatePromotion = params.get('createPromotion') === 'true';
  const searchType = params.get('searchType');

  useEffect(() => {
    if (isCreatePromotion) {
      setPromotionToEdit(undefined);
      setIsCreatePromotionModalOpen(isCreatePromotion);
    }
  }, [isCreatePromotion, navigate]);

  if (isCreatePromotionModalOpen && promotionIdToDelete) {
    setIsCreatePromotionModalOpen(false);
  }

  function SearchOptions() {
    switch (searchType) {
      case SearchedEntityType[SearchedEntityType.Event]:
        return <EventSearch />;
      case SearchedEntityType[SearchedEntityType.Promotion]:
        return <PromotionSearch />;
      default:
        return (
          <div>
            <RecentListingsEditor />
            <div className="flex">
              <RecentEventsEditor />
              <RecentPromotionsEditor
                setPromotionToEdit={setPromotionToEdit}
                setIsCreatePromotionModalOpen={setIsCreatePromotionModalOpen}
                promotionIdToDelete={promotionIdToDelete}
                setPromotionIdToDelete={setPromotionIdToDelete}
              />
            </div>
          </div>
        );
    }
  }

  return (
    <div className="flex-1 flex bg-red-500 h-auto pb-10 bg-theme1">
      <div>
        <div>
          <CreatePromotionModal
            isCreateModalOpen={isCreatePromotionModalOpen}
            setIsCreateModalOpen={setIsCreatePromotionModalOpen}
            modalTitle={
              !!promotionToEdit ? 'Edit Promotion' : 'Create a Promotion'
            }
            promotion={promotionToEdit}
          />
          <div
            style={{
              width: '90vw'
            }}
          >
            {SearchOptions()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardContainer;
