import { Input, Spinner } from '@bindystreet/bindystreet.kit.react';
import { InputFormEvent } from '@bindystreet/bindystreet.kit.react/dist/components/Input';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import ListingOperations from 'Colugo/operations/listings/ListingOperations';
import ListingResult from 'components/shared/ListingResult';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BsFillPlusCircleFill, BsSearch } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';

const listingOperations = container.resolve(ListingOperations);

type Props = {
  selectedListing: IListing | undefined;
  setSelectedListing: (listing: IListing | undefined) => void;
  goBack?: () => void;
  error?: string | false | undefined;
  handleClearEventSelection?: () => void;
  isDisabled?: boolean;
  className?: string | undefined;
  flyoutWidth?: string | number | undefined;
  flyoutStyle?: React.CSSProperties | undefined;
};

function ListingSearchModal(props: Props) {
  const {
    setSelectedListing,
    goBack,
    selectedListing,
    error,
    isDisabled = false,
    handleClearEventSelection,
    className,
    flyoutStyle
  } = props;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(true);
  const [searchedListings, setSearchedListings] = useState<IListing[]>([]);
  const [isSearchResultsLoading, setIsSearchResultsLoading] =
    useState<boolean>(false);

  const { errorToast } = useErrorToast();
  const newSpotAreaRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const resultItemCount = useMemo(() => {
    return searchedListings.length;
  }, [searchedListings]);

  function handleOnChange(e: InputFormEvent) {
    setSearchQuery(e.currentTarget.value);
  }

  function handleClearSelection() {
    setSearchQuery('');
    handleClearEventSelection && handleClearEventSelection();
  }

  const debouncedSearchListings = useAsyncDebounce(
    async (searchQuery: string) => {
      return await listingOperations.searchListingsAsync(searchQuery);
    },
    200
  );

  const onSearchListings = useCallback(async () => {
    setIsSearchResultsLoading(true);
    const { data, error } = await debouncedSearchListings(searchQuery);
    if (error) {
      errorToast('An error occured, please try again');
      return;
    }
    setIsSearchResultsLoading(false);
    setSearchedListings(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, setSearchedListings, debouncedSearchListings]);

  useEffect(() => {
    if (searchQuery.length === 0) {
      setIsFlyoutOpen(false);
    } else if (searchQuery.length > 2) {
      setIsFlyoutOpen(true);
      onSearchListings();
    }
  }, [searchQuery, onSearchListings]);

  function redirectCreateListing() {
    goBack && goBack();
    navigate(PuffinRoutes.CreateListing);
  }

  const listingFlyout = (
    <div>
      {isFlyoutOpen && (
        <div
          style={flyoutStyle || { zIndex: 100 }}
          className={
            className ||
            `border bg-white rounded-lg shadow absolute w-full overflow-y-auto flex flex-col z-50`
          }
        >
          {isSearchResultsLoading && (
            <div className="mt-1 pt-1">
              <Spinner />
            </div>
          )}
          {resultItemCount > 0 && !isSearchResultsLoading && (
            <div
              className="pb-1 px-4 overflow-y-scroll"
              style={{ height: '230px' }}
            >
              {searchedListings.map((listing, i) => (
                <ListingResult
                  listing={listing}
                  key={i}
                  onClick={() => {
                    setSelectedListing(listing);
                    setIsFlyoutOpen(false);
                  }}
                />
              ))}
            </div>
          )}
          {resultItemCount === 0 && !isSearchResultsLoading && (
            <div className="mx-6 my-2 bg-lightGreen h-16 flex justify-center items-center rounded">
              <span className="text-primaryCharcoal font-nunito font-normal text-base  mr-1">
                No results found, please search again or
              </span>

              <span
                className="underline text-primaryCharcoal font-nunito font-bold text-base cursor-pointer"
                onClick={redirectCreateListing}
              >
                create a new Business
              </span>
            </div>
          )}
          <div className="flex flex-grow" />
          <div
            className="border-t border-black border-opacity-10 flex h-14 px-6 py-3 items-center cursor-pointer hover:bg-grayBorder"
            onClick={redirectCreateListing}
          >
            <BsFillPlusCircleFill
              className="text-primary text-xl cursor-pointer mr-3"
              size={30}
            />
            <span className="font-inter text-base font-bold leading-6">
              Add a new business
            </span>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className={`w-full mt-2 relative`}>
      <div ref={newSpotAreaRef}>
        <div className="w-full flex flex-col text-left relative">
          <Input
            isMandatory={true}
            onChange={handleOnChange}
            size="lg"
            disabled={isDisabled}
            value={selectedListing?.name || searchQuery}
            placeholder="Search Businesses... "
            error={error}
          />
          {!isDisabled && (
            <>
              {selectedListing ? (
                <IoMdClose
                  className="text-2xl text-black absolute mr-2 right-2 top-6 cursor-pointer"
                  onClick={handleClearSelection}
                />
              ) : (
                <BsSearch className="text-2xl text-black absolute mr-2 right-2 top-6" />
              )}
            </>
          )}
        </div>
      </div>
      {listingFlyout}
    </div>
  );
}

export default ListingSearchModal;
