import NoImage from 'images/dashboard/noImage.svg';

interface LocalEntity {
  name: string;
  images?: string[];
}

type Props<T> = {
  entity: T;
  imageProportion: number;
};

function EntityImage<T extends LocalEntity>(props: Props<T>) {
  const { entity, imageProportion } = props;

  return (
    <div style={{ width: imageProportion, height: imageProportion }}>
      <img
        src={
          entity.images && entity.images.length > 0 ? entity.images[0] : NoImage
        }
        alt={entity.name}
        className="rounded-md cursor-pointer"
        style={{
          width: '100%',
          height: '95%',
          objectFit: 'cover'
        }}
      />
    </div>
  );
}

export default EntityImage;
