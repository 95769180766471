import { InputFormEvent } from '@bindystreet/bindystreet.kit.react';
import { IBaseListingRequest } from 'Colugo/interfaces/business/IBaseListingRequest';
import { ReactComponent as SearchIcon } from 'images/dashboard/searchIcon.svg';
import React from 'react';
import { IoClose } from 'react-icons/io5';
import ImprovedInput from './ImprovedInput';

type Props<T> = {
  allRequestsOrClaims: T[];
  setFilteredData: React.Dispatch<React.SetStateAction<T[]>>;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  searchQuery: string;
};

function ManagerClaimRequestSearchBar<T extends IBaseListingRequest>({
  allRequestsOrClaims,
  setFilteredData,
  setSearchQuery,
  searchQuery
}: Props<T>) {
  function handleOnChange(e: InputFormEvent) {
    const value = e.currentTarget.value.toLowerCase();
    setSearchQuery(value);

    if (allRequestsOrClaims && allRequestsOrClaims.length > 0) {
      const filteredRequests = allRequestsOrClaims.filter((allRequest) => {
        const { firstName, lastName } = allRequest.user;
        const { name, address } = allRequest.listing;
        const { addressString, city, country, postcode } = address || {};
        const { clarification, businessConnection, rejectionReason } =
          allRequest;

        const fieldsToCheck = [
          firstName?.toLowerCase(),
          lastName?.toLowerCase(),
          name?.toLowerCase(),
          addressString?.toLowerCase(),
          city?.toLowerCase(),
          country?.toLowerCase(),
          postcode?.toLowerCase(),
          clarification?.toLowerCase(),
          businessConnection?.toLowerCase(),
          rejectionReason?.toLowerCase()
        ];

        return fieldsToCheck.some((field) => field?.includes(value));
      });

      setFilteredData(filteredRequests);
    }
  }

  function handleCancelSearch() {
    setSearchQuery('');
  }

  return (
    <div>
      <ImprovedInput
        value={searchQuery}
        onChange={handleOnChange}
        className="h-full py-4 px-4 focus:outline-none"
        wrapperClassName="border mt-3 rounded-xl bg-white flex-row items-center focus:border-onSurface px-2 mb-3"
        placeHolder="Search Businesses"
        prependJsx={
          <div className="flex items-center flex-col h-full justify-center ml-2">
            <SearchIcon className="text-onSurface" />
          </div>
        }
        appendJsx={
          <div className="flex items-center flex-col h-full justify-center mr-2 cursor-pointer">
            {searchQuery.length > 0 && (
              <div onClick={handleCancelSearch}>
                <IoClose size={20} className="text-onSurface cursor-pointer" />
              </div>
            )}
          </div>
        }
      />
    </div>
  );
}

export default ManagerClaimRequestSearchBar;
