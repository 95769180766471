import { ErrorPage, Modal, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IUser } from 'Colugo/interfaces/identity';
import { useReqListListingsForUserAsync } from 'Colugo/operations/listings/ListingOperations';
import EmptyCard from 'components/shared/EmptyCard';
import ListingCard from 'components/shared/ListingCard';
import { ReactComponent as ProfileIcon } from 'images/dashboard/profileIcon.svg';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';

type Props = {
  businessUser: IUser;
  onCloseModal: () => void;
};

function ProfileModal(props: Props) {
  const { businessUser, onCloseModal } = props;

  const {
    data: listings,
    isLoading,
    isError
  } = useReqListListingsForUserAsync(businessUser.id);
  return (
    <div>
      <Modal
        overlay={true}
        padding="none"
        isMenuOpen
        className="rounded-xl -mt-28"
        size="xl"
        position="fixedCenter"
        styles={{
          overflowY: 'auto',
          height: '592px'
        }}
      >
        <div className="flex flex-col">
          <div
            className="flex flx-row justify-between px-8 py-8 border-b border-outline"
            style={{ borderRadius: '8px 8px 0px 0px' }}
          >
            <div className="flex items-center gap-2">
              <ProfileIcon width={'32px'} height={'32px'} />
              <p className="heading-semibold-l text-onSurface">
                {businessUser?.firstName} {businessUser?.lastName}
              </p>
            </div>
            <IoClose
              size={32}
              className="text-onSurface cursor-pointer"
              onClick={onCloseModal}
            />
          </div>
          <div className="flex flex-col py-6 px-6 gap-8">
            <div>
              <p className="heading-semibold-av text-onSurface">
                Email Address
              </p>
              <div className="bg-surfaceContainerLow border border-outline rounded-lg px-6 py-4 mt-2">
                {businessUser?.email}
              </div>
            </div>

            <div>
              <p className="heading-semibold-av text-onSurface">
                Contact Number
              </p>
              <div className="bg-surfaceContainerLow border border-outline rounded-lg px-6 py-4 mt-2">
                {businessUser?.phoneNumber ?? 'No Phone Number Provided'}
              </div>
            </div>

            <div>
              <p className="heading-semibold-av text-onSurface">
                Ember Businesses
              </p>
              {isLoading && (
                <div className="mt-4">
                  <Spinner />
                </div>
              )}
              {isError && (
                <ErrorPage>
                  <span>Unable to load owned businesses from server</span>
                </ErrorPage>
              )}
              {listings && listings.length > 0 && !isLoading && !isError ? (
                <div className="w-full flex flex-col gap-2 mt-2">
                  {listings.map((listing, index) => (
                    <Link
                      className="w-auto cursor-pointer"
                      key={index}
                      to={`/listing/${listing.id}/general`}
                    >
                      <ListingCard
                        key={index}
                        listing={listing}
                        styles={{ width: '100%' }}
                        shouldShowBusinessName={false}
                      />
                    </Link>
                  ))}
                </div>
              ) : (
                <div className="mt-2">
                  <EmptyCard text="No Businesses" />
                </div>
              )}
            </div>
          </div>
          <div
            className="border-t border-outline"
            style={{
              height: '88px',
              background: 'rgba(242, 240, 235, 1)',
              borderRadius: '0px 0px 8px 8px'
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

export default ProfileModal;
