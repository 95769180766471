import { ITab } from './TabList';

type Props = {
  tab: ITab;
  activeTabName: string;
  tabName: string;
  tabItemsCount: string;
  index: number;
  onClickTab: () => void;
};

function TopTab(props: Props) {
  const { tab, tabName, tabItemsCount, activeTabName, index, onClickTab } =
    props;

  return (
    <div
      className="flex flex-row justify-center items-center h-auto"
      onClick={onClickTab}
      style={{ width: '172px' }}
    >
      <div
        className={`flex flex-row justify-center py-1 cursor-pointer w-full 
          ${tabName === activeTabName ? 'border-primary' : 'border-none'} ${
          index > 0 && 'ml-8'
        }
      
           `}
        style={{ borderBottomWidth: '3px' }}
      >
        <div
          className={`${
            tabName === activeTabName
              ? 'heading-semibold-m-onSurface'
              : 'heading-regular-m-onSurfaceVariant hover:text-onSurface'
          }`}
        >
          {tab.name}
          <span
            className={`ml-1 ${
              tabName === activeTabName
                ? 'heading-semibold-sm text-onSurface'
                : 'heading-semibold-sm  text-onSurfaceVariant'
            }`}
          >
            ({tabItemsCount ?? ''})
          </span>
        </div>
      </div>
    </div>
  );
}

export default TopTab;
