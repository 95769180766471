import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { FiMinus } from 'react-icons/fi';

type Props = {
  renderFlyoutMenu: (index: number) => JSX.Element;
  renderInput: (index: number) => JSX.Element;
  blocksCount: number;
  onAddBlock: () => void;
  onRemoveBlock: (index: number) => void;
  maxBlocksCount: number;
};

function GenerativeLinkInputs(props: Props) {
  const {
    maxBlocksCount,
    renderFlyoutMenu,
    renderInput,
    onAddBlock,
    onRemoveBlock,
    blocksCount
  } = props;
  const [blocks, setBlocks] = useState<number[]>(initializeBlocks(blocksCount));
  const isDisabled = blocks.length === maxBlocksCount;

  function initializeBlocks(count: number): number[] {
    const blocksArray: number[] = [];
    for (let i = 0; i < count; i++) {
      blocksArray.push(i);
    }
    return blocksArray;
  }

  function addBlock() {
    onAddBlock();
    setBlocks((prevBlocks) => [...prevBlocks, prevBlocks.length]);
  }

  function removeBlock(index: number) {
    onRemoveBlock(index);

    setBlocks((prevBlocks) => prevBlocks.filter((_, i) => i !== index));
  }

  return (
    <div>
      {blocks.map((_, i) => (
        <div className="flex justify-between items-center mb-2" key={i}>
          <div className="flex w-full items-center">
            <div className="w-64">{renderFlyoutMenu(i)}</div>
            <div className="ml-4 w-full">{renderInput(i)}</div>
          </div>
          <div className="ml-4">
            {i === 0 ? (
              <button
                className={`w-10 h-10 rounded-full bg-primary flex justify-center items-center ${
                  isDisabled ? 'opacity-50' : 'opacity-100'
                }`}
                onClick={addBlock}
                disabled={isDisabled}
              >
                <FaPlus size={24} className="text-white" />
              </button>
            ) : (
              <button
                className="w-10 h-10 rounded-full bg-theme2 flex justify-center border items-center"
                style={{
                  borderColor: 'rgba(0, 0, 0, 0.10)'
                }}
                onClick={() => removeBlock(i)}
              >
                <FiMinus size={24} className="text-black" />
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default GenerativeLinkInputs;
