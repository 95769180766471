import ImprovedInput from 'components/shared/ImprovedInput';
import ImprovedRadioButton from 'components/shared/ImprovedRadioButton';
import ImprovedTextArea from 'components/shared/ImprovedTextarea';
import React from 'react';
import { Button } from '@bindystreet/bindystreet.kit.react';
import { ILocation } from 'Colugo/interfaces/games';
import { IAddress } from 'Colugo/interfaces/listing/IAddress';

export interface BusinessValidationErrors {
  name?: string;
  address?: string;
  isYourBusiness?: string;
  businessConnection?: string;
  hasAlreadyContacted?: string;
  clarification?: string;
}

export const businessValidationRequiredErrors = {
  name: 'Please provide a business name',
  address:
    'No address selected. Please enter an address or toggle off the Business Address above.',
  isYourBusiness: 'Please answer this question',
  businessConnection:
    'Please provide details on your connection to the business',
  hasAlreadyContacted: 'Please answer this question',
  clarification: 'Please provide a short description',
  location: ''
};

export interface BusinessClaimOrRequestInput {
  location?: ILocation;
  name?: string;
  address?: IAddress | null;
  isYourBusiness: boolean | null;
  businessConnection: string;
  hasAlreadyContacted: boolean | null;
  clarification: string;
}

type Props = {
  claimOrRequestInput: BusinessClaimOrRequestInput;
  setClaimOrRequestInput: React.Dispatch<
    React.SetStateAction<BusinessClaimOrRequestInput>
  >;
  errors: BusinessValidationErrors | null;
  onPressSubmit: () => Promise<void>;
  isSubmitLoading: boolean;
  type: 'Business Request' | 'Claim';
};

function BusinessRequestClaimFooter({
  claimOrRequestInput,
  setClaimOrRequestInput,
  errors,
  onPressSubmit,
  isSubmitLoading,
  type
}: Props) {
  return (
    <div>
      <div className="mt-6">
        <p className="font-inter text-onSurface font-bold text-base">
          Are you the owner of this business?
        </p>
        <div className="flex font-inter text-sm text-black mt-4">
          <div className="flex items-center gap-2">
            <ImprovedRadioButton
              onChange={() =>
                setClaimOrRequestInput({
                  ...claimOrRequestInput,
                  isYourBusiness: true
                })
              }
              checked={claimOrRequestInput.isYourBusiness}
              id="isYourBusiness"
              error={Boolean(errors?.isYourBusiness)}
            />
            Yes
          </div>

          <div className="flex items-center gap-2 ml-6">
            <ImprovedRadioButton
              onChange={() =>
                setClaimOrRequestInput({
                  ...claimOrRequestInput,
                  isYourBusiness: false
                })
              }
              checked={
                claimOrRequestInput.isYourBusiness === null
                  ? null
                  : !claimOrRequestInput.isYourBusiness
              }
              id="isYourBusiness"
              error={Boolean(errors?.isYourBusiness)}
            />
            <span className="ml-1">No</span>
          </div>
        </div>
        <p className="text-red text-sm mt-3 font-medium">
          {errors?.isYourBusiness && 'Please answer this question'}
        </p>
      </div>

      {claimOrRequestInput.isYourBusiness === false && (
        <div className="mt-6">
          <p className="font-inter text-onSurface font-bold text-base mb-2">
            What is your connection to the business?
          </p>
          <p className="font-inter text-onSurfaceVariant font-light text-sm">
            For example, do you represent the business through an agency?
          </p>
          <ImprovedInput
            className="h-full py-4 focus:outline-none text-sm"
            wrapperClassName="border mt-3 rounded-lg bg-white flex-row items-center focus:border-onSurface px-6"
            maxCharacterCount={70}
            onChange={(e) =>
              setClaimOrRequestInput({
                ...claimOrRequestInput,
                businessConnection: e.currentTarget.value
              })
            }
            value={claimOrRequestInput.businessConnection}
            error={
              errors?.businessConnection &&
              'Please provide details on your connection to the business'
            }
            isError={Boolean(errors?.businessConnection)}
          />
        </div>
      )}
      <div className="mt-6">
        <p className="font-inter text-onSurface font-bold text-base">
          Have you been in contact with us before?
        </p>
        <div className="flex font-inter text-sm  text-black  mt-4">
          <div className="flex items-center gap-2">
            <ImprovedRadioButton
              onChange={() =>
                setClaimOrRequestInput({
                  ...claimOrRequestInput,
                  hasAlreadyContacted: true
                })
              }
              checked={claimOrRequestInput.hasAlreadyContacted}
              id="hasAlreadyContacted"
              error={Boolean(errors?.hasAlreadyContacted)}
            />
            Yes
          </div>

          <div className="flex items-center gap-2 ml-6">
            <ImprovedRadioButton
              onChange={() =>
                setClaimOrRequestInput({
                  ...claimOrRequestInput,
                  hasAlreadyContacted: false
                })
              }
              checked={
                claimOrRequestInput.hasAlreadyContacted === null
                  ? null
                  : !claimOrRequestInput.hasAlreadyContacted
              }
              id="hasAlreadyContacted"
              error={Boolean(errors?.hasAlreadyContacted)}
            />
            <span className="ml-1">No</span>
          </div>
        </div>
        <p className="text-red text-sm mt-3 font-medium">
          {errors?.hasAlreadyContacted && 'Please answer this question'}
        </p>
      </div>
      <div className="mt-6">
        <p className="font-inter text-onSurface font-bold text-base mb-2">
          Please provide details to validate your {type}
        </p>
        <div className="font-inter text-onSurfaceVariant font-light text-sm mb-2">
          <div className="flex flex-row">
            1.
            <p className="ml-1">
              If you are the owner or employed at this business, please provide
              further details about your business and your connection to it.
            </p>
          </div>
          <div className="flex flex-row">
            2.
            <p className="ml-1">
              If you are not the owner, please provide additional details to
              support your {type.toLowerCase()} alongside a contact for the
              business so we can verify with them.
            </p>
          </div>
        </div>
        <ImprovedTextArea
          value={claimOrRequestInput.clarification}
          className="h-full pb-10 pt-4 px-4 focus:outline-none"
          wrapperClassName="border rounded-lg flex-row items-center focus:border-onSurface px-2"
          placeHolder="Enter a short description"
          onChange={(e) =>
            setClaimOrRequestInput({
              ...claimOrRequestInput,
              clarification: e.currentTarget.value
            })
          }
          error={errors?.clarification && 'Please provide a short description'}
          isError={Boolean(errors?.clarification)}
          maxCharacterCount={280}
        />
      </div>
      <div className="flex flex-row">
        <div className="ml-auto">
          <Button
            size="lg"
            type="submit"
            isLoading={isSubmitLoading}
            skin={'transparent'}
            onClick={onPressSubmit}
            className="flex flex-row justify-center font-nunito items-center w-auto px-6 py-3 my-4 bg-primary cursor-pointer"
          >
            <label
              className="heading-semibold-av text-white cursor-pointer"
              style={{ lineHeight: '20px' }}
            >
              Submit
            </label>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BusinessRequestClaimFooter;
