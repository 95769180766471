import { AiOutlinePlus } from 'react-icons/ai';
import { FlyoutMenuItem } from "@bindystreet/bindystreet.kit.react";

type Props = {
  tag: FlyoutMenuItem;
  isDisabled: boolean;
  onClick: (item: FlyoutMenuItem) => void;
};

function TagCard(props: Props) {
  const { tag, onClick, isDisabled } = props;

  return (
    <div
      className={`flex flex-row items-center ${
        isDisabled ? 'pointer-events-none' : 'pointer-events-auto'
      }  mr-3 px-4 mb-3 rounded-lg border border-theme6 bg-theme8 cursor-pointer h-10 hover:bg-theme9`}
      onClick={() => onClick(tag)}
    >
      <span className="mr-2 font-nunito font-normal leading-normal inline-block whitespace-nowrap text-lg">
        {tag.label}
      </span>
      <AiOutlinePlus />
    </div>
  );
}

export default TagCard;
