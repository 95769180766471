import { Button, Spinner } from '@bindystreet/bindystreet.kit.react';
import { useLoadScript } from '@react-google-maps/api';
import { IBase } from 'Colugo/interfaces';
import { IIoiValidationError } from 'Colugo/interfaces/IIoiValidationError';
import { ActionLinkType } from 'Colugo/interfaces/event/IEvent';
import { ICategory, ILocation, ITag } from 'Colugo/interfaces/games';
import { IAddress } from 'Colugo/interfaces/listing/IAddress';
import { useReqListCategories } from 'Colugo/operations/categories/CategoryOperations';
import { useReqListTags } from 'Colugo/operations/tags';
import { requestResponse } from 'Colugo/provider/HttpClient';
import ConfirmationPopup from 'components/shared/ConfirmationPopup';
import DashboardSideBarContainer from 'components/sidebar/DashboardSideBarContainer';
import Papa from 'papaparse';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { IoCloudUpload } from 'react-icons/io5';
import { MdSearch } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Resizing } from 'storybook/imageHandling';
import { uploadImage } from 'storybook/imageWorker';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from 'use-places-autocomplete';
import { GOOGLEPLACES_KEY } from 'utility/constants/constants';
import { useErrorToast } from 'utility/hooks/useErrorToast';

export type IBaseWithNameAndImages = IBase & {
  name?: string;
  images?: string[];
  isActive?: boolean;
  categories?: ICategory[];
  tags?: ITag[];
  address?: IAddress;
  location?: ILocation;
};

export type ImportContainerProps<TImportEntity extends IBaseWithNameAndImages> =
  {
    columnHeaders: string[];
    entityString: string;
    locationIndex: number;
    priorityTag1Index: number;
    priorityTag2Index: number;
    priorityTag3Index: number;
    tag1Index: number;
    tag2Index: number;
    tag3Index: number;
    tag4Index: number;
    tag5Index: number;
    nameIndex: number;
    category1Index: number;
    category2Index: number;
    createForCsvUploadAsync: (data: TImportEntity[]) => requestResponse;
    getEntityAsync: (
      parsedColumns: string[],
      getActionLinkType: (linkString: string) => ActionLinkType
    ) => Promise<TImportEntity | undefined>;
    validateEntity: (entity: TImportEntity) => IIoiValidationError[];
    checkNameForLocationAsync: (
      data: TImportEntity
    ) => requestResponse<boolean>;
  };

function ImportContainer<TImportEntity extends IBaseWithNameAndImages>(
  props: ImportContainerProps<TImportEntity>
) {
  const {
    columnHeaders,
    entityString,
    locationIndex,
    priorityTag1Index,
    priorityTag2Index,
    priorityTag3Index,
    tag1Index,
    tag2Index,
    tag3Index,
    tag4Index,
    tag5Index,
    nameIndex,
    category1Index,
    category2Index,
    createForCsvUploadAsync,
    getEntityAsync,
    validateEntity,
    checkNameForLocationAsync
  } = props;

  const { errorToast } = useErrorToast();
  const [libraries] = useState<
    ('places' | 'drawing' | 'geometry' | 'visualization')[]
  >(['places']);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLEPLACES_KEY,
    libraries
  });

  const { setValue } = usePlacesAutocomplete();

  function getAddressValue(
    components: google.maps.GeocoderAddressComponent[],
    key: string
  ) {
    return components.find((component) =>
      component.types.find((type) => type === key)
    )?.long_name;
  }

  function getListingAddress(
    components: google.maps.GeocoderAddressComponent[]
  ): IAddress {
    return {
      houseNumber:
        getAddressValue(components, 'street_number') ||
        getAddressValue(components, 'subpremise'),
      houseName:
        getAddressValue(components, 'premise') ||
        getAddressValue(components, 'establishment'),
      street: getAddressValue(components, 'route'),
      city: getAddressValue(components, 'postal_town'),
      postcode: getAddressValue(components, 'postal_code'),
      country: getAddressValue(components, 'country'),
      county: getAddressValue(components, 'county')
    };
  }

  const { data: allCategories, isLoading: areCategoriesLoading } =
    useReqListCategories();
  const { data: allTags, isLoading: areTagsLoading } = useReqListTags();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entitiesToCreate, setEntitiesToCreate] = useState<
    TImportEntity[] | undefined
  >();
  const [failedEntities, setFailedEntities] = useState<string[]>([]);
  const [importProgressInformation, setImportProgressInformation] =
    useState<string>();

  async function onCsvDropAsync(result: Papa.ParseResult<unknown>) {
    setIsLoading(true);
    if (result.errors.length > 0) {
      result.errors.forEach((error: any) => console.error(error));
      errorToast('Failed to read csv');
      setIsLoading(false);
      return;
    }

    const data = result.data.filter(
      (row: any) => !!(row as string[])[0]
    ) as string[][];

    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i] !== columnHeaders[i]) {
        errorToast('Provided column headers do not match the expected format');
        errorToast(
          `Expected ${columnHeaders[i]} for column number ${
            i + 1
          } but instead found ${data[0][i]}`
        );
        setIsLoading(false);
        return;
      }
    }

    checkForRowDuplication(data);

    let entities: TImportEntity[] = [];

    const localFailedEntities: string[] = [];
    for (let i = 1; i < data.length; i++) {
      setImportProgressInformation(
        `Processing ${entityString} #${i}/${data.length - 1} ${
          data[i][nameIndex]
        }`
      );
      const newEntity = await getEntityFromDataAsync(data[i]);

      if (!newEntity) {
        localFailedEntities.push(data[i][nameIndex]);
        continue;
      }

      const { data: isUnique } = await checkNameForLocationAsync(newEntity);

      if (!isUnique) {
        errorToast(
          `${entityString} ${newEntity.name} is not unique for location and name, skipping`
        );
        localFailedEntities.push(data[i][nameIndex]);
        continue;
      }

      entities.push(newEntity);
    }
    setFailedEntities(localFailedEntities);

    if (entities.length === data.length - 1) {
      setIsLoading(false);
      await createEntitiesAsync(entities);
    }
    setEntitiesToCreate(entities);
    setImportProgressInformation(undefined);
    setIsLoading(false);
  }

  const imageSizing: Resizing = {
    isCrop: true,
    resolution: {
      width: 1024,
      height: 1024
    }
  };

  async function populateLocationInformationAsync(
    address: string,
    newEntity: TImportEntity
  ): Promise<TImportEntity> {
    setValue(address);
    const results = await getGeocode({ address: address });

    const { lat, lng } = getLatLng(results[0]);

    const entityAddress: IAddress = getListingAddress(
      results[0].address_components
    );
    newEntity.location = {
      latitude: lat,
      longitude: lng
    };
    newEntity.address = entityAddress;
    return newEntity;
  }

  async function populateImagesAsync(entity: TImportEntity) {
    const entityImages: string[] = [];
    if (!entity.images) {
      return entityImages;
    }
    setImportProgressInformation((lp) => lp + '\n');
    for (let i = 0; i < entity.images.length; i++) {
      setImportProgressInformation((lp) => lp + `#${i + 1}`);
      let imageUrl =
        entity.images[i] && (await uploadImage(entity.images[i], imageSizing));
      if (imageUrl) {
        entityImages.push(imageUrl);
      }
    }
    entity.images = entityImages;
    return entity.images;
  }

  async function createEntitiesAsync(
    entities?: TImportEntity[]
  ): Promise<void> {
    if (!entities || entities.length === 0) {
      errorToast('No entities available to be created.');
      return;
    }
    setEntitiesToCreate(undefined);
    const entityCount = entities.length;
    let i: number = 0;

    setIsLoading(true);
    for (let entity of entities) {
      i++;
      setImportProgressInformation(
        `Uploading ${entityString} images for ${entityString} #${i}/${entityCount} ${entity.name}`
      );
      entity.images = await populateImagesAsync(entity);
    }

    const { error } = await createForCsvUploadAsync(entities);
    if (error) {
      errorToast(`Error creating ${entityString}.`);
      console.error(error);
      setEntitiesToCreate(undefined);
      return;
    }

    setEntitiesToCreate(undefined);
    toast.success(`${entityString}s created successfully.`);
    navigate(`/?searchType=${entityString}`);
  }

  function checkForRowDuplication(data: string[][]) {
    const duplicateRows: string[] = [];
    const hasDuplicateRow = data.some((row: string[], index: number) =>
      data.some((otherRow: string[], otherIndex: number) => {
        const entityId = row[0]?.trim();
        const name = row[1]?.trim();
        const otherEntityId = otherRow[0]?.trim();
        const otherName = otherRow[1]?.trim();

        const isDuplicate =
          entityId &&
          name &&
          otherEntityId &&
          otherName &&
          entityId === otherEntityId &&
          name === otherName &&
          index !== otherIndex;

        if (isDuplicate) {
          duplicateRows.push(name);
        }

        return isDuplicate;
      })
    );

    if (hasDuplicateRow) {
      errorToast(`CSV contains duplicate  ${entityString}s`);
      duplicateRows.forEach((dr) => console.log(dr));
      return;
    }
  }

  function getActionLinkType(actionLinkTypeString: string) {
    switch (actionLinkTypeString) {
      case 'Book Now':
        return ActionLinkType.BookNow;
      case 'Book Tickets':
        return ActionLinkType.BookTickets;
      case 'Contact':
        return ActionLinkType.Contact;
      case 'Learn More':
        return ActionLinkType.LearnMore;
      case 'View Menu':
        return ActionLinkType.ViewMenu;
      default:
        return ActionLinkType.None;
    }
  }

  //NOTE: This function is responsible for parsing the hardcoded strings used for categories or tags and ensuring that they correspond to existing entities
  function parseSubEntity(
    parsedColumns: string[],
    index1: number,
    index2: number,
    subEntities: IBaseWithNameAndImages[] | undefined,
    descriptor: string
  ) {
    const invalidSubEntityNames = parsedColumns
      .slice(index1, index2)
      .filter(
        (t) => t && !subEntities?.some((at) => compareBaseString(at.name, t))
      );

    if (invalidSubEntityNames.length > 0) {
      const errorMessage = `For event: ${parsedColumns[nameIndex]} the following ${descriptor} names could not be bound to existing ${descriptor}s: ${invalidSubEntityNames}.`;
      errorToast(errorMessage);
      console.error(errorMessage);

      return false;
    }
    return true;
  }

  function compareBaseString(a?: string, b?: string): boolean {
    return cleanStringForComparison(a) === cleanStringForComparison(b);
  }

  function cleanStringForComparison(a?: string): string | undefined {
    return a?.replace(/\n/g, '').toLowerCase().trim();
  }

  async function getEntityFromDataAsync(
    parsedColumns: string[]
  ): Promise<TImportEntity | undefined> {
    if (
      !parseSubEntity(
        parsedColumns,
        priorityTag1Index,
        tag5Index,
        allTags,
        'tag'
      )
    ) {
      return undefined;
    }

    if (
      !parseSubEntity(
        parsedColumns,
        category1Index,
        category2Index,
        allCategories,
        'categories'
      )
    ) {
      return undefined;
    }

    let entity: TImportEntity | undefined = await getEntityAsync(
      parsedColumns,
      getActionLinkType
    );

    const categories = allCategories
      ?.filter(
        (c) =>
          compareBaseString(c.name, parsedColumns[category1Index]) ||
          compareBaseString(c.name, parsedColumns[category2Index])
      )
      .map((c) => {
        return { name: c.name, id: c.id, key: c.key } as ICategory;
      });

    let priorityTags =
      allTags?.filter(
        (t) =>
          compareBaseString(t.name, parsedColumns[priorityTag1Index]) ||
          compareBaseString(t.name, parsedColumns[priorityTag2Index]) ||
          compareBaseString(t.name, parsedColumns[priorityTag3Index])
      ) || [];
    priorityTags = priorityTags.map((t) => {
      return { id: t.id, name: t.name, priority: true };
    });
    const otherTags =
      allTags?.filter(
        (t) =>
          compareBaseString(t.name, parsedColumns[tag1Index]) ||
          compareBaseString(t.name, parsedColumns[tag2Index]) ||
          compareBaseString(t.name, parsedColumns[tag3Index]) ||
          compareBaseString(t.name, parsedColumns[tag4Index]) ||
          compareBaseString(t.name, parsedColumns[tag5Index])
      ) || [];

    if (!entity) {
      return undefined;
    }

    entity.categories = categories;
    entity.tags = priorityTags.concat(otherTags);

    if (parsedColumns[locationIndex]) {
      entity = await populateLocationInformationAsync(
        parsedColumns[locationIndex],
        entity
      );
    }

    const validationErrors = validateEntity(entity);
    if (validationErrors.length > 0) {
      errorToast(`Invalid ${entityString}s provided.`);
      validationErrors.forEach((ve) =>
        errorToast(`${ve.name}:  ${ve.tabMessage}`)
      );
      setIsLoading(false);
      return undefined;
    }

    return entity;
  }

  function ingestCsv(files: File[]) {
    Papa.parse(files[0], {
      complete: onCsvDropAsync
    });
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: ingestCsv,
    accept: '.csv'
  });

  if (
    !allCategories ||
    areCategoriesLoading ||
    !allTags ||
    areTagsLoading ||
    !isLoaded
  ) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-row h-screen w-screen">
      <DashboardSideBarContainer />
      <div
        className="mt-48 bg-theme3 rounded-md border border-theme6 flex flex-row p-12 w-1/2 mx-auto"
        style={{ height: '450px' }}
        {...getRootProps()}
      >
        <div className="absolute z-50">
          <ConfirmationPopup
            popupLabel={`Continue without the following ${entityString}s?`}
            isErrorButton={false}
            confirmButtonText="Import"
            closeModal={() => setEntitiesToCreate(undefined)}
            isModalOpen={!!entitiesToCreate}
            handleClickConfirmButton={async () => {
              await createEntitiesAsync(entitiesToCreate);
            }}
          >
            <div>
              {failedEntities.map((entity) => (
                <div key={entity}>{entity}</div>
              ))}
            </div>
          </ConfirmationPopup>
        </div>
        {importProgressInformation || isLoading
          ? importProgressInformation && (
              <div className="mx-40 pl-4 mt-5">
                {importProgressInformation}
                <div className="mt-10">
                  {isLoading && <Spinner size="15" />}
                </div>
              </div>
            )
          : !entitiesToCreate && (
              <div className="bg-theme6 rounded-md border border-gray-700 border-dashed flex-grow flex-row mx-auto ">
                <div className="flex flex-row">
                  <div className="flex-grow" />
                  <div className="flex flex-col h-full">
                    <div className="flex-grow" />
                    <div className="text-gray-500 text-8xl my-4 flex flex-row">
                      <div className="flex-grow"></div>
                      <IoCloudUpload />
                      <div className="flex-grow"></div>
                    </div>
                    <div className="text-lg">Drag &amp; drop files here</div>
                    <div className="text-lg">to upload {entityString}s</div>
                    <div className="text-gray-500 mt-2">
                      Files supported: .csv
                    </div>
                    <div className="my-4">or</div>
                    <Button skin="lowRank">
                      <MdSearch className="m-1" />
                      <div>Browse Files</div>
                    </Button>
                  </div>
                  <input {...getInputProps()} />
                  <div className="flex-grow" />
                </div>
              </div>
            )}
      </div>
    </div>
  );
}

export default ImportContainer;
