import JPEG from 'jpeg-js';
import Jimp from 'jimp';

Jimp.decoders['image/jpeg'] = (data) =>
  JPEG.decode(data, {
    maxMemoryUsageInMB: 6144,
    maxResolutionInMP: 600
  });

const centerCropImage = async (
  file: File,
  resolution: { width: number; height: number }
) => {
  const imageUrl = await readImageFile(file);
  let jimpImage = await Jimp.read(imageUrl);
  const width = jimpImage.bitmap.width;
  const height = jimpImage.bitmap.height;
  const mime = jimpImage.getMIME();

  const targetWidthHeightRatio = resolution.width / resolution.height;
  const inputWidthHeightRatio = width / height;

  const isInputWidthHeightRatioLarger =
    inputWidthHeightRatio > targetWidthHeightRatio;

  const cropHeight = isInputWidthHeightRatioLarger
    ? height
    : width * targetWidthHeightRatio;
  const cropWidth = isInputWidthHeightRatioLarger
    ? height * (1 / targetWidthHeightRatio)
    : width;

  const cropY = isInputWidthHeightRatioLarger ? 0 : (height - cropHeight) / 2;
  const cropX = isInputWidthHeightRatioLarger ? (width - cropWidth) / 2 : 0;

  jimpImage = jimpImage.crop(cropX, cropY, cropWidth, cropHeight);

  if (width > resolution.width || height > resolution.height) {
    jimpImage = jimpImage.resize(resolution.width, resolution.height);
  }

  const buffer = await jimpImage.getBufferAsync(mime);

  const newFile = new File([buffer as BlobPart], file.name, { type: mime });
  return newFile;
};

export const readImageFile = async (file: File): Promise<string> => {
  const reader = new FileReader();
  return new Promise((resolve) => {
    const resolver = () => {
      reader.removeEventListener('load', resolver);
      resolve(reader.result as string);
    };
    reader.addEventListener('load', resolver, false);
    reader.readAsDataURL(file);
  });
};

export default centerCropImage;
