import ConfirmationPopup from 'components/shared/ConfirmationPopup';
import { useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import { useErrorToast } from 'utility/hooks/useErrorToast';

interface LocalEntity {
  id?: string;
  name: string;
}

type Props<T> = {
  localEntity: T;
  label: 'Event' | 'Business';
  onDeleteAsync: () => Promise<
    { error: Response; data: undefined } | { data: any; error: undefined }
  >;
};

function DangerZone<T extends LocalEntity>(props: Props<T>) {
  const { localEntity, label, onDeleteAsync } = props;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { errorToast } = useErrorToast();
  const navigate = useNavigate();

  async function handleDeleteEntityAsync() {
    if (!localEntity.id) {
      errorToast(`No ${label} found, Please try again`);
      return;
    }

    navigate('/');

    const { error } = await onDeleteAsync();

    if (error) {
      errorToast(`Failed to delete ${label}, please try again`);
      return false;
    }
  }

  const isListing = label === 'Business';

  return (
    <div className="flex flex-col w-full">
      <div className="mt-2 text-2xl font-bold font-inter text-dangerRed">
        Danger Zone
      </div>
      <div className="flex flex-row w-full mt-6">
        <div className="flex flex-col items-start" style={{ width: '85%' }}>
          <div className="font-normal text-base font-nunito text-primaryCharcoal">
            Please note, once {label === 'Event' ? 'an' : 'a'}{' '}
            {isListing ? 'business' : 'event'} is permanently deleted, this
            action cannot <br /> be undone. Are you sure you want to delete{' '}
            {isListing ? 'your business' : 'this Event'}?
          </div>
          <div
            className="bg-dangerRed mt-4 rounded-lg flex flex-row justify-center items-center px-6 py-2 border-grayBorder cursor-pointer hover:bg-dangerHover"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <div className="text-white font-nunito text-base font-bold">
              Delete {isListing ? 'Business' : 'Event'}
            </div>
            <AiFillDelete color="white" className="ml-2" size={24} />
          </div>
        </div>
        <div className="border-r-2 border-r-gray ml-6"></div>
        <div
          style={{ width: '247px' }}
          className="ml-6 w-full text-sm font-inter font-normal"
        >
          This change is permanent and immediate. Deleting your{' '}
          {isListing ? 'Ember Business' : 'event'} will completely remove{' '}
          {isListing ? 'your' : 'its'} information from our app.
        </div>
      </div>

      {isDeleteModalOpen && (
        <ConfirmationPopup
          isModalOpen={isDeleteModalOpen}
          handleClickConfirmButton={handleDeleteEntityAsync}
          closeModal={() => setIsDeleteModalOpen(false)}
          popupLabel={`Delete ${localEntity.name}`}
          confirmButtonText={`Yes, Delete my ${label}`}
          isErrorButton={true}
          height="230px"
        >
          <div className="font-nunito text-lg font-normal">
            Deleting this {label} is permanent, and will remove this {label}{' '}
            from our database. Are you sure that you want to delete?
          </div>
        </ConfirmationPopup>
      )}
    </div>
  );
}

export default DangerZone;
