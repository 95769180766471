import { IBusinessClaim } from 'Colugo/interfaces/business/IBusinessClaim';
import { IBusinessRequest } from 'Colugo/interfaces/business/IBusinessRequest';
import { ReactComponent as RightArrow } from 'images/dashboard/arrowRight.svg';
import { ReactComponent as ProfileIcon } from 'images/dashboard/profileIcon.svg';
import { Link } from 'react-router-dom';
import { formatAddress } from 'utility/general/formatHelpers';

type Props = {
  request: IBusinessRequest | IBusinessClaim;
  isAccepted: boolean;
  onClickBusinessAccount: (request: IBusinessRequest | IBusinessClaim) => void;
};

function AcceptedRejectedCard(props: Props) {
  const { isAccepted, request, onClickBusinessAccount } = props;

  return (
    <div className="bg-surface border rounded-xl border-outline w-full flex flex-col">
      <div className="flex flex-row justify-between items-center py-4 px-6 border-outline border-b">
        <div className="flex flex-col gap-1">
          <p className="heading-bold-m text-primaryDark">
            {request.listing.name}
          </p>
          <p className="heading-regular-av text-onSurfaceVariant">
            {request.listing.address &&
            Object.keys(request.listing.address).length > 3
              ? formatAddress(request.listing.address)
              : 'No Address Provided'}
          </p>
          <div
            className="flex flex-row items-center gap-1 mt-1 cursor-pointer"
            onClick={() => onClickBusinessAccount(request)}
          >
            <ProfileIcon />
            <span className="heading-semibold-sm text-onSurface">
              {request.user.firstName} {request.user.lastName}
            </span>
            <RightArrow className="mt-1" />
          </div>
        </div>
        <div className="flex flex-row justify-between gap-4">
          <button
            type="button"
            className={`border ${
              isAccepted
                ? 'border-green bg-greenContainer text-green cursor-default'
                : 'border-red bg-redContainer text-red'
            } rounded-lg flex flex-row justify-center items-center body-bold-l py-2 px-4`}
            style={{ width: '102px', height: '38px' }}
            disabled
          >
            {isAccepted ? 'Accepted' : 'Rejected'}
          </button>
          {isAccepted && (
            <Link to={`/listing/${request.listing.id}/general`}>
              <button
                type="button"
                className="border border-onSurface rounded-lg flex flex-row justify-center items-center text-onSurface body-bold-l py-2 px-4 hover:opacity-60"
                style={{ width: '102px', height: '38px' }}
              >
                View
              </button>
            </Link>
          )}
        </div>
      </div>
      {!isAccepted && (
        <div className="flex flex-row justify-between items-center py-4 px-6">
          <p className="heading-regular-sm text-onSurfaceVariant text-justify">
            {request.rejectionReason}
          </p>
        </div>
      )}
    </div>
  );
}

export default AcceptedRejectedCard;
