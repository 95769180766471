import { FlyoutMenu, FlyoutMenuItem } from '@bindystreet/bindystreet.kit.react';
import { IIoiValidationError } from 'Colugo/interfaces/IIoiValidationError';
import { EventType, IEvent } from 'Colugo/interfaces/event/IEvent';
import { EnumHelper } from 'Colugo/utility/helpers';
import { isEqual } from 'lodash';
import { useState } from 'react';
import DateOptionsEditor from './DateOptionsEditor';

enum EventTypes {
  SingleDate = 'Single Date',
  DateRange = 'Date Range'
}
type Props = {
  localEvent: IEvent;
  setLocalEvent: (event: IEvent) => void;
  updateEventAsync: (updatedEvent: IEvent) => Promise<boolean>;
  validationErrors: IIoiValidationError[];
  updateIsActiveForInvalidEventAsync: () => Promise<boolean>;
};

const eventTypeFlyoutMenuItems = EnumHelper.getEnumValuesForFlyout(EventTypes);

const dateTimeSideNote = `Please choose whether your event takes place on one day, or a range of days.`;

function DateTimeContainer(props: Props) {
  const {
    localEvent,
    setLocalEvent,
    updateEventAsync,
    validationErrors,
    updateIsActiveForInvalidEventAsync
  } = props;
  const [activeEventTypeKey, setActiveEventTypeKey] = useState<number>(
    localEvent.type! - 1 || 0
  );

  const [isEventTypeMenuOpen, setIsEventTypeMenuOpen] = useState(false);

  async function handleFlyoutOnChangeAsync(e: FlyoutMenuItem) {
    setActiveEventTypeKey(e.key!);
    const updatedEvent: IEvent = {
      ...localEvent,
      endDate: undefined,
      type: (e.key! + 1) as EventType
    };
    setLocalEvent(updatedEvent);

    const isSuccesful = await updateEventAsync(updatedEvent);
    if (!isSuccesful) {
      setLocalEvent(localEvent);
    }
  }

  return (
    <div className="flex flex-col rounded-lg px-6 py-8 w-full">
      <div className="font-inter text-2xl font-bold">Event Details</div>

      <div className="flex flex-col w-full">
        <div className="mt-3 text-base font-inter font-bold text-primaryCharcoal">
          Event Type*
        </div>

        <div className="flex flex-row w-full mt-2" style={{ height: '84px' }}>
          <div style={{ width: '85%' }}>
            <div style={{ width: '50%' }}>
              <FlyoutMenu
                isEqual={isEqual}
                size="sm"
                activeKey={activeEventTypeKey}
                items={eventTypeFlyoutMenuItems}
                onChange={handleFlyoutOnChangeAsync}
                isMenuOpen={isEventTypeMenuOpen}
                setIsMenuOpen={setIsEventTypeMenuOpen}
              />
            </div>
          </div>
          <div className="border-r-2 border-r-gray ml-6"></div>
          <div
            style={{ width: '247px' }}
            className="ml-6 w-full text-sm font-inter font-normal"
          >
            {dateTimeSideNote}
          </div>
        </div>
        <DateOptionsEditor
          isSingleDate={activeEventTypeKey! + 1 === EventType.SingleDate}
          localEvent={localEvent}
          updateEventAsync={updateEventAsync}
          setLocalEvent={setLocalEvent}
          validationErrors={validationErrors}
          updateIsActiveForInvalidEventAsync={
            updateIsActiveForInvalidEventAsync
          }
        />
      </div>
    </div>
  );
}

export default DateTimeContainer;
