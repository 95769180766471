import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { useReqListSuggestionsAsync } from 'Colugo/operations/suggestions/SuggestionOperations';
import { useParams } from 'react-router-dom';
import SuggestionBucketsComponent from './SuggestionBucketsComponent';

type ListingSuggestionRouteParams = {
  listingId: string;
};

function ListingSuggestionBucketsContainer() {
  const { listingId } = useParams<ListingSuggestionRouteParams>();
  const {
    data: suggestionBuckets,
    isLoading,
    isError
  } = useReqListSuggestionsAsync(listingId);

  if (isError) {
    return (
      <ErrorPage>
        <span>{'Unable to load suggestion from server.'}</span>
      </ErrorPage>
    );
  }

  if (!suggestionBuckets || isLoading) {
    return <Spinner />;
  }

  return (
    <SuggestionBucketsComponent
      suggestionBuckets={suggestionBuckets}
      isListingSpecific={true}
    />
  );
}

export default ListingSuggestionBucketsContainer;
