import { Spinner } from '@bindystreet/bindystreet.kit.react';
import React from 'react';

type Props = {
  text: string;
  isLoading?: boolean;
  isError?: boolean;
  error?: string;
  onRefresh?: () => void;
};
function EmptyCard(props: Props) {
  const { text, isLoading, isError, error, onRefresh } = props;

  return (
    <div
      className={`border border-dashed border-outline rounded-lg w-full ${
        isError ? 'bg-primaryContainer' : 'bg-white'
      }`}
      style={{
        paddingTop: '45px',
        paddingBottom: '45px',
        paddingRight: '24px',
        paddingLeft: '24px'
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <p className="text-center text-onPrimaryContainer text-lg">
          {error}
          <span
            className="font-semibold underline cursor-pointer"
            onClick={onRefresh}
          >
            Refresh
          </span>
        </p>
      ) : (
        <p className="text-center text-onSurfaceVariant text-lg">{text}</p>
      )}
    </div>
  );
}

export default EmptyCard;
