import { HoverIcon } from '@bindystreet/bindystreet.kit.react';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import bindyStreetLogo from 'images/bindyStreetLogo.svg';
import hexagon from 'images/hexagon.svg';
import React, { useMemo, useRef, useState } from 'react';
import { BiSolidCalendar } from 'react-icons/bi';
import { BsBoxArrowLeft, BsPlusSquareFill } from 'react-icons/bs';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { RxDashboard } from 'react-icons/rx';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addSpacesBeforeCapitalLetters } from 'utility/general/formatHelpers';
import SidebarItem from './SidebarItem';

type Props = {
  onClickSignout: (e: React.MouseEvent) => void;
};

export type SidebarMenuName =
  | 'Dashboard'
  | 'NewBusiness'
  | 'NewEvent'
  | 'NewPromotion';

export type IconHoverState = {
  [name in SidebarMenuName]: boolean;
};

function DashboardSidebar(props: Props) {
  const { onClickSignout } = props;
  const navigate = useNavigate();

  const [activeLink, setActiveLink] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLogoutHovered, setIsLogoutHovered] = useState(false);
  const [isIconHovered, setIsIconHovered] = useState<IconHoverState>({
    Dashboard: false,
    NewBusiness: false,
    NewEvent: false,
    NewPromotion: false
  });
  const sidebarRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useMemo(() => {
    setActiveLink(location.search);
  }, [location.search]);

  const isActiveLink = (newActiveLinkName: string) =>
    activeLink === newActiveLinkName;

  const getClassNames = (newActiveLinkName: string) =>
    isActiveLink(newActiveLinkName) ? '' : 'hover:bg-sidebarHover';

  const handleIconHover = (iconName: SidebarMenuName, isHovered: boolean) => {
    setIsIconHovered((prevState) => ({
      ...prevState,
      [iconName]: isHovered
    }));
  };

  const sidebarSizing = isExpanded ? 'w-64' : 'w-28';

  const handleSetIsExpanded = (expanded: boolean) => {
    if (!expanded) {
      setIsExpanded(expanded);
    }
    const sidebar = sidebarRef.current;
    sidebar?.classList.toggle('w-64');
    sidebar?.classList.toggle('w-28');
    if (expanded) {
      setTimeout(() => setIsExpanded(expanded), 150);
    }
  };

  function handleClickDashboardIcon() {
    setActiveLink('');
  }

  const getLinkElement = (
    url: string,
    name: SidebarMenuName,
    onClick?: () => void
  ) => {
    const getIcon = () => {
      switch (name) {
        case 'Dashboard':
          return (
            <RxDashboard
              fontSize="1.5em"
              className={`ml-4 ${!isActiveLink(url) && 'opacity-50'}`}
            />
          );
        case 'NewBusiness':
          return (
            <BsPlusSquareFill
              size={24}
              className={`ml-4 mr-0.5 ${!isActiveLink(url) && 'opacity-50'}`}
            />
          );
        case 'NewEvent':
          return (
            <BiSolidCalendar
              size={24}
              className={`ml-4 mr-0.5 ${!isActiveLink(url) && 'opacity-50'}`}
            />
          );
      }
    };

    return (
      <Link
        to={url}
        className={`flex flex-row mt-4 p-2 rounded-lg text-notSelected ${getClassNames(
          url
        )}`}
        onClick={onClick}
      >
        {getIcon()}
        <span className="ml-2 text-sm text-white">
          {addSpacesBeforeCapitalLetters(name)}
        </span>
      </Link>
    );
  };

  function horizontalLine() {
    return (
      <div
        className="w-full bg-white mt-4 opacity-20"
        style={{ height: '1px' }}
      />
    );
  }

  return (
    <div
      key="sidebar"
      ref={sidebarRef}
      className={`${sidebarSizing} bg-black z-50 inset-y-0 left-0 transition-all duration-300 transform overflow-visible lg:translate-x-0 lg:static lg:inset-0`}
    >
      <div className="absolute right-0 top-11 -mr-5 z-40">
        <div
          onClick={() => {
            handleSetIsExpanded(!isExpanded);
          }}
          className="text-xl cursor-pointer text-white flex flex-col relative"
        >
          <img src={hexagon} alt="hexagon" />
          {isExpanded && <FiChevronLeft className="ml-8 relative bottom-10" />}
          {!isExpanded && (
            <FiChevronRight className="ml-8 relative bottom-10" />
          )}
        </div>
      </div>
      <div className={`flex flex-row pt-12 pb-1 px-auto ml-10`}>
        <Link to={'/'} className={`flex flex-row rounded-lg text-notSelected`}>
          <img src={bindyStreetLogo} alt="bindy street" className="z-50" />
        </Link>
        {isExpanded && (
          <div className="flex flex-col ">
            <div className="flex flex-row mt-1.5 uppercase">
              <span className="text-2xl text-left pl-2 pr-1 inline pt-0.5 pb-1 text-white font-bold">
                Bindy
              </span>
              <span className="text-sm text-left inline pt-1 text-white font-bold">
                ST
              </span>
            </div>
          </div>
        )}
      </div>
      {isExpanded ? (
        <div className="w-full">
          <nav
            className="mt-6 mx-4 text-sm rounded-lg flex flex-col text-gray-500 font-inter"
            style={{ height: '85vh' }}
          >
            {getLinkElement('/', 'Dashboard', handleClickDashboardIcon)}
            {horizontalLine()}
            {getLinkElement(
              '/' + PuffinRoutes.CreateListing,
              'NewBusiness',
              () => navigate(PuffinRoutes.CreateListing)
            )}
            {getLinkElement('/' + PuffinRoutes.CreateEvent, 'NewEvent', () =>
              navigate(PuffinRoutes.CreateEvent)
            )}
            <div className="flex flex-grow"></div>
            <div className="text-white text-xs text-left font-extralight ml-7 font-nunito"></div>
            <div
              className={`cursor-pointer flex-row flex mt-3 pl-4 pt-2 ml-1 hover:bg-error1 text-notSelected rounded-lg ${
                isLogoutHovered ? 'opacity-100' : ''
              }`}
              onMouseEnter={() => setIsLogoutHovered(true)}
              onMouseLeave={() => setIsLogoutHovered(false)}
              onClick={onClickSignout}
            >
              <BsBoxArrowLeft
                className={`text-2xl pb-1  ${
                  isLogoutHovered ? 'opacity-100' : 'opacity-50'
                }`}
              />
              <span className="pl-2 mb-2 text-sm text-white opacity-100 hover:opacity-100">
                Log out
              </span>
            </div>
          </nav>
        </div>
      ) : (
        <nav className="mt-6 text-gray-500" style={{ height: '85vh' }}>
          <div className="flex flex-row h-full font-inter">
            <div className="flex-grow" />

            <div className="flex flex-col mx-auto">
              <Link
                to="/"
                className="mt-1"
                onClick={() => {
                  handleClickDashboardIcon();
                }}
              >
                <HoverIcon
                  icon={<RxDashboard />}
                  text="Dashboard"
                  isActive={isActiveLink('')}
                />
              </Link>
              {horizontalLine()}
              <div className="mt-2">
                <SidebarItem
                  handleIconHover={handleIconHover}
                  icon={
                    <BsPlusSquareFill
                      size={24}
                      color={isIconHovered.NewBusiness ? 'white' : '#738C89'}
                    />
                  }
                  itemName="NewBusiness"
                  itemPath={PuffinRoutes.CreateListing}
                  isActiveLink={() => isActiveLink(PuffinRoutes.CreateListing)}
                  setActiveLink={() => {
                    setActiveLink(PuffinRoutes.CreateListing);
                  }}
                />
                <SidebarItem
                  handleIconHover={handleIconHover}
                  icon={
                    <BiSolidCalendar
                      size={24}
                      color={isIconHovered.NewEvent ? 'white' : '#738C89'}
                    />
                  }
                  itemName="NewEvent"
                  itemPath={PuffinRoutes.CreateEvent}
                  isActiveLink={() => isActiveLink(PuffinRoutes.CreateEvent)}
                  setActiveLink={() => {
                    setActiveLink(PuffinRoutes.CreateEvent);
                  }}
                />
              </div>
              <div className="flex-grow" />
              <div className="py-1" onClick={onClickSignout}>
                <HoverIcon
                  icon={<BsBoxArrowLeft className="text-2xl cursor-pointer" />}
                  text="Log out"
                  isError
                />
              </div>
            </div>
            <div className="flex-grow" />
          </div>
        </nav>
      )}
    </div>
  );
}

export default DashboardSidebar;
