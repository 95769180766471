import { Modal, Spinner } from '@bindystreet/bindystreet.kit.react';
import { AiOutlineClose } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import ImprovedButton from './ImprovedButton';

type Props = {
  isModalOpen: boolean;
  handleClickConfirmButton: () => void;
  closeModal: () => void;
  popupLabel: string;
  children: JSX.Element;
  confirmButtonText: string;
  isErrorButton: boolean;
  Icon?: IconType;
  isLoading?: boolean;
  height?: string;
  position?:
    | 'center'
    | 'topRight'
    | 'topLeft'
    | 'fixed'
    | 'fixedCenter'
    | 'flyout'
    | 'flyoutTop';
};

function ConfirmationPopup(props: Props) {
  const {
    isModalOpen,
    closeModal,
    handleClickConfirmButton,
    children,
    isErrorButton,
    confirmButtonText,
    popupLabel,
    isLoading,
    Icon,
    height = '220px',
    position = 'fixedCenter'
  } = props;

  const buttonOrSpinner = isLoading ? (
    <Spinner color="white" size="sm" />
  ) : (
    confirmButtonText
  );

  return (
    <Modal
      isMenuOpen={isModalOpen}
      position={position}
      size="xl"
      className="rounded-lg border border-outline"
      overlay={true}
      onCloseModal={closeModal}
      zIndex={100}
      styles={{ height: height }}
    >
      <div className="flex flex-col mx-2 my-4">
        <div className="flex mt-2 mb-4">
          <span className="text-onSurface font-inter text-xl font-bold leading-9 inline-block">
            {popupLabel}
          </span>
          <div className="flex flex-grow"></div>
          <AiOutlineClose
            size={24}
            className="text-secondaryText cursor-pointer"
            onClick={closeModal}
          />
        </div>
        {children}
        <div className="flex justify-end mt-6">
          <ImprovedButton
            className={`px-4 py-3 flex  w-24 flex-row items-center justify-center body-bold-l border border-onSurface text-onSurface bg-surface ${
              !!Icon ? 'mr-2' : 'mr-4'
            }`}
            onClick={closeModal}
          >
            Cancel
          </ImprovedButton>

          {!!Icon ? (
            <div className="px-4">
              <ImprovedButton
                className="px-4 py-3 flex justify-center items-center "
                onClick={handleClickConfirmButton}
              >
                <div className="font-nunito text-base font-bold text-white">
                  {buttonOrSpinner}
                </div>
                <Icon
                  size={18}
                  color="white"
                  name="close"
                  className="mt-0.5 ml-2"
                />
              </ImprovedButton>
            </div>
          ) : (
            <ImprovedButton
              className={`px-4 py-3 flex justify-center items-center font-nunito text-base font-bold text-white  ${
                isErrorButton ? 'bg-darkRed' : 'bg-primary'
              } `}
              onClick={handleClickConfirmButton}
            >
              {buttonOrSpinner}
            </ImprovedButton>
          )}
        </div>
      </div>
    </Modal>
  );
}
export default ConfirmationPopup;
