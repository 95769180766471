import IBase from '../IBase';
import { IUser } from '../identity';
import { IListing } from '../listing/IListing';

export interface IBaseListingRequest extends IBase {
  listing: IListing;
  user: IUser;
  status: ClaimRequestStatus;
  rejectionReason: string;
  clarification: string;
  isYourBusiness: boolean;
  hasAlreadyContacted: boolean;
  businessConnection: string;
}

export enum ClaimRequestStatus {
  None,
  Requested,
  Approved,
  Rejected
}

export enum ClaimStatusText {
  None,
  Claim,
  Owned,
  Pending,
  AlreadyClaimed
}
