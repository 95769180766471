import { linkUrlType } from 'Colugo/interfaces/games/blocks/IExternalLinkBlockItem';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { IListingSuggestion } from 'Colugo/interfaces/suggestions/IListingSuggestion';
import { ListingField } from 'Colugo/interfaces/suggestions/ListingField';
import { TbExternalLink } from 'react-icons/tb';
import { Link } from 'react-router-dom';

type Props = {
  suggestion: IListingSuggestion;
  listing: IListing;
  onManageAsync: (
    isAccepting: boolean,
    suggestionTitle: string
  ) => Promise<void>;
};

function SuggestionCard(props: Props) {
  const { suggestion, listing, onManageAsync } = props;
  function getSuggestionSocialLinkValue(type: linkUrlType) {
    return listing.social?.socialLinks?.find((sl) => sl.urlType === type)
      ?.value;
  }

  let suggestionTitle: string;
  let currentValue: string | undefined;
  switch (suggestion.fieldToUpdate) {
    case ListingField.ActionLink:
      suggestionTitle = 'Action Link';
      currentValue = listing.social?.actionLinkUrl;
      break;
    case ListingField.WebsiteLink:
      suggestionTitle = 'Website Link';
      currentValue = listing.social?.website;
      break;
    case ListingField.ContactNumber:
      suggestionTitle = 'Contact Number';
      currentValue = listing.contact;
      break;
    case ListingField.Instagram:
      suggestionTitle = 'Instagram';
      currentValue = getSuggestionSocialLinkValue(linkUrlType.Instagram);
      break;
    case ListingField.Facebook:
      suggestionTitle = 'Facebook';
      currentValue = getSuggestionSocialLinkValue(linkUrlType.Facebook);
      break;
    case ListingField.TikTok:
      suggestionTitle = 'TikTok';
      currentValue = getSuggestionSocialLinkValue(linkUrlType.TikTok);
      break;
    case ListingField.Twitter:
      suggestionTitle = 'Twitter';
      currentValue = getSuggestionSocialLinkValue(linkUrlType.Twitter);
      break;
    case ListingField.OpeningTimes:
      suggestionTitle = 'Opening Times';
      currentValue = listing.timeExplanation;
      break;
    default:
      suggestionTitle = 'Unknown field';
      break;
  }

  return (
    <div
      className={`my-2 font-nunito rounded-lg text-background border-black border-opacity-10 border`}
      style={{ width: 900 }}
      key={suggestion.id}
    >
      <div>
        <div className="text-lg flex flex-row">
          <span className="font-bold p-4">{suggestionTitle}</span>
          <div className="flex flex-grow" />
          <div className="flex flex-row my-3 text-base">
            <div
              className="px-5 pt-1.5 bg-primaryTeal cursor-pointer rounded-lg text-white"
              onClick={async () => await onManageAsync(true, suggestionTitle)}
            >
              Accept
            </div>
            <span
              className="pt-1.5 ml-3 mr-6 text-lightCharcoal cursor-pointer"
              onClick={async () => await onManageAsync(false, suggestionTitle)}
            >
              Reject
            </span>
          </div>
        </div>
        <div className="w-full flex-row flex font-inter text-primaryCharcoal">
          <div
            style={{ width: 452, height: 82 }}
            className="border-r border-t bg-theme4 border-black border-opacity-10 rounded-bl-lg p-4"
          >
            <div className="font-bold">Current</div>
            <div className="flex flex-row">
              {currentValue || 'Not provided'}
              {currentValue && (
                <Link to={currentValue} target="_blank">
                  <TbExternalLink
                    className="ml-2 mt-1 cursor-pointer"
                    size={18}
                  />
                </Link>
              )}
            </div>
          </div>
          <div
            style={{ width: 452 }}
            className="border-t bg-theme1 border-black border-opacity-10 rounded-b rounded-br-lg p-4"
          >
            <div className="font-bold">Suggestions</div>
            <div className="flex flex-row">
              {suggestion.updatedValue}{' '}
              <Link to={suggestion.updatedValue} target="_blank">
                <TbExternalLink
                  className="ml-2 mt-1 cursor-pointer"
                  size={18}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SuggestionCard;
