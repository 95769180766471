import {
  FlyoutMenu,
  FlyoutMenuItem,
  Input,
  InputFormEvent
} from '@bindystreet/bindystreet.kit.react';
import { EnumHelper } from 'Colugo/utility/helpers';
import EventIcon from 'images/searchFlyOutMenuIcons/eventIcon.svg';
import ListingIcon from 'images/searchFlyOutMenuIcons/listingIcon.svg';
import PromotionIcon from 'images/searchFlyOutMenuIcons/promotionIcon.svg';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AiFillPlusCircle, AiOutlineClose } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

export enum SearchedEntityType {
  Listing,
  Event,
  Promotion
}

type Props = {
  onSearchAsync: (query: string) => Promise<void>;
  setIsSearchMode: (isSearchMode: boolean) => void;
  onClearSearchQuery: () => void;
  activeSearchType: SearchedEntityType;
  onClickCreateButton?: () => void;
};

const searchedEntityTypeFlyoutMenuItems =
  EnumHelper.getEnumValuesForFlyout(SearchedEntityType);

function EntitySearch(props: Props) {
  const {
    setIsSearchMode,
    onSearchAsync,
    onClearSearchQuery,
    activeSearchType,
    onClickCreateButton
  } = props;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isCreateHovered, setIsCreateHovered] = useState(false);
  const [isFlyoutMenuOpen, setIsFlyoutMenuOpen] = useState(false);
  const navigate = useNavigate();

  const inputRef = useRef<HTMLInputElement>(null);

  function handleOnChange(e: InputFormEvent) {
    setSearchQuery(e.currentTarget.value);
  }

  function updateFlyoutMenuItems() {
    searchedEntityTypeFlyoutMenuItems.forEach((se) => {
      switch (se.label) {
        case SearchedEntityType[SearchedEntityType.Listing]:
          se.imageUrl = ListingIcon;
          break;
        case SearchedEntityType[SearchedEntityType.Event]:
          se.imageUrl = EventIcon;
          break;
        case SearchedEntityType[SearchedEntityType.Promotion]:
          se.imageUrl = PromotionIcon;
          break;
      }
    });

    return searchedEntityTypeFlyoutMenuItems;
  }

  const searchedEntityTypeFlyoutMenuItemsWithImages = updateFlyoutMenuItems();

  const onClearQuery = useCallback(async () => {
    if (searchQuery.length === 0) {
      onClearSearchQuery();
    }
  }, [searchQuery.length, onClearSearchQuery]);

  useEffect(() => {
    onClearQuery();
  }, [onClearQuery]);

  async function handleKeyPressAsync(event) {
    if (event.key === 'Enter' && !!searchQuery) {
      await onSearchAsync(searchQuery);
      setIsSearchMode(true);
    }
  }

  function handleFlyoutOnChange(e: FlyoutMenuItem) {
    navigate(`/?searchType=${e.value}`);
  }

  return (
    <div className="h-20" style={{ paddingRight: '179px' }}>
      <div className="flex flex-row w-auto items-center">
        <div className="relative flex flex-row w-full items-center">
          <div className="z-20 -mt-1 -mr-1" style={{ width: '196px' }}>
            <FlyoutMenu
              isEqual={isEqual}
              size="sm"
              activeKey={activeSearchType}
              withImage
              defaultPlaceholder="Search By"
              items={searchedEntityTypeFlyoutMenuItemsWithImages}
              isMenuOpen={isFlyoutMenuOpen}
              setIsMenuOpen={setIsFlyoutMenuOpen}
              onChange={handleFlyoutOnChange}
              isInSearchBar={true}
            />
          </div>
          <Input
            ref={inputRef}
            className="flex-grow "
            placeholder="Search Ember Business Hub"
            color="text-black"
            size="lg"
            onKeyDown={handleKeyPressAsync}
            value={searchQuery}
            onChange={handleOnChange}
          />
          {searchQuery.length > 0 && (
            <AiOutlineClose
              size={24}
              className="text-3xl text-secondaryText cursor-pointer hover:text-inactiveRed absolute right-4"
              onClick={() => {
                onClearSearchQuery();
                setSearchQuery('');
                inputRef?.current?.focus();
              }}
            />
          )}
        </div>
        <div
          onClick={async () => await onSearchAsync(searchQuery)}
          className="py-4 px-6 mb-1 -ml-1 z-10 flex flex-row items-center cursor-pointer bg-primary"
          style={{
            borderRadius: '0px 8px 8px 0px',
            borderTop: '1px solid rgba(0, 0, 0, 0.10)',
            borderRight: '1px solid rgba(0, 0, 0, 0.10)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.10)'
          }}
        >
          <BsSearch className="text-xl text-white mr-2" />
          <h6 className="text-white font-nunito text-base font-bold">Search</h6>
        </div>

        {onClickCreateButton && (
          <div
            onClick={onClickCreateButton}
            onMouseEnter={() => setIsCreateHovered(true)}
            onMouseLeave={() => setIsCreateHovered(false)}
            className={`py-4 px-5 mb-1 ml-4 flex flex-row items-center ${
              isCreateHovered ? 'bg-primary' : 'bg-white'
            } hover:text-white cursor-pointer rounded-lg border border-grayBorder`}
          >
            <AiFillPlusCircle
              size={24}
              className={`text-xl ${
                isCreateHovered ? 'text-white' : 'text-primary'
              } mr-2`}
            />
            <h6
              className={`font-nunito ${
                isCreateHovered ? 'text-white' : 'text-primary'
              } text-base font-bold`}
            >
              Create
            </h6>
          </div>
        )}
      </div>
    </div>
  );
}

export default EntitySearch;
