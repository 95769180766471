import { Button } from '@bindystreet/bindystreet.kit.react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

interface Props {
  title: string;
  isActionLoading?: boolean;
  onPressAction?: () => void;
  actionTitle?: string;
  onPressClose?: () => void;
  onPressComponent?: JSX.Element;
}

function CustomFormHeader({
  title,
  onPressAction,
  actionTitle,
  isActionLoading = false,
  onPressClose,
  onPressComponent
}: Props) {
  const navigate = useNavigate();

  return (
    <div>
      <div
        className="w-full bg-white flex justify-between items-center px-8"
        style={{
          height: '72px',
          borderBottomWidth: '1px',
          borderColor: 'outline'
        }}
      >
        <div
          className="flex flex-row cursor-pointer"
          onClick={() => (onPressClose ? onPressClose() : navigate(-1))}
        >
          <MdKeyboardArrowLeft size={26} />
          <span className="font-inter font-semibold text-lg">Return</span>
        </div>
        <h2 className="leading-44 heading-semibold-l text-primaryDark">
          {title}
        </h2>
        {onPressComponent ? (
          onPressComponent
        ) : onPressAction ? (
          <Button
            size="lg"
            type="submit"
            isLoading={isActionLoading}
            skin={'transparent'}
            onClick={onPressAction}
            className="flex flex-row justify-center font-nunito items-center w-auto px-6 py-3 mr-auto bg-primary cursor-pointer"
          >
            <label
              className="heading-semibold-av text-white cursor-pointer"
              style={{ lineHeight: '20px' }}
            >
              {actionTitle}
            </label>
          </Button>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}

export default CustomFormHeader;
