import {
  IIoiValidationError,
  ErrorType
} from 'Colugo/interfaces/IIoiValidationError';
import {
  CategoryKey,
  ICategoryUserView
} from 'Colugo/interfaces/common/ICategory';

import {
  ActiveAnimalsNaturePill,
  ActiveCulturePill,
  ActiveActivitiesPill,
  ActiveFoodDrinkPill,
  ActiveHobbiesPill,
  ActiveSportHealthPill,
  DefaultAnimalsNaturePill,
  DefaultCulturePill,
  DefaultActivitiesPill,
  DefaultFoodDrinkPill,
  DefaultHobbiesPill,
  DefaultSportHealthPill
} from 'images/categoryIcons';
import { ReactNode } from 'react';

type Props = {
  categories: ICategoryUserView[];
  handleAddCategoryAsync: (categoryId: string) => Promise<boolean>;
  handleRemoveCategoryAsync: (
    index: number,
    categoryId: string
  ) => Promise<void>;
  pillWidth: string;
  validationErrors: IIoiValidationError[];
};

function CategorySelector(props: Props) {
  const {
    categories,
    handleRemoveCategoryAsync,
    handleAddCategoryAsync,
    pillWidth,
    validationErrors
  } = props;

  const categoryValidationError = validationErrors.find(
    (e) => e.type === ErrorType.Category
  );

  const isSelectAvailable =
    categories.filter((c) => !!c.isGuideOrItemInCategory).length < 2;

  interface CategoryIconAndColour {
    image: ReactNode | null;
    activeColour: string;
    disabledColour: string;
  }
  async function handleOnClickCategoryAsync(
    category: ICategoryUserView,
    i: number
  ) {
    if (!isSelectAvailable && !category.isGuideOrItemInCategory) {
      return;
    }
    category.isGuideOrItemInCategory
      ? await handleRemoveCategoryAsync(i, category.id!)
      : await handleAddCategoryAsync(category.id!);
  }

  const getCategoryIconAndColour = (
    key?: string,
    isActive?: boolean
  ): CategoryIconAndColour => {
    let image: ReactNode | null = null;
    let activeColour: string = '';
    let disabledColour: string = '';

    switch (key) {
      case CategoryKey.AnimalsNature:
        image = isActive ? (
          <ActiveAnimalsNaturePill />
        ) : (
          <DefaultAnimalsNaturePill />
        );
        activeColour = '#27C382';
        disabledColour = '#D4F3E6';
        break;
      case CategoryKey.Culture:
        image = isActive ? <ActiveCulturePill /> : <DefaultCulturePill />;
        activeColour = '#774ECD';
        disabledColour = '#E4DCF5';
        break;
      case CategoryKey.Activities:
        image = isActive ? <ActiveActivitiesPill /> : <DefaultActivitiesPill />;
        activeColour = '#EC5A70';
        disabledColour = '#FBDEE2';
        break;
      case CategoryKey.FoodDrink:
        image = isActive ? <ActiveFoodDrinkPill /> : <DefaultFoodDrinkPill />;
        activeColour = '#F4AF45';
        disabledColour = '#FDEFDA';
        break;
      case CategoryKey.Hobbies:
        image = isActive ? <ActiveHobbiesPill /> : <DefaultHobbiesPill />;
        activeColour = '#C86BAD';
        disabledColour = '#F4E1EF';
        break;
      case CategoryKey.SportHealth:
        image = isActive ? (
          <ActiveSportHealthPill />
        ) : (
          <DefaultSportHealthPill />
        );
        activeColour = '#49AED6';
        disabledColour = '#DBEFF7';
        break;
    }

    return { image, activeColour, disabledColour };
  };

  const categoryElements = categories.map((category, i) => {
    const {
      image,
      activeColour: pillColour,
      disabledColour
    } = getCategoryIconAndColour(
      category.key,
      category.isGuideOrItemInCategory
    );
    return (
      <div
        className={`flex flex-row cursor-pointer rounded-lg mr-2 my-2 border border-rounded ${
          categoryValidationError ? ' border-error1' : ' border-grayBorder'
        } ${
          !isSelectAvailable &&
          !category.isGuideOrItemInCategory &&
          'opacity-60 cursor-not-allowed'
        }`}
        style={{
          width: pillWidth,
          backgroundColor: category.isGuideOrItemInCategory
            ? pillColour
            : 'white'
        }}
        key={category.key!}
        onClick={async () => await handleOnClickCategoryAsync(category, i)}
      >
        <div
          className={`flex flex-row items-start rounded-md justify-center px-3 py-3 relative`}
          style={{
            backgroundColor: category.isGuideOrItemInCategory
              ? pillColour
              : disabledColour,
            borderRadius: '8px 0px 0px 8px'
          }}
        >
          <div>{image}</div>
        </div>

        <div
          className="flex flex-row  items-center -ml-1 rounded-md"
          style={{
            backgroundColor: category.isGuideOrItemInCategory
              ? pillColour
              : 'white',
            width: '300px'
          }}
        >
          <div
            className={`font-nunito ml-2 text-sm font-bold  ${
              category.isGuideOrItemInCategory
                ? 'text-white'
                : 'text-secondaryText'
            }`}
          >
            {category.name}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="flex flex-col">
      <div className="flex flex-row flex-wrap">{categoryElements}</div>
      <div>
        {categoryValidationError && (
          <div>
            <span className="text-error1 italic flex mr-auto -mb-4">
              {categoryValidationError.tabMessage}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default CategorySelector;
