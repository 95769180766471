import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { ITag } from 'Colugo/interfaces/tags';
import { useReqListTags } from 'Colugo/operations/tags';
import React from 'react';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export type TagsContextType = {
  tags: ITag[] | undefined;
};

export const TagsContext = React.createContext<TagsContextType>({
  tags: undefined
});

export function populateTags(tags: ITag[], allTags: ITag[]) {
  if (!allTags) {
    return [];
  }

  const populatedTags = tags.map((promotionTag) => {
    const currentTag = allTags.find((tag) => tag.id === promotionTag.id);
    return currentTag ? currentTag : { id: promotionTag.id };
  });

  return populatedTags;
}

function TagsProvider(props: Props) {
  const { children } = props;

  const {
    data: tags,
    isLoading: isTagsLoading,
    isError: isTagsError
  } = useReqListTags();

  if (isTagsError) {
    return (
      <ErrorPage>
        <span>{`Error retrieving tags.`}</span>
      </ErrorPage>
    );
  }

  if (isTagsLoading) {
    return (
      <div className="absolute w-full h-full">
        <Spinner expand />
      </div>
    );
  }

  return (
    <TagsContext.Provider
      value={{
        tags
      }}
    >
      {children}
    </TagsContext.Provider>
  );
}

export default TagsProvider;
