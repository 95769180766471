// This file wraps a WebWorker for uploading images for the CSV upload

import FileUploadOperations from 'Colugo/operations/file/FileUploadOperations';
import { container } from 'tsyringe';
import { resizeImage } from './imageHandling';

const fileUploadOperations = container.resolve(FileUploadOperations);

export type Resizing = {
  isCrop: boolean;
  resolution: { width: number; height: number };
};

const uploadLocalImage = async (imageUrl: string, resizing: Resizing) => {
  const resizedFile = await resizeImage(imageUrl, resizing);
  if (!resizedFile) {
    return undefined;
  }
  const { data, error } = await fileUploadOperations.uploadImageAsync(
    resizedFile
  );
  if (error) {
    console.error('Error from req: ' + error);
    return undefined;
  }
  return data as string;
};

const validateAndUploadLocalImage = async (
  rawData: string | undefined,
  resizing: Resizing
) => {
  if (!rawData) {
    console.error('Empty or undefined path given');
    return undefined;
  }
  const match = urlParseRegex.exec(rawData);
  if (!!match) {
    const parsedData = match[1];
    return await uploadLocalImage(parsedData, resizing);
  }
  return undefined;
};

export const validateImageUrl = (rawData: string) => {
  const match = urlParseRegex.exec(rawData);
  return !!match && !!match[1];
};

export const urlParseRegex = /\((http.*?)\)/;

export const uploadImage = async (
  url: string | undefined,
  resizing: Resizing
) => {
  return await validateAndUploadLocalImage(url, resizing);
};
