import { BusinessSidebarNames } from 'components/business/dashboard/BusinessSidebar';
import { ReactComponent as LogoutIcon } from 'images/dashboard/logoutIcon.svg';
import { ReactComponent as BusinessDetailsTooltip } from 'images/sidebar/tooltips/businessDetails.svg';
import { ReactComponent as EventsToolTip } from 'images/sidebar/tooltips/events.svg';
import { ReactComponent as HomeTooltip } from 'images/sidebar/tooltips/home.svg';
import { ReactComponent as LogoutTooltip } from 'images/sidebar/tooltips/logout.svg';
import { ReactComponent as ManageBusinessTooltip } from 'images/sidebar/tooltips/manageBusiness.svg';
import { ManagerContext } from 'provider/manager/managerProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HoveredTooltip from './HoveredTooltip';
import ImprovedTooltip from './ImprovedTooltip';

type Props = {
  onClickSignout: (e: React.MouseEvent) => void;
  sideBarItems: ISidebarItem[];
  onClickNestedRoute?: (sideBarItemName: string) => void;
  activeNestedSidebarNames?: string[] | undefined;
  setActiveNestedSidebarItemName?: (
    activeNestedSidebarNames: string | undefined
  ) => void;
  shouldDisplay?: boolean;
};

export interface ISidebarItem {
  name: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  path?: string;
}

function Sidebar(props: Props) {
  const {
    onClickSignout,
    sideBarItems,
    onClickNestedRoute,
    setActiveNestedSidebarItemName,
    shouldDisplay = true
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(
    undefined
  );
  const [isLogoutHovered, setIsLogoutHovered] = useState<boolean | undefined>(
    false
  );
  const [tooltipStep, setTooltipStep] = useState<number>(1);
  const { isSuperAdmin } = useContext(UserContext);
  const {
    setHasViewedTooltips,
    hasViewedTooltips,
    selectedOwnedId,
    setSelectedOwnedId,
    mutateOwnsClaimsRequests
  } = useContext(ManagerContext);

  const getActivePathClasses = (path: string | undefined) => {
    return location.pathname.split('/')[1] === path?.split('/')[1]
      ? 'bg-primary text-white'
      : 'text-onSurfaceVariant';
  };

  //TODO: Fix this later to be just css that draws out the tooltip shape rather than importing svgs for each sidebar item
  function getTooltip(sideBarItemName: string) {
    switch (sideBarItemName) {
      case BusinessSidebarNames.Home:
        return <HomeTooltip />;
      case BusinessSidebarNames.BusinessDetails:
        return <BusinessDetailsTooltip />;
      case BusinessSidebarNames.Events:
        return <EventsToolTip />;
      case BusinessSidebarNames.ManageBusiness:
        return <ManageBusinessTooltip />;
    }
    return <></>;
  }

  function onClickSidebarItem(sideBarItem: ISidebarItem) {
    if (sideBarItem.path) {
      setActiveNestedSidebarItemName &&
        setActiveNestedSidebarItemName(undefined);
      navigate(sideBarItem.path);
    } else if (onClickNestedRoute) {
      onClickNestedRoute(sideBarItem.name);
    }
  }

  let tooltipTitle: string;
  let tooltipSubtitle: string;
  let toolTipPadding: string;

  switch (tooltipStep) {
    case 1:
      tooltipTitle = 'Welcome to your ember business homepage!';
      tooltipSubtitle =
        "This is your dashboard, where you'll be able to access and view your business details, events, or promotions.";
      toolTipPadding = '107px';
      break;
    case 2:
      tooltipTitle = 'This is where you can edit your business details';
      tooltipSubtitle =
        "Within this tab, you'll be able to edit all your business details, events, and promotions.";
      toolTipPadding = '160px';
      break;
    case 3:
      tooltipTitle = 'This is where you can manage all of your events';
      tooltipSubtitle =
        "Within this tab, you'll be able to create and edit any events for your business.";
      toolTipPadding = '213px';
      break;
    default:
      tooltipTitle = 'This is where you can add or edit your businesses';
      tooltipSubtitle =
        'If you need to make any additional claims or requests, you can do so on this page.';
      toolTipPadding = '266px';
      break;
  }

  const nextButton = (
    <div
      className="w-full bg-primary rounded-lg text-white flex flex-row cursor-pointer mt-2"
      onClick={() => {
        if (tooltipStep > 3) {
          setHasViewedTooltips(true);
          navigate('/');
          return;
        }
        setTooltipStep(tooltipStep + 1);
        onClickSidebarItem(sideBarItems[tooltipStep]);
      }}
    >
      <span className="mx-auto font-nunito font-bold text-sm my-2">Next</span>
    </div>
  );

  const isSidebarEnabed = hasViewedTooltips || isSuperAdmin;

  return (
    <div
      className={`bg-surface border-r border-outline z-30 left-0 w-full h-screen sticky top-0 ${
        isSidebarEnabed ? 'cursor-pointer' : 'cursor-not-allowed'
      }`}
      style={{ width: '72px' }}
    >
      {!isSidebarEnabed && tooltipStep < 5 && selectedOwnedId && (
        <div
          className={
            'h-screen w-screen absolute top-0 z-50 left-20 cursor-not-allowed'
          }
          style={{ paddingTop: toolTipPadding }}
        >
          <ImprovedTooltip
            header={`${tooltipStep} / 4`}
            title={tooltipTitle}
            subtitle={tooltipSubtitle}
            isSuperAdmin={isSuperAdmin}
            bottomComponent={nextButton}
          />
        </div>
      )}
      <div
        className="flex flex-col h-screen items-center gap-3"
        style={{ marginTop: '108px' }}
      >
        {shouldDisplay &&
          sideBarItems.map((sideBarItem, i) => {
            const Icon = sideBarItem.Icon;
            return (
              <div
                key={i}
                style={{ width: '40px', height: '40px' }}
                className={`${getActivePathClasses(
                  sideBarItem.path ? sideBarItem.path : sideBarItem.name
                )} ${
                  isSidebarEnabed
                    ? 'cursor-pointer hover:bg-primaryContainer hover:text-black'
                    : 'cursor-not-allowed'
                } flex justify-center items-center rounded-lg`}
                onClick={() =>
                  isSidebarEnabed && onClickSidebarItem(sideBarItem)
                }
                onMouseEnter={() => setHoveredIndex(i)}
                onMouseLeave={() => setHoveredIndex(undefined)}
              >
                {Icon && <Icon />}
              </div>
            );
          })}
      </div>
      <div
        className="absolute gap-3 flex flex-col"
        style={{ left: '70px', top: '108px' }}
      >
        {sideBarItems.map((sidebarItem, index) => (
          <HoveredTooltip
            key={index}
            index={index}
            hoveredIndex={isSidebarEnabed ? hoveredIndex : undefined}
            tooltip={getTooltip(sidebarItem.name)}
          />
        ))}
      </div>
      <div
        className="absolute bottom-8 flex justify-center items-center w-full"
        onClick={(e) => {
          setSelectedOwnedId('');
          mutateOwnsClaimsRequests({ owns: [], claims: [], requests: [] });
          onClickSignout(e);
        }}
      >
        <div
          style={{ width: '40px', height: '40px' }}
          className="hover:bg-primaryContainer hover:text-black flex justify-center items-center cursor-pointer rounded-lg"
          onMouseEnter={() => setIsLogoutHovered(true)}
          onMouseLeave={() => setIsLogoutHovered(false)}
        >
          <LogoutIcon />
        </div>
      </div>
      <div
        className="absolute gap-3 flex flex-col bottom-8"
        style={{ left: '70px' }}
      >
        <HoveredTooltip
          itemHovered={isLogoutHovered}
          tooltip={<LogoutTooltip />}
        />
      </div>
    </div>
  );
}

export default Sidebar;
