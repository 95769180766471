import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IIoiValidationError } from 'Colugo/interfaces/IIoiValidationError';
import { IEvent } from 'Colugo/interfaces/event/IEvent';
import EventOperations from 'Colugo/operations/events/EventOperations';
import { useReqListTagsByFilterGroup } from 'Colugo/operations/tags';
import TagSelector from 'components/listings/tagging/TagSelector';
import { FilterGroupContext } from 'provider/filterGroups/filterGroupsProvider';
import { TagsContext } from 'provider/tags/tagsProvider';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';

const eventOperations = container.resolve(EventOperations);

type Props = {
  localEvent: IEvent;
  setLocalEvent: (localEvent: IEvent) => void;
  validationErrors: IIoiValidationError[];
  updateIsActiveForInvalidEventAsync: () => Promise<boolean>;
  mutate: (event: IEvent, revalidate?: boolean) => void;
};

function TaggingEventContainer(props: Props) {
  const {
    localEvent,
    setLocalEvent,
    validationErrors,
    updateIsActiveForInvalidEventAsync,
    mutate
  } = props;

  const { errorToast } = useErrorToast();

  const { tags: allTags } = useContext(TagsContext);
  const { promotionsFilterGroup } = useContext(FilterGroupContext);

  const {
    data: promotionTags,
    isLoading: isPromotionTagsLoading,
    isError: isPromotionTagsError
  } = useReqListTagsByFilterGroup(promotionsFilterGroup?.id || '');

  const nonPromotionTags = allTags?.filter((tag) => {
    return !promotionTags?.some((promotionTag) => promotionTag.id === tag.id);
  });

  async function onClickTagUpdatePriorityAsync(
    tagId: string,
    isPriority: boolean
  ) {
    if (
      localEvent.tags?.filter((item) => item.isPriority).length! >= 3 &&
      isPriority
    ) {
      errorToast(`Event already has the maximum number of priority tags.`);
      return;
    }
    const updatedTags = localEvent.tags
      ?.map((tag) =>
        tag.id === tagId ? { ...tag, isPriority: isPriority } : tag
      )
      .sort((a) => (a.isPriority ? -1 : 1));

    const updatedEvent = {
      ...localEvent,
      tags: updatedTags
    };
    setLocalEvent(updatedEvent);
    const { error } = await eventOperations.updateTagPriorityAsync(
      localEvent.id!,
      tagId,
      isPriority
    );
    if (error) {
      errorToast('Failed to change the priority of tag, try again later');
      setLocalEvent(localEvent);
      return;
    }
    mutate(updatedEvent);
  }

  async function handleAddTagAsync(tagId: string) {
    if (!nonPromotionTags || nonPromotionTags.length === 0) {
      toast.warn('No tags found.');
      return;
    }
    const tagToAdd = nonPromotionTags.find((t) => t.id === tagId);
    if (!tagToAdd) {
      toast.warn('No tag selected');
      return;
    }
    const updatedEvent = {
      ...localEvent,
      tags: [...(localEvent?.tags || []), tagToAdd]
    };
    setLocalEvent(updatedEvent);
    const { error } = await eventOperations.addTagAsync(localEvent.id!, tagId);
    if (error) {
      errorToast('Failed to add tag, try again later');
      setLocalEvent(localEvent);
      return;
    }
    mutate(updatedEvent);
  }

  async function handleRemoveTagAsync(tagId: string) {
    if (!localEvent.id) {
      errorToast('No event selected, Please try again');
      return;
    }
    const newTags = localEvent.tags!.filter((t) => t.id !== tagId);
    let updatedEvent = {
      ...localEvent,
      tags: newTags
    };
    setLocalEvent(updatedEvent);
    const { error } = await eventOperations.removeTagAsync(
      localEvent.id,
      tagId
    );
    if (error) {
      errorToast('Failed to remove tag, try again later');
      setLocalEvent(localEvent);
      return;
    }
    if (newTags.length === 0) {
      updatedEvent = {
        ...localEvent,
        isActive: false,
        tags: newTags
      };
      setLocalEvent(updatedEvent);
      await updateIsActiveForInvalidEventAsync();
    }
    mutate(updatedEvent);
  }

  if (isPromotionTagsLoading) {
    return <Spinner />;
  }

  if (isPromotionTagsError) {
    return (
      <ErrorPage>
        <span>{`Error retrieving tags.`}</span>
      </ErrorPage>
    );
  }

  return (
    <div className="flex flex-col rounded-lg px-6 py-8 w-full ">
      <span className="text-inter text-2xl font-bold leading-9 text-left">
        Tags
      </span>
      <div className="flex flex-row mt-6">
        <TagSelector
          tags={nonPromotionTags || []}
          onClickTagDeleteIconAsync={handleRemoveTagAsync}
          onConfirmNewTagAsync={handleAddTagAsync}
          entityTags={localEvent.tags || []}
          validationErrors={validationErrors}
          onClickTagUpdatePriorityAsync={onClickTagUpdatePriorityAsync}
        />
        <div className="border-r-2 border-gray-300 mr-6"></div>
        <span
          className="text-primary-charcoal font-inter text-base font-normal"
          style={{ width: 295 }}
        >
          Tags help our users discover your event on the app. Please add between
          5 - 10 tags that you see as relevant.
        </span>
      </div>
    </div>
  );
}

export default TaggingEventContainer;
