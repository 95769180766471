type Props = {
  index?: number | undefined;
  hoveredIndex?: number | undefined;
  tooltip: JSX.Element;
  itemHovered?: boolean;
};

function HoveredTooltip(props: Props) {
  const { index, hoveredIndex, tooltip, itemHovered } = props;

  const isHovered =
    hoveredIndex !== undefined && index !== undefined
      ? hoveredIndex === index
      : itemHovered;

  return (
    <div
      key={index}
      className={`relative ${
        isHovered
          ? 'opacity-100 translate-x-0 w-full'
          : 'opacity-0 -translate-x-12 w-0'
      }  transition-all duration-300 ease-in-out cursor-pointer`}
      style={{ pointerEvents: 'none' }}
    >
      {tooltip}
    </div>
  );
}

export default HoveredTooltip;
