import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';
import { EventStatus, IEvent } from 'Colugo/interfaces/event/IEvent';
import { requestResponse } from 'Colugo/provider/HttpClient';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';
import { ICreateEventsForCsvUpload } from 'Colugo/interfaces/event/ICreateEventsForCsvUpload';

@scoped(Lifecycle.ContainerScoped)
class EventOperations extends AbstractOperations {
  protected basePath: string = 'event';

  public async createAsync(event: IEvent): requestResponse<IEvent> {
    return await this.executePost(``, event);
  }

  public async createForCsvUploadAsync(events: IEvent[]): requestResponse {
    const createEventsForCsvUpload: ICreateEventsForCsvUpload = {
      events: events
    };
    return await this.executePost(`csvUpload`, createEventsForCsvUpload);
  }

  public async updateAsync(event: IEvent): requestResponse {
    return await this.executePut(`${event.id}`, event);
  }

  public async checkNameForLocationAsync(
    event: IEvent
  ): requestResponse<boolean> {
    return await this.executePost(`checkNameForLocation`, event);
  }

  public async searchEventsAsync(query?: string): requestResponse<IEvent[]> {
    return await this.executeGet(`search?query=${query}`);
  }

  public async deleteAsync(eventId: string): requestResponse {
    return await this.executeDelete(`${eventId}`);
  }

  public async addTagAsync(eventId: string, tagId: string): requestResponse {
    return await this.executePost(`${eventId}/tag/${tagId}`);
  }

  public async inactivateAsync(eventId: string): requestResponse {
    return await this.executeDelete(`inactivate/${eventId}`);
  }

  public async removeTagAsync(eventId: string, tagId: string): requestResponse {
    return await this.executeDelete(`${eventId}/tag/${tagId}`);
  }

  public async updateTagPriorityAsync(
    eventId: string,
    tagId: string,
    isPriority: boolean
  ): requestResponse {
    return await this.executePut(
      `${eventId}/tag/${tagId}?isPriority=${isPriority}`
    );
  }

  public async addCategoryAsync(
    eventId: string,
    categoryId: string
  ): requestResponse {
    return await this.executePost(`${eventId}/category/${categoryId}`);
  }

  public async removeCategoryAsync(
    eventId: string,
    categoryId: string
  ): requestResponse {
    return await this.executeDelete(`${eventId}/category/${categoryId}`);
  }
}

const useReqListEventsAsync = (
  eventStatus: EventStatus = EventStatus.All
): swrRequestResponse<IEvent[]> => {
  return useGetHttpClient(
    `event/recentlyUpdated?eventStatus=${eventStatus}`,
    {
      revalidateOnFocus: true
    },
    false
  );
};

const useReqGetEventAsync = (eventId?: string): swrRequestResponse<IEvent> => {
  return useGetHttpClient(
    `event/${eventId}?isActive=false`,
    {
      revalidateOnFocus: true
    },
    false
  );
};

export default EventOperations;
export { useReqListEventsAsync, useReqGetEventAsync };
