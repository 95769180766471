import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { IBusinessOwnsClaimsRequests } from 'Colugo/interfaces/business/IBusinessOwnsClaimsRequests';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { useReqOwnsClaimsRequestsAsync } from 'Colugo/operations/claims/ClaimOperations';
import { useReqGetListingAsync } from 'Colugo/operations/listings/ListingOperations';
import authMethods from 'provider/auth/authMethods';
import { AuthContext } from 'provider/auth/authProvider';
import { createContext, useContext, useEffect, useState } from 'react';
import { MutatorCallback } from 'swr/dist/types';
import localStore from 'utility/general/localStore';

type Props = {
  children: React.ReactNode;
};

type ManagersContextType = {
  businessOwnsClaimsRequests: IBusinessOwnsClaimsRequests;
  mutateOwnsClaimsRequests: (
    data?:
      | IBusinessOwnsClaimsRequests
      | Promise<IBusinessOwnsClaimsRequests>
      | MutatorCallback<IBusinessOwnsClaimsRequests>
      | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<IBusinessOwnsClaimsRequests | undefined>;
  mutateListing: (listing: IListing) => void;
  mutateOwnedListing: (listing: IListing) => void;
  isLoading: boolean | undefined;
  isListingLoading: boolean | undefined;
  isError: boolean | undefined;
  listing: IListing | undefined;
  setSelectedOwnedId: (ownedId: string) => void;
  selectedOwnedId: string;
  setHasActivatedBusiness: (hasActivated: boolean) => void;
  hasActivatedBusiness: boolean;
  setHasActivatedEvent: (eventId: string) => void;
  hasActivatedEvent: boolean;
  setHasViewedTooltips: (hasViewed: boolean) => void;
  hasViewedTooltips: boolean;
  hasSearchedForBusiness: boolean;
  setHasSearchedForBusiness: (hasSearchedForBusiness: boolean) => void;
};

export const ManagerContext = createContext<ManagersContextType>({
  businessOwnsClaimsRequests: { owns: [], claims: [], requests: [] },
  mutateOwnsClaimsRequests: async (
    data?: any,
    shouldRevalidate?: boolean | undefined
  ) => undefined,
  mutateOwnedListing: () => undefined,
  mutateListing: () => undefined,
  isLoading: false,
  isListingLoading: false,
  isError: false,
  listing: undefined,
  selectedOwnedId: '',
  setSelectedOwnedId: () => undefined,
  setHasActivatedBusiness: () => undefined,
  hasActivatedBusiness: false,
  setHasActivatedEvent: () => undefined,
  hasActivatedEvent: false,
  setHasViewedTooltips: () => undefined,
  hasViewedTooltips: false,
  setHasSearchedForBusiness: () => undefined,
  hasSearchedForBusiness: false
});

function ManagersProvider(props: Props) {
  const { children } = props;
  const selectedIdKey = 'selectedOwnedId';
  const activatedBusinessIdsKey = 'activatedBusinessIds';
  const activatedEventIdsKey = 'activatedEventIds';
  const hasViewedTooltipsKey = 'hasViewedTooltips';
  const hasSearchedForBusinessKey = 'hasSearchedForBusiness';

  const { setToken, setErrors } = useContext(AuthContext);

  const [selectedOwnedId, setSelectedOwnedIdLocal] = useState<string>(
    localStore.getItem(selectedIdKey) || ''
  );
  const [hasViewedTooltips, setHasViewedTooltipsLocal] = useState<boolean>(
    localStore.getItem(hasViewedTooltipsKey) === 'true'
  );
  const [hasSearchedForBusiness, setHasSearchedForBusinessLocal] =
    useState<boolean>(localStore.getItem(hasSearchedForBusinessKey) === 'true');
  const [hasActivatedBusiness, setHasActivatedBusinessLocal] =
    useState<boolean>(false);
  const [hasActivatedEvent, setHasActivatedEventLocal] =
    useState<boolean>(false);

  const {
    data: businessOwnsClaimsRequests,
    isLoading,
    isError,
    mutate: mutateOwnsClaimsRequests
  } = useReqOwnsClaimsRequestsAsync();

  const {
    data: listing,
    mutate: mutateListing,
    isLoading: isListingLoading
  } = useReqGetListingAsync(selectedOwnedId);

  useEffect(() => {
    if (listing) {
      const activatedBusinesses = JSON.parse(
        localStore.getItem(activatedBusinessIdsKey) || '[]'
      ) as string[];
      if (activatedBusinesses.some((ab) => ab === listing.id)) {
        setHasActivatedBusinessLocal(true);
      }
    }
  }, [listing]);

  function setSelectedOwnedId(id: string) {
    setSelectedOwnedIdLocal(id);
    localStore.setItem(selectedIdKey, id);
    setHasActivatedBusinessLocal(false);
    setHasActivatedEventLocal(false);
  }

  function setHasViewedTooltips() {
    setHasViewedTooltipsLocal(true);
    localStore.setItem(hasViewedTooltipsKey, true);
  }

  function setHasSearchedForBusiness() {
    setHasSearchedForBusinessLocal(true);
    localStore.setItem(hasSearchedForBusinessKey, true);
  }

  function setHasActivatedBusiness() {
    setHasActivatedBusinessLocal(true);
    const activatedBusinesses = JSON.parse(
      localStore.getItem(activatedBusinessIdsKey) || '[]'
    ) as string[];
    localStore.setItem(activatedBusinessIdsKey, [
      ...activatedBusinesses,
      listing?.id
    ]);
  }

  function setHasActivatedEvent(eventId: string) {
    setHasActivatedEventLocal(true);
    const activatedEvents = JSON.parse(
      localStore.getItem(activatedEventIdsKey) || '[]'
    ) as string[];
    localStore.setItem(activatedEventIdsKey, [...activatedEvents, eventId]);
  }

  function mutateOwnedListing(listing: IListing) {
    if (!businessOwnsClaimsRequests) {
      return;
    }
    const updatedBusinessOwnsClaimsRequest = {
      ...businessOwnsClaimsRequests,
      owns: [
        ...businessOwnsClaimsRequests.owns.filter((a) => a.id !== listing?.id)
      ]
    };

    mutateOwnsClaimsRequests({
      ...updatedBusinessOwnsClaimsRequest,
      owns: [...businessOwnsClaimsRequests.owns, listing]
    });
  }

  if (isLoading) {
    return (
      <div className="absolute w-full h-screen flex flex-col items-center justify-center">
        <Spinner />;
      </div>
    );
  }

  if (isError || !businessOwnsClaimsRequests) {
    authMethods.signOutAsync(setErrors, setToken);
    return <div />;
  }

  return (
    <ManagerContext.Provider
      value={{
        businessOwnsClaimsRequests,
        mutateOwnsClaimsRequests,
        mutateListing,
        mutateOwnedListing,
        isError,
        isLoading,
        listing,
        setSelectedOwnedId,
        selectedOwnedId,
        setHasActivatedBusiness,
        hasActivatedBusiness,
        setHasActivatedEvent,
        hasActivatedEvent,
        setHasViewedTooltips,
        hasViewedTooltips,
        setHasSearchedForBusiness,
        hasSearchedForBusiness,
        isListingLoading: selectedOwnedId ? isListingLoading : false
      }}
    >
      {children}
    </ManagerContext.Provider>
  );
}

export default ManagersProvider;
