export type ImprovedTooltipProps = {
  header?: string;
  title: string;
  subtitle?: string;
  isSuperAdmin: boolean;
  bottomComponent?: JSX.Element;
};

const ImprovedTooltip = (props: ImprovedTooltipProps): JSX.Element => {
  const { header, title, subtitle, isSuperAdmin, bottomComponent } = props;

  if (isSuperAdmin) {
    return <div />;
  }

  return (
    <div
      className="flex flex-col border border-outline rounded-xl p-4 bg-white cursor-default"
      style={{
        width: '332px',
        boxShadow:
          '0 4px 6px rgba(0, 0, 0, 0.03), 0 -4px 6px rgba(0, 0, 0, 0.03), 0 10px 15px rgba(0, 0, 0, 0.03), 0 -10px 15px rgba(0, 0, 0, 0.03)' // Reduced opacity for lighter shadow
      }}
    >
      <span className="font-inter font-extrabold text-primary text-sm">
        {header || 'Getting Started'}
      </span>
      <span className="text-xl font-bold text-onSurface mt-0.5">{title}</span>
      {subtitle && (
        <span className="text-onSurfaceVariant mt-0.5">{subtitle}</span>
      )}
      {bottomComponent}
    </div>
  );
};

export default ImprovedTooltip;
