import { useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

function ErrorBoundary() {
  let error = useRouteError();
  const navigate = useNavigate();
  useEffect(() => {
    if (error) {
      navigate('/');
    }
  }, [error, navigate]);

  //TODO: update this when we have the 404 design
  return (
    <div className="absolute w-full h-screen flex flex-col items-center justify-center">
      Hmm, Couldn't find this route
    </div>
  );
}

export default ErrorBoundary;
