import IBase from '../IBase';
import { ICategory, ILocation, ITag } from '../games';
import { IListing } from '../listing/IListing';
import { IAddress } from '../listing/IAddress';
import { IIoiTime } from '../common/IIoiTime';
import { IIoiSocial } from '../common/IIoiSocial';
import { IPromotion } from '../promotions/IPromotion';

export enum ActionLinkType {
  None = 0,
  BookNow = 1,
  BookTickets = 2,
  ViewMenu = 3,
  LearnMore = 4,
  Contact = 5,
  EnterGiveaway = 6,
  WhatsOn = 7
}

export interface IEvent extends IBase {
  name: string;
  address?: IAddress;
  isActive?: boolean;
  description?: string;
  location?: ILocation;
  images?: string[];
  categories?: ICategory[];
  tags?: ITag[];
  type?: EventType;
  promotions?: IPromotion[];
  startDate?: string;
  endDate?: string;
  listing?: IListing;
  time?: IIoiTime;
  social?: IIoiSocial;
  order?: number;
  contact?: string;
  emailAddress?: string;
  inheritAddress?: boolean;
  popularScore?: number;
  trendingScore?: number;
}

export interface ITimesWrapper {
  times: ITimes[];
}

interface ITimes {
  openingTime?: string;
  closingTime?: string;
}

export enum EventType {
  None = 0,
  SingleDate = 1,
  DateRange = 2
}

export enum EventStatus {
  All,
  Active,
  Inactive,
  Expired
}
