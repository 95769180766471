import { useReqListBusinessClaimsAsync } from 'Colugo/operations/claims/ClaimOperations';
import ListingClaimsOrRequests, {
  ManageAccountSection
} from './ListingClaimsOrRequests';

function BusinessClaims() {
  return (
    <ListingClaimsOrRequests
      pageName="Business Claims"
      useListingRequestsAsync={useReqListBusinessClaimsAsync}
      accountSection={ManageAccountSection.BusinessClaims}
    />
  );
}

export default BusinessClaims;
