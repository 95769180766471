import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import { ISidebarItem } from 'components/shared/Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';

enum ManageAccountSidebarNames {
  BusinessClaims = 'Business Claims',
  BusinessRequests = 'Business Requests'
}

function ManageAccountSidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const sidebarItems: ISidebarItem[] = [
    {
      name: ManageAccountSidebarNames.BusinessClaims,
      path: `${PuffinRoutes.ManageAccounts}/${PuffinRoutes.BusinessClaims.slice(
        0,
        -1
      )}${PuffinRoutes.NewBusinessClaimOrRequests}`
    },
    {
      name: ManageAccountSidebarNames.BusinessRequests,
      path: `${
        PuffinRoutes.ManageAccounts
      }/${PuffinRoutes.BusinessRequests.slice(0, -1)}${
        PuffinRoutes.NewBusinessClaimOrRequests
      }`
    }
  ];

  const getActivePathClasses = (path: string | undefined) => {
    // Since this is a nested route, this basically compares the base and the nested path
    // if it matches what is in the current location path by splitting the path strings into arrays
    // and taking sections (i.e the paths at index 1 to 3) from them to compare

    const currentPathSegment = location.pathname
      .split('/')
      .slice(1, 3)
      .join('/');
    const targetPathSegment = path?.split('/').slice(1, 3).join('/');
    const isMatch = currentPathSegment === targetPathSegment;

    return isMatch
      ? 'bg-primaryContainer text-primary'
      : 'bg-white text-onSurfaceVariant';
  };

  return (
    <div className="w-56 border-r border-outline bg-white h-full flex-col flex">
      <div className="fixed">
        <div
          className="pt-8 flex justify-start px-4 heading-semibold-m-onSurface"
          style={{ marginTop: '72px' }}
        >
          <div>Manage Accounts</div>
        </div>
        <div
          className="flex flex-col items-center px-4"
          style={{ marginTop: '10px', gap: '10px' }}
        >
          {sidebarItems.map((sidebarItem, i) => (
            <div
              key={i}
              className={`cursor-pointer ${getActivePathClasses(
                sidebarItem.path
              )} w-full justify-start items-center flex rounded-lg`}
              style={{ padding: '10px 12px' }}
              onClick={() => {
                navigate(`${sidebarItem.path}`);
              }}
            >
              <div className="font-inter font-semibold text-sm">
                {sidebarItem.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ManageAccountSidebar;
