import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { useState } from 'react';

type ButtonSkin =
  | 'primary'
  | 'unique'
  | 'secondary'
  | 'transparent'
  | 'lowRank'
  | 'error';

type Props = {
  id?: string;
  name?: string;
  type?: 'submit' | 'reset' | 'button';
  //The UI of the button based on design system (see for more details).
  skin?: ButtonSkin;
  disabled?: boolean;
  //Additional classes that get added to the base button component.
  className?: string;
  //Height of the button. Width is uncontrolled.
  size?: string;
  isLoading?: boolean;
  onClick?: () => void;
  children?: React.ReactNode | object | string;
  expand?: boolean;
  iconButton?: boolean;
};

export type ButtonProps = Props;

const ImprovedButton = (props: Props) => {
  const {
    id,
    name,
    type = 'button',
    disabled: isDisabled,
    onClick,
    children,
    size = 'md',
    isLoading = false,
    skin = 'primary',
    className = '',
    expand = false,
    iconButton = false
  } = props;

  const sizeList: { [key: string]: string } = {
    sm: 'h-8',
    md: 'h-10',
    lg: 'h-12'
  };

  const iconButtonSizeList: { [key: string]: string } = {
    sm: 'w-7 h-7',
    md: 'w-8 h-8',
    lg: 'w-10 h-10'
  };

  const [isHover, setIsHover] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const handleSetState = (args: { hover?: boolean; pressed?: boolean }) => {
    const { hover, pressed } = args;
    if (isDisabled) {
      setIsHover(false);
      setIsPressed(false);
      return;
    }

    if (hover !== undefined) {
      setIsHover(hover);
    }
    if (pressed !== undefined) {
      setIsPressed(pressed);
    }
  };

  const handleOnClick = () => {
    if (isDisabled) {
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  const state = isHover ? (isPressed ? 'Pressed' : 'Hover') : '';

  const getBackgroundColour = () => {
    if ((state === '' && skin === 'secondary') || skin === 'transparent') {
      return 'bg-transparent';
    }

    if (skin === 'error') {
      return 'bg-error1';
    }

    switch (skin) {
      case 'lowRank':
        switch (state) {
          case '':
            return 'bg-lowRankButton';
          case 'Hover':
            return 'bg-lowRankButtonHover';
          default:
            return 'bg-lowRankButtonPressed';
        }
      case 'primary':
        switch (state) {
          case '':
            return 'bg-primary';
          case 'Hover':
            return 'bg-dangerHover';
          default:
            return 'bg-primary';
        }
      case 'secondary':
        switch (state) {
          case '':
          case 'Hover':
            return 'bg-primaryTeal';
          default:
            return 'bg-secondaryButtonPressed';
        }
      case 'unique':
        switch (state) {
          case '':
            return 'bg-uniqueButton';
          case 'Hover':
            return 'bg-uniqueButtonHover';
          default:
            return 'bg-uniqueButtonPressed';
        }
    }
  };

  const getTextColour = () => {
    if (skin === 'primary' || skin === 'unique' || skin === 'error') {
      return 'text-white';
    }

    if (skin === 'lowRank') {
      return 'text-black font-bold';
    }

    switch (state) {
      case '':
        return 'text-primaryButton';
      case 'Hover':
        return 'text-white';
      case 'Pressed':
        return 'text-primaryButtonPressed';
    }
  };

  const getBorder = () => {
    if (skin === 'transparent' && state === 'Hover') {
      return 'shadow';
    }

    if (skin === 'lowRank') {
      return 'border border-black';
    }

    if (skin === 'secondary') {
      return `border-2 ${
        state === 'Hover' ? 'border-tealBorder' : 'border-primaryTeal'
      }`;
    }
    return '';
  };

  const buttonClasses = `${iconButton ? 'rounded-full' : 'rounded-lg'}  ${
    expand && ' w-full '
  } focus:outline-none ${
    iconButton ? iconButtonSizeList[size] : sizeList[size]
  } ${getBackgroundColour()} ${getTextColour()} ${getBorder()} ${
    state === 'Pressed' && 'shadow-inner'
  } ${isDisabled ? 'opacity-40 cursor-not-allowed' : 'cursor-pointer'} ${
    isLoading && ' cursor-not-allowed '
  }`;

  return (
    <div className="text-left">
      <button
        id={id}
        name={name}
        type={type}
        disabled={isDisabled}
        className={buttonClasses + className}
        onClick={!isLoading ? handleOnClick : () => {}}
        onMouseEnter={() => handleSetState({ hover: true })}
        onMouseLeave={() => handleSetState({ hover: false, pressed: false })}
        onMouseDown={() => handleSetState({ pressed: true })}
        onMouseUp={() => handleSetState({ pressed: false })}
      >
        <div
          className={`flex flex-row ${
            iconButton ? 'relative w-full h-full' : 'px-3'
          } mx-auto`}
        >
          {isLoading === true ? (
            <Spinner color="white" />
          ) : iconButton ? (
            <div className="absolute inset-0 flex flex-row">
              <div className="flex-grow" />
              <div className="flex flex-col">
                <div className="flex-grow" />
                {children}
                <div className="flex-grow" />
              </div>
              <div className="flex-grow" />
            </div>
          ) : (
            children
          )}
        </div>
      </button>
    </div>
  );
};

export default ImprovedButton;
