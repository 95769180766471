import Jimp from 'jimp';
import JPEG from 'jpeg-js';
import { MdClose } from 'react-icons/md';

type Props = {
  imageSrc: string;
  onClose?: () => void;
};

function ImageViewer(props: Props) {
  const { imageSrc, onClose } = props;

  Jimp.decoders['image/jpeg'] = (data) =>
    JPEG.decode(data, {
      maxMemoryUsageInMB: 6144,
      maxResolutionInMP: 600
    });

  return (
    <div className="bg-theme1 rounded-md overflow-y-auto h-full">
      <div className="">
        <div className="mx-8">
          <div className="py-6 flex flex-row">
            <div className="text-lg text-left text-gray-800 font-extrabold">
              View Image
            </div>
            <div className="flex-grow"></div>
            {!!onClose && (
              <MdClose className="text-4xl cursor-pointer" onClick={onClose} />
            )}
          </div>
          <div className=" bg-hotLight rounded-md relative">
            <div className="">
              <div>
                <div className="relative flex flex-col">
                  <div
                    className={`border bg-grayBorder border-stroke1 relative flex rounded-lg overflow-hidden outline-none`}
                    style={{ width: '100%', minHeight: '650px' }}
                  >
                    <div
                      key={imageSrc}
                      style={{
                        flexShrink: 0,
                        backgroundImage: `url("${imageSrc}")`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%'
                      }}
                      className="flex-grow mx-auto"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageViewer;
