import { ActionLinkType } from 'Colugo/interfaces/event/IEvent';
import {
  linkType,
  linkUrlType
} from 'Colugo/interfaces/games/blocks/IExternalLinkBlockItem';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import ListingOperations from 'Colugo/operations/listings/ListingOperations';
import { EnumHelper } from 'Colugo/utility/helpers';
import { container } from 'tsyringe';
import { validateListing } from 'utility/general/validators';
import ImportContainer from './ImportContainer';
import { NameLocationResult } from 'Colugo/interfaces/NameLocationResult';
import { requestResponse } from 'Colugo/provider/HttpClient';

const listingOperations = container.resolve(ListingOperations);

enum ColumnnHeader {
  Name,
  Description,
  Category1,
  Category2,
  Location,
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  TimeExplanation,
  ActionLinkType,
  ActionLinkUrl,
  Website,
  ContactNumber,
  FacebookLink,
  InstagramLink,
  TwitterLink,
  TikTokLink,
  PriorityTag1,
  PriorityTag2,
  PriorityTag3,
  Tag1,
  Tag2,
  Tag3,
  Tag4,
  Tag5
}

function ImportListingsContainer() {
  async function getEntityAsync(
    parsedColumns: string[],
    getActionLinkType: (linkString: string) => ActionLinkType
  ): Promise<IListing | undefined> {
    let listing: IListing = {
      name: parsedColumns[ColumnnHeader.Name].trim(),
      description: parsedColumns[ColumnnHeader.Description],
      isActive: true,
      timeExplanation: parsedColumns[ColumnnHeader.TimeExplanation],
      images: parsedColumns
        .slice(ColumnnHeader.Image1, ColumnnHeader.Image5 + 1)
        .filter((c) => c !== ''),
      contact: parsedColumns[ColumnnHeader.ContactNumber].trim(),
      social: {
        website: parsedColumns[ColumnnHeader.Website],
        actionLinkType: getActionLinkType(
          parsedColumns[ColumnnHeader.ActionLinkType]
        ),
        actionLinkUrl: parsedColumns[ColumnnHeader.ActionLinkUrl],
        socialLinks: [
          {
            type: linkType.url,
            urlType: linkUrlType.Facebook,
            value: parsedColumns[ColumnnHeader.FacebookLink]
          },
          {
            type: linkType.url,
            urlType: linkUrlType.Instagram,
            value: parsedColumns[ColumnnHeader.InstagramLink]
          },
          {
            type: linkType.url,
            urlType: linkUrlType.Twitter,
            value: parsedColumns[ColumnnHeader.TwitterLink]
          },
          {
            type: linkType.url,
            urlType: linkUrlType.TikTok,
            value: parsedColumns[ColumnnHeader.TikTokLink]
          }
        ]
      },
      address: {}
    };

    return listing;
  }

  const columnHeaderStrings: string[] = EnumHelper.getEnumValuesForFlyout(
    ColumnnHeader
  ).map((fmi) => fmi.label);

  return (
    <ImportContainer
      columnHeaders={columnHeaderStrings}
      entityString="Listing"
      locationIndex={ColumnnHeader.Location}
      priorityTag1Index={ColumnnHeader.PriorityTag1}
      priorityTag2Index={ColumnnHeader.PriorityTag2}
      priorityTag3Index={ColumnnHeader.PriorityTag3}
      tag1Index={ColumnnHeader.Tag1}
      tag2Index={ColumnnHeader.Tag2}
      tag3Index={ColumnnHeader.Tag3}
      tag4Index={ColumnnHeader.Tag4}
      tag5Index={ColumnnHeader.Tag5}
      nameIndex={ColumnnHeader.Name}
      category1Index={ColumnnHeader.Category1}
      category2Index={ColumnnHeader.Category2}
      validateEntity={validateListing}
      getEntityAsync={getEntityAsync}
      createForCsvUploadAsync={async (listings: IListing[]) =>
        await listingOperations.createForCsvUploadAsync(listings)
      }
      checkNameForLocationAsync={async (entity) => {
        const response = listingOperations.checkNameLocationAsync(entity);
        return {
          ...response,
          data: (await response).data === NameLocationResult.Unique
        } as requestResponse<boolean>;
      }}
    />
  );
}

export default ImportListingsContainer;
