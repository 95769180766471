import { ITag } from 'Colugo/interfaces/tags/ITag';
import { useReqListTags } from 'Colugo/operations/tags';

const hotSpotName: string = 'hot spot';

//NOTE: Massive shout out to the incredible app team for providing this beautiful code
type ITagUtilities = {
  getCompleteTagDetails: (
    tagswithoutDetails: ITag[],
    isSuperAdmin: boolean
  ) => ITag[];
};

function useTagUtilities(): ITagUtilities {
  const { data: tags } = useReqListTags();

  function getCompleteTagDetails(
    tagswithoutDetails: ITag[],
    isSuperAdmin: boolean
  ) {
    if (!tags) {
      return [];
    }

    const populatedTags = tagswithoutDetails.reduce((acc: ITag[], current) => {
      const foundITag = tags.find(
        (t: ITag) => t.id === current.id || t.name === current.name
      );
      if (foundITag) {
        acc.push({ ...current, ...foundITag });
      }
      return acc;
    }, []);

    return isSuperAdmin
      ? populatedTags
      : populatedTags.filter((pt) => pt.name?.toLowerCase() !== hotSpotName);
  }

  return {
    getCompleteTagDetails
  };
}
export default useTagUtilities;
