import accountCreatedImage from 'images/onboarding/accountCreatedImage.png';

import { Button } from '@bindystreet/bindystreet.kit.react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';

function AccountCreated() {
  const navigate = useNavigate();
  return (
    <Layout customImage={accountCreatedImage}>
      <div className="flex-col px-40 items-center justify-center">
        <div className="mt-4">
          <h2 className="heading-bold-2xl-onSurface pr-12">
            Thank you for creating an account with us!
          </h2>
        </div>
        <div className="mt-2 heading-regular-l-onSurfaceVariant">
          <div>
            Please check your emails and verify your account. Once verified,
            you'll be able to access your business profile on Ember Business.
          </div>
        </div>
        <div>
          <Button
            size="lg"
            type="submit"
            className="flex flex-row justify-center font-nunito items-center w-auto px-6 py-3 mt-6 mr-auto bg-primary"
            onClick={() => {
              navigate('/');
            }}
          >
            <span className="heading-semibold-av-onPrimary">Return </span>
          </Button>
        </div>
      </div>
    </Layout>
  );
}

export default AccountCreated;
