import { getTailwindColour } from '@bindystreet/bindystreet.kit.react';
import ReactSwitch from 'react-switch';

type Props = {
  checked?: boolean;
  onChange: () => void;
  skin?: 'primary' | 'text';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
};

const ImprovedSwitch = (props: Props) => {
  const {
    checked = false,
    onChange,
    size = 'md',
    className,
    skin = 'primary'
  } = props;

  const onColor = getTailwindColour('green');

  const heightList: { [key: string]: number } = {
    sm: 15,
    md: 20,
    lg: 25,
    xl: 30
  };

  const widthList: { [key: string]: number } = {
    sm: 30,
    md: 40,
    lg: 50,
    xl: 60
  };

  const handleDiameterList: { [key: string]: number } = {
    sm: 13,
    md: 18,
    lg: 23,
    xl: 28
  };

  let uncheckedIcon: boolean | JSX.Element = false;
  let checkedIcon: boolean | JSX.Element = false;
  let width: undefined | number = widthList[size];
  let height: undefined | number = heightList[size];

  switch (skin) {
    case 'text':
      checkedIcon = (
        <div className="text-white absolute" style={{ left: '10px' }}>
          ENABLED
        </div>
      );
      uncheckedIcon = (
        <div
          className="text-hotLight text-right absolute"
          style={{ right: '10px' }}
        >
          DISABLED
        </div>
      );
      width = 120;
      height = undefined;
  }

  return (
    <div className={className}>
      <ReactSwitch
        checked={checked}
        onChange={onChange}
        onColor={onColor}
        height={height}
        width={width}
        handleDiameter={handleDiameterList[size]}
        uncheckedIcon={uncheckedIcon}
        checkedIcon={checkedIcon}
      />
    </div>
  );
};

export default ImprovedSwitch;
