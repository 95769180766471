import { IBusinessClaim } from 'Colugo/interfaces/business/IBusinessClaim';
import { IBusinessOwnsClaimsRequests } from 'Colugo/interfaces/business/IBusinessOwnsClaimsRequests';
import { requestResponse } from 'Colugo/provider/HttpClient';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';
import { ClaimRequestStatus } from 'Colugo/interfaces/business/IBaseListingRequest';
import { EmptyGuid } from 'Colugo/interfaces/constants';

@scoped(Lifecycle.ContainerScoped)
class ClaimOperations extends AbstractOperations {
  protected basePath: string = 'claim';

  public async createAsync(
    claim: IBusinessClaim
  ): requestResponse<IBusinessClaim> {
    return await this.executePost(``, claim);
  }

  public async handleRequestAsync(
    businessClaimId: string,
    claimRequestStatus: ClaimRequestStatus,
    rejectionReason?: string
  ): requestResponse<IBusinessClaim> {
    return await this.executePut(
      `${businessClaimId}/handle/${claimRequestStatus}?rejectionReason=${rejectionReason}`
    );
  }

  public async deleteAsync(id: string): requestResponse {
    return await this.executeDelete(`${id}`);
  }
}

const useReqOwnsClaimsRequestsAsync =
  (): swrRequestResponse<IBusinessOwnsClaimsRequests> => {
    return useGetHttpClient(
      'claim/ownsClaimsAndRequests',
      {
        revalidateOnFocus: true
      },
      false
    );
  };

const useReqListBusinessClaimsAsync = (
  claimRequestStatus: ClaimRequestStatus,
  take: number = 100,
  cursorId: string = EmptyGuid
): swrRequestResponse<IBusinessClaim[]> => {
  return useGetHttpClient(
    claimRequestStatus
      ? `claim/list/${claimRequestStatus}?take=${take}&cursorId=${cursorId}`
      : null,
    {
      revalidateOnFocus: false
    },
    false
  );
};

export default ClaimOperations;
export { useReqOwnsClaimsRequestsAsync, useReqListBusinessClaimsAsync };
