import { Input } from '@bindystreet/bindystreet.kit.react';
import { IListing } from 'Colugo/interfaces/listing/IListing';

type Props = {
  localListing: IListing;
  setLocalListing: (localListing: IListing) => void;
  updateListingAsync: (updatedListing: IListing) => Promise<boolean>;
};

function ListingOpeningTimesContainer(props: Props) {
  const { localListing, setLocalListing, updateListingAsync } = props;
  return (
    <div className="flex flex-col rounded-lg px-6 py-8 w-full">
      <span className="w-57 text-inter text-2xl font-bold leading-9 text-left">
        Opening Times
      </span>
      <div className="flex flex-row mt-6">
        <div style={{ width: '85%' }}>
          <Input
            value={localListing?.timeExplanation}
            placeholder="E.g., Monday - Saturday 7:30am - 7:00pm"
            size="md"
            onBlurWithinCharacterLimit={async () =>
              await updateListingAsync(localListing)
            }
            onChange={(e) =>
              setLocalListing({
                ...localListing,
                timeExplanation: e.currentTarget.value
              })
            }
          />
        </div>

        <div className="border-r-2 border-r-gray mx-6 mb-4 mt-2"></div>
        <div>Please list the opening times of your business.</div>
      </div>
    </div>
  );
}

export default ListingOpeningTimesContainer;
