import {
  ErrorType,
  IIoiValidationError
} from 'Colugo/interfaces/IIoiValidationError';
import { EventType, IEvent } from 'Colugo/interfaces/event/IEvent';
import {
  maxDescriptionLength,
  maxNameLength
} from 'components/events/general/GeneralEventSettings';
import Validator from './RegexValidator';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { currentIsoDateString } from 'utility/constants/constants';

export const isValidDate = (dateString: string) => {
  const timestamp = Date.parse(dateString);
  return !isNaN(timestamp);
};

export function validateEvent(event: IEvent): IIoiValidationError[] {
  const validationErrors: IIoiValidationError[] = [];

  if (!event.categories || event.categories.length === 0) {
    validationErrors.push({
      tabMessage: 'Must select at least one "Category".',
      type: ErrorType.Category
    });
  }

  if (!event.description || event.description.length === 0) {
    validationErrors.push({
      tabMessage: 'Must have a description.',
      type: ErrorType.Description
    });
  }

  if (event.description && event.description.length > maxDescriptionLength) {
    validationErrors.push({
      tabMessage:
        'To activate your event the description cannot exceed ' +
        maxDescriptionLength +
        '. Event description: ' +
        event.description,
      type: ErrorType.Description
    });
  }

  if (event.name && event.name.length > maxNameLength) {
    validationErrors.push({
      tabMessage:
        'To activate your event the name cannot exceed ' +
        maxNameLength +
        '. Event name: ' +
        event.name,
      type: ErrorType.Name
    });
  }

  if (!event.images || event.images.length === 0) {
    validationErrors.push({
      tabMessage: 'Must upload a "Primary image".',
      type: ErrorType.Image
    });
  }

  if (
    event.inheritAddress
      ? !event.listing?.address ||
        !(
          event.listing.address.addressString ||
          Object.keys(event.listing.address).length > 3
        )
      : !event.address || Object.keys(event.address).length <= 3
  ) {
    validationErrors.push({
      tabMessage:
        'You need to provide an address for this event or it cannot be activated',
      type: ErrorType.Address
    });
  }

  if (
    event.images &&
    event.images.some((image) => image.split('.')[1].split(' ')[0] === 'webp')
  ) {
    validationErrors.push({
      tabMessage: `One of the image URLs are WebP, which is not supported (${event.name})`,
      type: ErrorType.Image
    });
  }

  if (!event.tags || event.tags.length === 0) {
    validationErrors.push({
      tabMessage: 'To activate your event you must select at least one "tag".',
      type: ErrorType.Tag
    });
  }

  if (event.type === EventType.DateRange && !event.endDate) {
    validationErrors.push({
      tabMessage: 'Please complete required field End date',
      type: ErrorType.EndDate
    });
  }

  if (
    event.type === EventType.DateRange &&
    event.startDate &&
    event.endDate &&
    (event.endDate < currentIsoDateString || event.endDate < event.startDate)
  ) {
    const errorMessage =
      event.endDate < currentIsoDateString
        ? `End date cannot be set to a date prior to today's current date`
        : `End date cannot be set to be before the start date`;

    if (event.endDate !== event.startDate) {
      validationErrors.push({
        tabMessage: errorMessage,
        type: ErrorType.EndDate
      });
    }
  }

  if (!event.startDate) {
    validationErrors.push({
      tabMessage: 'Please enter a valid date field',
      type: ErrorType.StartDate
    });
  }

  if (event.time?.isExplanationOnly && !event.time?.timeExplanation) {
    validationErrors.push({
      tabMessage: 'Enter a valid time explanation',
      type: ErrorType.TimeExplanation
    });
  }

  if (
    (event.type === EventType.SingleDate ||
      event.type === EventType.DateRange) &&
    event.time &&
    !event.time.isExplanationOnly &&
    !event?.time.times?.times[0].openingTime
  ) {
    validationErrors.push({
      tabMessage: 'Enter a valid time',
      type: ErrorType.OpeningTime
    });
  }

  if (
    (event.type === EventType.SingleDate ||
      event.type === EventType.DateRange) &&
    event.time &&
    !event.time.isExplanationOnly &&
    !event?.time.times?.times[0].closingTime
  ) {
    validationErrors.push({
      tabMessage: 'Enter a valid time',
      type: ErrorType.ClosingTime
    });
  }

  if (
    event.social &&
    event.social.actionLinkUrl &&
    !Validator.isUrlValid(event.social.actionLinkUrl)
  ) {
    validationErrors.push({
      tabMessage: 'Invalid URL: ' + event.social.actionLinkUrl,
      type: ErrorType.UrlExplanation
    });
  }

  if (
    event.social &&
    event.social.website &&
    !Validator.isUrlValid(event.social.website)
  ) {
    validationErrors.push({
      tabMessage: 'Invalid URL: ' + event.social.website,
      type: ErrorType.UrlExplanation
    });
  }

  if (event.contact && !Validator.isPhoneNumberValid(event.contact)) {
    validationErrors.push({
      tabMessage: 'Invalid contact number: ' + event.contact,
      type: ErrorType.ContactNumber
    });
  }

  validationErrors.forEach((ve) => (ve.name = event.name));

  return validationErrors;
}

export function validateListing(listing: IListing): IIoiValidationError[] {
  const validationErrors: IIoiValidationError[] = [];

  if (!listing.categories || listing.categories.length === 0) {
    validationErrors.push({
      tabMessage: 'Must select at least one "Category".',
      type: ErrorType.Category
    });
  }

  if (!listing.description || listing.description.length === 0) {
    validationErrors.push({
      tabMessage: 'Must have a description.',
      type: ErrorType.Description
    });
  }

  if (listing.name && listing.name.length > maxNameLength) {
    validationErrors.push({
      tabMessage:
        'Name cannot exceed ' + maxNameLength + '. Event name: ' + listing.name,
      type: ErrorType.Description
    });
  }

  if (
    listing.description &&
    listing.description.length > maxDescriptionLength
  ) {
    validationErrors.push({
      tabMessage:
        'Description cannot exceed ' +
        maxDescriptionLength +
        '. Event name: ' +
        listing.name,
      type: ErrorType.Category
    });
  }

  if (!listing.images || listing.images.length === 0) {
    validationErrors.push({
      tabMessage: 'Must upload a "Primary image".',
      type: ErrorType.Image
    });
  }

  if (
    listing.images &&
    listing.images.some((image) => image.split('.')[1].split(' ')[0] === 'webp')
  ) {
    validationErrors.push({
      tabMessage: `One of the image URLs are WebP, which is not supported (${listing.name})`,
      type: ErrorType.Image
    });
  }

  if (!listing.tags || listing.tags.length === 0) {
    validationErrors.push({
      tabMessage: 'Select at least one "tag".',
      type: ErrorType.Tag
    });
  }

  if (
    listing.social &&
    listing.social.actionLinkUrl &&
    !Validator.isUrlValid(listing.social.actionLinkUrl)
  ) {
    validationErrors.push({
      tabMessage: 'Invalid URL: ' + listing.social.actionLinkUrl,
      type: ErrorType.UrlExplanation
    });
  }

  if (
    listing.social &&
    listing.social.website &&
    !Validator.isUrlValid(listing.social.website)
  ) {
    validationErrors.push({
      tabMessage: 'Invalid URL: ' + listing.social.website,
      type: ErrorType.UrlExplanation
    });
  }

  if (listing.contact && !Validator.isPhoneNumberValid(listing.contact)) {
    validationErrors.push({
      tabMessage: 'Invalid phone number: ' + listing.contact,
      type: ErrorType.ContactNumber
    });
  }

  validationErrors.forEach((ve) => (ve.name = listing.name));

  return validationErrors;
}

type ValidationErrors<T> = Partial<Record<keyof T, string>>;

export const validateFields = <T extends Record<string, any>>(
  data: T,
  required: (keyof T)[],
  errorMessages: Record<keyof T, string>
): ValidationErrors<T> => {
  let errors: ValidationErrors<T> = {};

  required.forEach((key) => {
    let rawValue = data[key];

    const value = typeof rawValue === 'number' ? `${rawValue}` : rawValue;

    if (
      value === null ||
      value === undefined ||
      (typeof value === 'object' && JSON.stringify(value) === '') ||
      (value as string).toString().trim() === ''
    ) {
      errors[key] = errorMessages[key] ?? 'Please, answer this question';
    }
  });

  return errors;
};
