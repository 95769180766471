import { FlyoutMenu } from '@bindystreet/bindystreet.kit.react';
import { EventStatus, IEvent } from 'Colugo/interfaces/event/IEvent';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import EventOperations from 'Colugo/operations/events/EventOperations';
import { EnumHelper } from 'Colugo/utility/helpers';
import EntitySearch, {
  SearchedEntityType
} from 'components/dashboard/EntitySearch';
import ConfirmationPopup from 'components/shared/ConfirmationPopup';
import { isEqual } from 'lodash';
import { EventsContext } from 'provider/events/eventProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import EventCard from './EventCard';

const eventOperations = container.resolve(EventOperations);

function EventSearch() {
  const { events, setEventStatus, eventStatus } = useContext(EventsContext);
  const { errorToast } = useErrorToast();
  const [localEvents, setLocalEvents] = useState<IEvent[]>(events);
  const [isSearchMode, setIsSearchMode] = useState<boolean>(true);
  const [isFlyoutMenuOpen, setIsFlyoutMenuOpen] = useState<boolean>(false);
  const [eventIdToDelete, setEventIdToDelete] = useState<string | undefined>(
    undefined
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!!events) {
      setLocalEvents(events);
    }
  }, [events]);

  async function handleDeleteEventAsync() {
    const eventToBeRemoved = events.find((p) => p.id === eventIdToDelete);
    if (!eventIdToDelete || !eventToBeRemoved) {
      errorToast('Cannot find event, please try again.');
      return;
    }

    const updatedEvents = events.filter((p) => p.id !== eventIdToDelete);
    setLocalEvents(updatedEvents);
    const { error } = await eventOperations.deleteAsync(eventIdToDelete);

    if (error) {
      errorToast('Failed to delete event, please try again');
      setEventIdToDelete(undefined);
      setLocalEvents(localEvents);
      return false;
    }

    setEventIdToDelete(undefined);
  }

  const onSearchEvents = useCallback(
    async (query: string) => {
      if (!query) {
        return;
      }
      const { data, error } = await eventOperations.searchEventsAsync(query);
      setIsSearchMode(true);
      if (!data || error) {
        setIsSearchMode(false);
        errorToast('An error occured, please try again');
        return;
      }
      setLocalEvents(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setLocalEvents, setIsSearchMode]
  );

  const onClearSearchQuery = useCallback(() => {
    setLocalEvents(events);
    setIsSearchMode(false);
  }, [events, setIsSearchMode, setLocalEvents]);

  const eventStatusFlyoutMenuItems =
    EnumHelper.getEnumValuesForFlyout(EventStatus);

  return (
    <div className="flex flex-col pt-12">
      <div style={{ marginLeft: '152px' }}>
        <EntitySearch
          setIsSearchMode={setIsSearchMode}
          onSearchAsync={onSearchEvents}
          onClearSearchQuery={onClearSearchQuery}
          activeSearchType={SearchedEntityType.Event}
          onClickCreateButton={() => {
            navigate(PuffinRoutes.CreateEvent);
          }}
        />
      </div>
      <div className="pr-8 flex flex-row">
        <div
          className={`font-inter font-bold text-2xl flex ${
            isSearchMode && 'border-grayBorder border-b-2'
          } py-3`}
          style={{ marginLeft: '152px', lineHeight: '35px', fontSize: '25px' }}
        >
          Events Search
        </div>
        <div className="flex flex-grow" />
        <div
          className="mr-2 pr-2 cursor-pointer w-48"
          style={{ marginRight: '140px' }}
        >
          <FlyoutMenu
            isEqual={isEqual}
            size="sm"
            activeKey={eventStatus}
            withImage
            defaultPlaceholder="Search By"
            items={eventStatusFlyoutMenuItems}
            isMenuOpen={isFlyoutMenuOpen}
            setIsMenuOpen={setIsFlyoutMenuOpen}
            onChange={(value) => {
              setEventStatus(
                eventStatusFlyoutMenuItems.find((t) => t.value === value.label)
                  ?.key || EventStatus.All
              );
              setIsFlyoutMenuOpen(false);
            }}
          />
        </div>
      </div>
      <div className="flex flex-row flex-wrap pl-36 mt-1">
        {localEvents?.map((event, i) => (
          <div
            className="w-auto cursor-pointer"
            key={i}
            style={{ margin: '10px' }}
          >
            <EventCard
              event={event}
              listing={event.listing}
              onClickDelete={() => setEventIdToDelete(event.id)}
              width="456px"
            />
          </div>
        ))}
      </div>
      <div className="pr-8">
        {isSearchMode && localEvents.length > 0 && (
          <div className="flex flex-row mt-4 ml-36 py-12 items-center justify-center bg-theme3 rounded-lg border font-nunito text-base font-normal border-grayBorder ">
            Can't find what you're looking for? Update your search query to see
            more results
          </div>
        )}
      </div>
      <div className="pr-8">
        {isSearchMode && localEvents.length === 0 && (
          <div
            className="flex flex-row items-center justify-center ml-36 mt-2 font-nunito text-lg text-center border-dashed rounded bg-lightGreen border-2 border-primaryTeal"
            style={{ height: '371px' }}
          >
            <span>No results found, please search again or </span>
            <span
              className="underline font-bold text-lg ml-1 font-nunito cursor-pointer"
              onClick={() => navigate(PuffinRoutes.CreateEvent)}
            >
              create a new Event
            </span>
          </div>
        )}
      </div>
      {!!eventIdToDelete && (
        <ConfirmationPopup
          isModalOpen={!!eventIdToDelete}
          handleClickConfirmButton={async () => await handleDeleteEventAsync()}
          closeModal={() => setEventIdToDelete(undefined)}
          popupLabel={`Delete this Event?`}
          confirmButtonText="Delete Event"
          isErrorButton={true}
        >
          <div className="font-nunito text-primaryCharcoal font-normal text-base">
            If a Event is permanently deleted, it cannot be undone. Are you sure
            you want to delete this Event?
          </div>
        </ConfirmationPopup>
      )}
    </div>
  );
}

export default EventSearch;
