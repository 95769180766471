import { Input } from '@bindystreet/bindystreet.kit.react';
import {
  ErrorType,
  IIoiValidationError
} from 'Colugo/interfaces/IIoiValidationError';
import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { IPromotion } from 'Colugo/interfaces/promotions/IPromotion';
import { ITag } from 'Colugo/interfaces/tags';
import PromotionOperations from 'Colugo/operations/promotions/PromotionOperations';
import TagSelector from 'components/listings/tagging/TagSelector';
import ImprovedButton from 'components/shared/ImprovedButton';
import ImprovedSwitch from 'components/shared/ImprovedSwitch';
import { ManagerContext } from 'provider/manager/managerProvider';
import { PromotionsContext } from 'provider/promotions/promotionsProvider';
import { populateTags } from 'provider/tags/tagsProvider';
import { useContext, useState } from 'react';
import { FaCircleCheck } from 'react-icons/fa6';
import { IoMdClose } from 'react-icons/io';
import { TiWarning } from 'react-icons/ti';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import Validator from 'utility/general/RegexValidator';
import { useErrorToast } from 'utility/hooks/useErrorToast';

const promotionOperations = container.resolve(PromotionOperations);

type Props = {
  setIsModalOpen: (isCreateModalOpen: boolean) => void;
  promotionTags: ITag[];
  modalTitle: string;
  entity?: IListing | IEvent;
  onCreatePromotion?: (promotion: IPromotion) => void;
  onEditPromotion: (promotion: IPromotion) => void;
  promotion?: IPromotion;
  isEventPromotionsTabs: boolean;
};

function CreateOrEditPromotion(props: Props) {
  const {
    setIsModalOpen,
    promotionTags,
    entity,
    modalTitle,
    onCreatePromotion,
    onEditPromotion,
    promotion,
    isEventPromotionsTabs
  } = props;

  const isEditing = !!promotion;

  const emptyPromotion: IPromotion = {
    isActive: true,
    type: '',
    tags: [],
    title: ''
  };
  const { promotions, mutatePromotions } = useContext(PromotionsContext);
  const { mutateListing, listing } = useContext(ManagerContext);
  const [showTitleErrorMessage, setShowTitleErrorMessage] =
    useState<boolean>(false);
  const [showDetailsErrorMessage, setShowDetailsErrorMessage] =
    useState<boolean>(false);
  const [showLinkErrorMessage, setShowLinkErrorMessage] =
    useState<boolean>(false);
  const [showEntityErrorMessage, setShowEntityErrorMessage] =
    useState<boolean>(false);
  const [isCreateButtonLoading, setIsCreateButtonLoading] = useState(false);
  const [localPromotion, setLocalPromotion] = useState<IPromotion>(
    promotion || emptyPromotion
  );
  const [localValidationErrors, setLocalValidationErrors] = useState<
    IIoiValidationError[]
  >([]);
  const { errorToast } = useErrorToast();

  const maxTitleLength = 30;
  const maxDetailsLength = 160;
  const maxPromotionCount = 5;

  async function submitCreateOrEditPromotionAsync() {
    const isTitleInvalid =
      !localPromotion.title ||
      localPromotion.title.length > maxTitleLength ||
      localPromotion.title.length === 0;

    const isDetailsInvalid =
      !localPromotion.details ||
      localPromotion.details.length > maxDetailsLength ||
      localPromotion.details.length === 0;

    const isLinkInvalid =
      !!localPromotion.link && !Validator.isUrlValid(localPromotion.link);

    const isEntityInvalid =
      (isEventPromotionsTabs
        ? !localPromotion.event || !localPromotion.event.id
        : !localPromotion.listing || !localPromotion.listing.id) && !entity;

    const isPromotionTagsInvalid =
      !localPromotion.tags || localPromotion.tags.length === 0;

    if (isTitleInvalid) {
      setShowTitleErrorMessage(true);
    }

    if (isDetailsInvalid) {
      setShowDetailsErrorMessage(true);
    }

    if (isLinkInvalid) {
      setShowLinkErrorMessage(true);
    }

    if (isEntityInvalid) {
      setShowEntityErrorMessage(true);
    }

    if (!localPromotion.tags || localPromotion.tags.length === 0) {
      setLocalValidationErrors([
        { tabMessage: 'Must select at least one "Tag".', type: ErrorType.Tag }
      ]);
    }

    if (
      isTitleInvalid ||
      isDetailsInvalid ||
      isEntityInvalid ||
      isPromotionTagsInvalid ||
      isLinkInvalid
    ) {
      return;
    }

    setIsCreateButtonLoading(true);

    if (!localPromotion) {
      errorToast('Unable to create promotion');
      return;
    }

    if (entity) {
      if (isEventPromotionsTabs) {
        localPromotion.event = entity;
      } else {
        localPromotion.listing = entity;
      }
    }

    let localPromotionDraft: IPromotion;

    if (isEventPromotionsTabs) {
      localPromotionDraft = {
        ...localPromotion,
        event: { ...localPromotion?.event!, promotions: [] }
      };
    } else {
      localPromotionDraft = {
        ...localPromotion,
        listing: { ...localPromotion?.listing!, promotions: [] }
      };
    }

    const { data: updatedOrCreatedPromotion, error } = isEditing
      ? await promotionOperations.updateAsync(localPromotionDraft)
      : await promotionOperations.createAsync(localPromotionDraft);

    if (error || !updatedOrCreatedPromotion) {
      errorToast(`Failed to ${isEditing ? 'create' : 'edit'} promotion.`);
      setIsCreateButtonLoading(false);
      return;
    }

    setIsCreateButtonLoading(false);

    if (isEditing) {
      onEditPromotion && onEditPromotion(localPromotionDraft);
    } else {
      if (isEventPromotionsTabs) {
        mutatePromotions([
          ...promotions,
          { ...updatedOrCreatedPromotion, event: entity }
        ]);
        mutateListing({
          ...listing!,
          events: [
            ...(listing?.events?.filter(
              (e) => e.id !== updatedOrCreatedPromotion.id
            ) || []),
            {
              ...entity!,
              promotions: [
                ...(entity?.promotions || []),
                updatedOrCreatedPromotion
              ]
            }
          ]
        });
      } else {
        mutatePromotions([
          ...promotions,
          { ...updatedOrCreatedPromotion, listing: entity }
        ]);
        mutateListing({
          ...listing!,
          promotions: [
            ...(listing?.promotions || []),
            updatedOrCreatedPromotion
          ]
        });
      }
      onCreatePromotion && onCreatePromotion(updatedOrCreatedPromotion);
    }
    setIsModalOpen(false);
  }

  function handleAddTag(tagId: string) {
    if (!promotionTags || promotionTags.length === 0) {
      toast.warn('No tags found.');
      return;
    }
    const tagToAdd = promotionTags.find((t) => t.id === tagId);
    if (!tagToAdd) {
      toast.warn('No tag selected');
      return;
    }
    const updatedPromotion = {
      ...localPromotion,
      tags: [...(localPromotion?.tags || []), tagToAdd]
    };
    setLocalPromotion(updatedPromotion);
  }

  function handleRemoveTag(tagId: string) {
    const newTags = localPromotion.tags!.filter((t) => t.id !== tagId);
    let updatedPromotion = {
      ...localPromotion,
      tags: newTags
    };
    setLocalPromotion(updatedPromotion);

    if (newTags.length === 0) {
      updatedPromotion = {
        ...localPromotion,
        tags: newTags
      };
      setLocalPromotion(updatedPromotion);
    }
  }

  const modalHeader = (
    <div
      className="flex flex-col text-left font-inter text-primaryCharcoal font-bold border-b px-8"
      style={{
        fontSize: '16px',
        lineHeight: '24px',
        height: '92px',
        paddingTop: '30px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.10)'
      }}
    >
      <div className="flex flex-row">
        <div
          className="font-inter font-bold text-2xl text-left mb-4"
          style={{ lineHeight: '29px' }}
        >
          {modalTitle}
        </div>
        <div className="flex-grow" />
        <div
          className="cursor-pointer text-3xl px-2 text-secondaryText"
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          <IoMdClose />
        </div>
      </div>
    </div>
  );

  const listingSearch = (
    <div
      className="flex flex-col text-left relative px-8 pt-6"
      style={{ fontSize: '16px', lineHeight: '24px' }}
    >
      {`What ${
        isEventPromotionsTabs ? 'event' : 'business'
      } is this promotion for?`}

      <div
        className="bg-theme3 w-full rounded my-2"
        style={{ height: 56, paddingTop: 16 }}
      >
        <span className="text-secondaryText ml-6 font-nunito font-normal text-base">
          {entity
            ? entity.name
            : isEventPromotionsTabs
            ? promotion?.event?.name
            : promotion?.listing?.name}
        </span>
      </div>
      <span className="text-sm font-inter text-error5 -mt-1 mb-1">
        {showEntityErrorMessage &&
          (isEventPromotionsTabs
            ? 'Please select an Event'
            : 'Please select a Business')}
      </span>
    </div>
  );

  const promotionTitle = (
    <div
      className="flex flex-col text-left mt-4 px-8"
      style={{ fontSize: '16px', lineHeight: '24px' }}
    >
      Promotion Title
      <Input
        isMandatory={true}
        value={localPromotion?.title}
        placeholder="Enter title"
        onChange={(e) => {
          setLocalPromotion({
            ...localPromotion,
            title: e.currentTarget.value
          });
          setShowTitleErrorMessage(false);
        }}
        maxCharacterCount={maxTitleLength}
        showErrorText={false}
      />
      <span className="text-sm font-inter text-error5">
        {showTitleErrorMessage &&
          (!localPromotion.title ||
            localPromotion.title.length < maxTitleLength) &&
          'No promotion name provided, please enter a name for your promotion'}
      </span>
    </div>
  );

  const promotionTagsPicker = (
    <div
      className="flex flex-col text-left px-8"
      style={{ fontSize: '16px', lineHeight: '24px' }}
    >
      <div className="flex justify-between">
        <span className="mb-2">Promotion Tags</span>
        <span className="mb-2 font-inter text-base font-normal">{`${localPromotion.tags.length}/${maxPromotionCount}`}</span>
      </div>
      <div className="font-normal">
        <TagSelector
          tags={promotionTags ?? []}
          onClickTagDeleteIconAsync={handleRemoveTag}
          onConfirmNewTagAsync={handleAddTag}
          entityTags={populateTags(localPromotion.tags, promotionTags) || []}
          validationErrors={localValidationErrors}
          customPlaceholder="Start typing to search promotion tags"
          isInModal
          isDisabled={localPromotion.tags.length >= maxPromotionCount}
        />
      </div>
    </div>
  );

  const promotionDetailsInput = (
    <div
      className="flex flex-col text-left mt-4 px-8"
      style={{ fontSize: '16px', lineHeight: '24px' }}
    >
      Promotion Details
      <Input
        isMandatory={true}
        value={localPromotion?.details}
        placeholder="Enter your short description"
        size="xl"
        multiline
        onChange={(e) => {
          setLocalPromotion({
            ...localPromotion,
            details: e.currentTarget.value
          });
          setShowDetailsErrorMessage(false);
        }}
        maxCharacterCount={maxDetailsLength}
        error={showDetailsErrorMessage && 'Please add Promotion details'}
        showErrorText={false}
      />
      <span className="text-sm font-inter text-error5">
        {showDetailsErrorMessage &&
          (!localPromotion.details ||
            localPromotion.details.length < maxDetailsLength) &&
          'Please add Promotion details'}
      </span>
    </div>
  );

  const promotionLinkInput = (
    <div className="flex flex-col text-left mt-4 px-8">
      <div className="flex-row">
        <span>Promotion Link</span>{' '}
        <span className=" font-normal">(Optional)</span>
      </div>
      <Input
        isMandatory={true}
        value={localPromotion?.link}
        placeholder="Insert link"
        onChange={(e) => {
          setLocalPromotion({
            ...localPromotion,
            link: e.currentTarget.value
          });
          setShowLinkErrorMessage(false);
        }}
        error={
          showLinkErrorMessage &&
          (!localPromotion?.link ||
            !Validator.isUrlValid(localPromotion.link)) &&
          'Invalid URL'
        }
        showErrorText={false}
      />
      <span className="text-sm font-inter text-error5">
        {showLinkErrorMessage && 'Invalid URL'}
      </span>
    </div>
  );

  const promotionDuration = (
    <div
      className="flex flex-col mt-4 px-8 mb-32"
      style={{ fontSize: '16px', lineHeight: '24px' }}
    >
      Promotion Duration
      <span className="font-normal font-nunito">
        Please leave this field blank if your Promotion is permanent or does not
        have an end date
      </span>
      <div className="w-full flex flex-row">
        <Input
          label="Start date"
          onChange={(e) =>
            setLocalPromotion({
              ...localPromotion,
              duration: {
                ...localPromotion.duration,
                startDate: e.currentTarget.value
              }
            })
          }
          name="startDate"
          type="date"
          value={localPromotion.duration?.startDate?.replace('T00:00:00', '')}
        />
        <div className="w-8" />
        <Input
          label="Expiration date"
          onChange={(e) =>
            setLocalPromotion({
              ...localPromotion,
              duration: {
                ...localPromotion.duration,
                endDate: e.currentTarget.value
              }
            })
          }
          name="expirationDate"
          type="date"
          value={localPromotion.duration?.endDate?.replace('T00:00:00', '')}
        />
      </div>
    </div>
  );

  const modalFooter = (
    <div
      className="flex flex-row items-center w-full px-8 py-8 bg-theme3 fixed bottom-0 left-0 right-0"
      style={{
        borderRadius: '0px 0px 8px 8px'
      }}
    >
      {localPromotion.isActive ? (
        <FaCircleCheck className="text-green" size={20} />
      ) : (
        <TiWarning className="text-primary" size={24} />
      )}
      <div className="font-medium font-inter text-base ml-2">
        {localPromotion.isActive ? 'Active' : 'Inactive'} on Ember
      </div>
      <ImprovedSwitch
        className="mt-2 ml-2"
        checked={localPromotion.isActive}
        onChange={() =>
          setLocalPromotion({
            ...localPromotion,
            isActive: !localPromotion.isActive
          })
        }
      />
      <div className="flex-grow" />
      <ImprovedButton
        onClick={() => submitCreateOrEditPromotionAsync()}
        isLoading={isCreateButtonLoading}
        size="lg"
        className={`font-bold text-lg font-nunito ${'px-5'}`}
        skin={'primary'}
      >
        <div>{isEditing ? 'Save' : 'Create'}</div>
      </ImprovedButton>
    </div>
  );

  return (
    <div
      className="flex flex-col justify-center w-full h-full text-left font-inter text-primaryCharcoal font-bold bg-white"
      style={{
        borderRadius: '8px 8px 8px 8px'
      }}
    >
      {modalHeader}
      <div className="overflow-y-auto mb-32 bg-white">
        {listingSearch}
        {promotionTitle}
        {promotionTagsPicker}
        {promotionLinkInput}
        {promotionDetailsInput}
        {promotionDuration}
      </div>
      {modalFooter}
    </div>
  );
}

export default CreateOrEditPromotion;
