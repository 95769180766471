import { IListing } from 'Colugo/interfaces/listing/IListing';
import { useState } from 'react';
import { formatAddress } from 'utility/general/formatHelpers';
import EntityImage from './EntityImage';

type Props = {
  listing: IListing;
  onClick: () => void;
};

function ListingResult(props: Props) {
  const { listing, onClick } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div
      className={` flex flex-row items-center cursor-pointer my-1 px-2 h-10 rounded-xl ${
        isHovered ? 'bg-grayBorder' : 'bg-white'
      } ${listing.businessOwner && ' bg-primaryContainer'}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="mr-2 mt-0.5 relative">
        <EntityImage entity={listing!} imageProportion={25.5} />
        <div
          className={`absolute -top-0.5 -right-0.5 border-white border rounded-full w-2 h-2 ${
            listing.isActive ? 'bg-activeGreen' : 'bg-darkRed'
          }`}
        />
      </div>

      <div className={`flex flex-row w-full truncate`}>
        <div className="font-bold text-lg font-inter">{listing.name}</div>
        <div className="font-normal text-sm mb-1 font-nunito text-secondaryText pt-1 ml-2 ">
          {listing.address && formatAddress(listing.address)}
        </div>
      </div>
    </div>
  );
}

export default ListingResult;
