import React, { createContext } from 'react';
import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { MutatorCallback } from 'swr/dist/types';
import { useReqListListingsAsync } from 'Colugo/operations/listings/ListingOperations';

type Props = {
  children: React.ReactNode;
};

type ListingsContextType = {
  listings: IListing[];
  mutateListings: (
    data?:
      | IListing[]
      | Promise<IListing[]>
      | MutatorCallback<IListing[]>
      | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<IListing[] | undefined>;
};

export const ListingsContext = createContext<ListingsContextType>({
  listings: [],
  mutateListings: async (data?: any, shouldRevalidate?: boolean | undefined) =>
    undefined
});

//NOTE: use the userReducer once the state becomes complex
function ListingsProvider(props: Props) {
  const { children } = props;

  const {
    data: listings,
    isLoading: isListingsLoading,
    isError: isListingsError,
    mutate: mutateListings
  } = useReqListListingsAsync();

  if (isListingsLoading) {
    return (
      <div className="absolute w-full h-screen flex flex-col items-center justify-center">
        <Spinner />;
      </div>
    );
  }

  if (isListingsError) {
    return (
      <ErrorPage>
        <span>{'Unable to load listings from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <ListingsContext.Provider
      value={{
        listings: listings || [],
        mutateListings
      }}
    >
      {children}
    </ListingsContext.Provider>
  );
}

export default ListingsProvider;
