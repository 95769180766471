import React, { ReactElement, useEffect, useState } from 'react';
import { Circle, Group, Layer, Stage } from 'react-konva';

type Props = {
  id?: string;
  name?: string;
  disabled?: boolean;
  checked?: null | boolean;
  label?: string;
  children?: ReactElement[] | ReactElement | string;
  className?: string;
  size?: 'md' | 'lg' | 'xl';
  selectedColour?: string;
  unselectedColour?: string;
  disabledColour?: string;
  selectedBorderColour?: string;
  unselectedBorderColour?: string;
  error?: boolean;
  onChange?: () => void;
};

const innerCircleSize = 8;
const outerCircleSize = 24;

const ImprovedRadioButton = (props: Props) => {
  const {
    id,
    name,
    disabled,
    checked,
    label,
    children,
    className,
    size = 'md',
    selectedColour = '#FFFFFF',
    unselectedColour = '#FFFFFF',
    selectedBorderColour = '#FF566F',
    unselectedBorderColour = '#FFFFFF',
    error,
    onChange = () => {}
  } = props;

  const inputStyle = {
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer',
    height: 0,
    width: 0,
    top: '-9999px'
  } as React.CSSProperties;

  const [isHover, setIsHover] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    if (!isHover && isPressed) {
      setIsPressed(false);
    }
  }, [isPressed, isHover, setIsPressed]);

  const isNull = checked === null;
  return (
    <div
      onClick={!disabled ? onChange : undefined}
      onMouseLeave={() => setIsHover(false)}
      onMouseEnter={() => setIsHover(true)}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      className={`text-left align-middle flex flex-row ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      style={{ minWidth: 0, opacity: disabled ? '0.4' : '1.0' }}
      aria-checked={isNull ? false : checked}
      role="radio"
    >
      <input
        id={id}
        name={name}
        type={'radio'}
        disabled={disabled}
        checked={isNull ? false : checked}
        onChange={onChange}
        className={`mx-1 ${className}
          ${disabled ? `cursor-not-allowed` : ``}
        `}
        style={inputStyle}
      />
      <Stage width={outerCircleSize} height={outerCircleSize}>
        <Layer>
          <Group x={outerCircleSize * 0.5} y={outerCircleSize * 0.5}>
            <Circle
              strokeWidth={1}
              fill={checked ? selectedBorderColour : unselectedBorderColour}
              radius={outerCircleSize * 0.45}
              stroke={error ? '#F35067' : checked ? '' : '#DDD8DF'}
            />
            {checked && (
              <Circle
                fill={checked ? selectedColour : unselectedColour}
                radius={innerCircleSize * 0.5}
              />
            )}
          </Group>
        </Layer>
      </Stage>

      {(label || children) && (
        <label htmlFor={id} className={`ml-3`}>
          <div className={`${disabled && 'text-gray-400'}`}>
            <div className={`${size === 'xl' && 'text-2xl font-bold mt-1'}`}>
              {label}
            </div>
          </div>
          {children}
        </label>
      )}
    </div>
  );
};

export default ImprovedRadioButton;
