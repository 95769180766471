import React, { useState } from 'react';
import '@reach/combobox/styles.css';
import { useLoadScript } from '@react-google-maps/api';
import { GOOGLEPLACES_KEY } from 'utility/constants/constants';
import Map from './Map';
import { IAddress } from 'Colugo/interfaces/listing/IAddress';

type Props = {
  initialValue?: string;
  longitude?: number;
  latitude?: number;
  isInLocationTab?: boolean;
  onLocationChange: (
    newLongitude: number,
    newLatitude: number,
    address: IAddress,
    shouldCreate: boolean,
    shouldContinue: boolean
  ) => void;
  isDisabled?: boolean;
  inputValue?: string;
  shouldShowAutocomplete?: boolean;
  isDraggableAndScrollable?: boolean;
  panToMoveMarker?: boolean;
};

export default function GoogleMapListingLocationContainer(props: Props) {
  const {
    initialValue,
    longitude,
    latitude,
    onLocationChange,
    isInLocationTab = false,
    isDisabled = false,
    inputValue,
    shouldShowAutocomplete = true,
    isDraggableAndScrollable = true
  } = props;
  const [libraries] = useState<
    ('places' | 'drawing' | 'geometry' | 'visualization')[]
  >(['places']);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLEPLACES_KEY,
    libraries
  });
  const mapHeight = isInLocationTab ? 480 : 330;

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative w-full" style={{ height: mapHeight }}>
      <Map
        markerLongitude={longitude}
        markerLatitude={latitude}
        mapLatitude={latitude}
        mapLongitude={longitude}
        height={mapHeight}
        initialValue={initialValue}
        isDisabled={isDisabled}
        onSearchComplete={onLocationChange}
        shouldShowMarker={!!longitude && !!latitude}
        inputValue={inputValue}
        shouldShowAutocomplete={shouldShowAutocomplete}
        isDraggableAndScrollable={isDraggableAndScrollable}
      />
    </div>
  );
}
