import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { IUser } from 'Colugo/interfaces/identity';
import { ApplicationRoles } from 'Colugo/interfaces/identity/IUser';
import { useReqGetUser } from 'Colugo/operations/identity';
import { hasFlag } from 'Colugo/utility/helpers/EnumHelper';
import { AuthContext } from 'provider/auth/authProvider';
import React, { useContext, useEffect, useState } from 'react';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export type UserContextType = {
  user: IUser | undefined;
  setUser: (user: IUser | undefined) => void;
  isSuperAdmin: boolean;
  isEventNewlyCreated: boolean;
  setIsEventNewlyCreated: (isEventNewlyCreated: boolean) => void;
};

const isTokenAvailable = localStorage.getItem('token');

export const UserContext = React.createContext<UserContextType>({
  user: undefined,
  setUser: () => {},
  isSuperAdmin: true,
  setIsEventNewlyCreated: () => undefined,
  isEventNewlyCreated: false
});

function UserProvider(props: Props) {
  const { children } = props;

  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [isEventNewlyCreated, setIsEventNewlyCreated] =
    useState<boolean>(false);

  const { setToken } = useContext(AuthContext);

  const {
    data: userData,
    isLoading: isUserLoading,
    isError: isUserError
  } = useReqGetUser(!!isTokenAvailable);

  useEffect(() => {
    if (userData && isTokenAvailable) {
      setUser(userData);
    }
  }, [userData]);

  if (isUserLoading && isTokenAvailable) {
    return (
      <div className="absolute w-full h-screen flex flex-col items-center justify-center">
        <Spinner />;
      </div>
    );
  }

  if (isUserError && isTokenAvailable) {
    setToken(null);
  }

  const isSuperAdmin = hasFlag(
    user?.applicationRoles!,
    ApplicationRoles.BindyAdmin
  );

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isSuperAdmin,
        isEventNewlyCreated,
        setIsEventNewlyCreated
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
