import { ErrorPage } from '@bindystreet/bindystreet.kit.react';
import { IPromotion } from 'Colugo/interfaces/promotions/IPromotion';
import PromotionOperations from 'Colugo/operations/promotions/PromotionOperations';
import { useReqListTagsByFilterGroup } from 'Colugo/operations/tags';
import EntitySearch, {
  SearchedEntityType
} from 'components/dashboard/EntitySearch';
import { promotionsFilterGroupName } from 'components/dashboard/RecentPromotionsEditor';
import ConfirmationPopup from 'components/shared/ConfirmationPopup';
import PromotionCard from 'components/shared/PromotionCard';
import { PromotionsContext } from 'provider/promotions/promotionsProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { container } from 'tsyringe';

import { FilterGroupContext } from 'provider/filterGroups/filterGroupsProvider';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import CreateOrEditPromotionModal from './CreateOrEditPromotionModal';

const promotionOperations = container.resolve(PromotionOperations);

function PromotionSearch() {
  const { promotions } = useContext(PromotionsContext);
  const [isCreatePromotionModalOpen, setIsCreatePromotionModalOpen] =
    useState(false);
  const [promotionToEdit, setPromotionToEdit] = useState<
    IPromotion | undefined
  >();
  const [localPromotions, setLocalPromotions] =
    useState<IPromotion[]>(promotions);
  const [isSearchMode, setIsSearchMode] = useState<boolean>(true);
  const [promotionIdToDelete, setPromotionIdToDelete] = useState<
    string | undefined
  >(undefined);
  const navigate = useNavigate();
  const { errorToast } = useErrorToast();
  const { promotionsFilterGroup } = useContext(FilterGroupContext);

  const { data: promotionTags, isError: isTagsError } =
    useReqListTagsByFilterGroup(promotionsFilterGroup?.id || '');

  const onSearchPromotions = useCallback(
    async (query: string) => {
      if (!query) {
        return;
      }
      const { data, error } = await promotionOperations.searchPromotionsAsync(
        query
      );
      setIsSearchMode(true);
      if (!data || error) {
        setIsSearchMode(false);
        errorToast('An error occured, please try again');
        return;
      }
      setLocalPromotions(
        data.map((promotion) => {
          //TODO use promotion title here when doing this https://jirabindystreet.atlassian.net/browse/APP-1043
          promotion.type =
            promotionTags?.find((t) => t.id === promotion.tags[0].id)?.name ||
            '';
          return promotion;
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setLocalPromotions, setIsSearchMode, promotionTags]
  );

  const onClearSearchQuery = useCallback(() => {
    setLocalPromotions(promotions);
    setIsSearchMode(false);
  }, [promotions, setIsSearchMode, setLocalPromotions]);

  async function handleDeletePromotionAsync() {
    const promotionToBeRemoved = promotions.find(
      (p) => p.id === promotionIdToDelete
    );
    if (!promotionIdToDelete || !promotionToBeRemoved) {
      errorToast('Cannot find promotion, please try again.');
      return;
    }

    setLocalPromotions(
      localPromotions.filter((p) => p.id !== promotionIdToDelete)
    );
    setPromotionIdToDelete(undefined);
    const { error } = await promotionOperations.deleteAsync(
      promotionIdToDelete
    );

    if (error) {
      errorToast('Failed to delete promotion, please try again');
      setLocalPromotions(localPromotions);
      return false;
    }
  }

  function openCreatePromotionModal(promotion?: IPromotion) {
    if (promotionIdToDelete) {
      return;
    }
    setIsCreatePromotionModalOpen(true);
    setPromotionToEdit(promotion);
  }

  if (isCreatePromotionModalOpen && promotionIdToDelete) {
    setIsCreatePromotionModalOpen(false);
  }

  useEffect(() => {
    if (!!promotions && !isTagsError) {
      setLocalPromotions(
        promotions.map((promotion) => {
          promotion.type =
            promotionTags?.find((t) => t.id === promotion.tags[0].id)?.name ||
            '';
          return promotion;
        })
      );
    }
  }, [promotions, promotionTags, isTagsError]);

  if (!promotionsFilterGroup || !promotionsFilterGroup.id) {
    return (
      <ErrorPage>
        <span>{`There is no filter group named "${promotionsFilterGroupName}". This is required to create a promotion.`}</span>
      </ErrorPage>
    );
  }
  if (!promotionTags || promotionTags.length === 0) {
    return (
      <ErrorPage>
        <span>{`Unable to load tags for filter group named "${promotionsFilterGroupName}".`}</span>
      </ErrorPage>
    );
  }
  return (
    <div className="flex flex-col pt-12">
      <div style={{ marginLeft: '152px' }}>
        <EntitySearch
          setIsSearchMode={setIsSearchMode}
          onSearchAsync={onSearchPromotions}
          onClearSearchQuery={onClearSearchQuery}
          activeSearchType={SearchedEntityType.Promotion}
        />
      </div>
      <div className="pr-8">
        <div
          className={`font-inter font-bold text-2xl ${
            isSearchMode && 'border-grayBorder border-b-2'
          } py-1`}
          style={{ marginLeft: '152px', lineHeight: '27px', fontSize: '18px' }}
        >
          Promotions Search
        </div>
      </div>
      <CreateOrEditPromotionModal
        isCreateModalOpen={isCreatePromotionModalOpen}
        setIsCreateModalOpen={setIsCreatePromotionModalOpen}
        promotion={promotionToEdit}
        modalTitle={!!promotionToEdit ? 'Edit Promotion' : 'Create a Promotion'}
      />
      <div className="flex flex-row flex-wrap pl-36 mt-1">
        {localPromotions?.map((promotion, i) => (
          <div
            className="w-auto cursor-pointer"
            key={i}
            style={{ margin: '10px' }}
          >
            <PromotionCard
              promotion={promotion}
              listing={promotion.listing}
              onClickDelete={() => setPromotionIdToDelete(promotion.id)}
              onClickEdit={() => openCreatePromotionModal(promotion)}
              width="456px"
              onClickListingIcon={() =>
                promotion.listing &&
                navigate(`/listing/${promotion.listing.id}/general`)
              }
            />
          </div>
        ))}
      </div>
      <div className="pr-8">
        {isSearchMode && localPromotions.length > 0 && (
          <div className="flex flex-row mt-4 ml-36 py-12 items-center justify-center bg-theme3 rounded-lg border font-nunito text-base font-normal border-grayBorder ">
            Can't find what you're looking for? Update your search query to see
            more results
          </div>
        )}
      </div>
      <div className="pr-8">
        {isSearchMode && localPromotions.length === 0 && (
          <div
            className="flex flex-row items-center justify-center ml-36 mt-2 font-nunito text-lg text-center border-dashed rounded bg-lightGreen border-2 border-primaryTeal"
            style={{ height: '371px' }}
          >
            <span>No results found, please search again or </span>
            <span
              className="underline font-bold text-lg ml-1 font-nunito cursor-pointer"
              onClick={() => navigate('/?createPromotion=true')}
            >
              Create a new Promotion
            </span>
          </div>
        )}
      </div>
      {!!promotionIdToDelete && (
        <ConfirmationPopup
          isModalOpen={!!promotionIdToDelete}
          handleClickConfirmButton={async () =>
            await handleDeletePromotionAsync()
          }
          closeModal={() => setPromotionIdToDelete(undefined)}
          popupLabel={`Delete this Promotion?`}
          confirmButtonText="Delete Promotion"
          isErrorButton={true}
        >
          <div className="font-nunito text-primaryCharcoal font-normal text-base">
            If a Promotion is permanently deleted, it cannot be undone. Are you
            sure you want to delete this Promotion?
          </div>
        </ConfirmationPopup>
      )}
    </div>
  );
}

export default PromotionSearch;
