import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IFilterGroup } from 'Colugo/interfaces/tags';
import { useReqListFilterGroups } from 'Colugo/operations/tags';
import { promotionsFilterGroupName } from 'components/promotions/CreateOrEditPromotionModal';
import React from 'react';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export type FilterGroupContextType = {
  filterGroups: IFilterGroup[] | undefined;
  promotionsFilterGroup: IFilterGroup | undefined;
};

export const FilterGroupContext = React.createContext<FilterGroupContextType>({
  filterGroups: undefined,
  promotionsFilterGroup: undefined
});

function FilterGroupProvider(props: Props) {
  const { children } = props;

  const {
    data: filterGroups,
    isLoading: isFilterGroupsLoading,
    isError: isFilterGroupsError
  } = useReqListFilterGroups();

  if (isFilterGroupsError) {
    return (
      <ErrorPage>
        <span>{`Error retrieving filter groups.`}</span>
      </ErrorPage>
    );
  }

  if (isFilterGroupsLoading) {
    return (
      <div className="absolute w-full h-full">
        <Spinner expand />
      </div>
    );
  }

  const promotionsFilterGroup = filterGroups?.find(
    (fg) => fg.name === promotionsFilterGroupName
  );

  return (
    <FilterGroupContext.Provider
      value={{
        filterGroups,
        promotionsFilterGroup
      }}
    >
      {children}
    </FilterGroupContext.Provider>
  );
}

export default FilterGroupProvider;
