import { ReactComponent as EmberLogo } from 'images/dashboard/emberLogo.svg';
import HeaderDropdown from './HeaderDropdown';
import { useNavigate } from 'react-router-dom';
import { ManagerContext } from 'provider/manager/managerProvider';
import { useContext } from 'react';
import { UserContext } from 'provider/user/userProvider';

type Props = {
  isDropdownVisible?: boolean;
};

function DashboardHeader(props: Props) {
  const { isDropdownVisible = true } = props;
  const navigate = useNavigate();
  const { hasViewedTooltips } = useContext(ManagerContext);
  const { isSuperAdmin } = useContext(UserContext);

  const isHeaderEnabled = hasViewedTooltips || isSuperAdmin;

  return (
    <div
      className={`top-0 bottom-0 right-0 left-0  bg-surface z-40 border-b border-outline fixed ${
        isHeaderEnabled ? 'cursor-default' : 'cursor-not-allowed'
      }`}
      style={{ height: '72px' }}
    >
      <div className="flex flex-row items-center h-full justify-between pl-4 pr-8">
        <div
          className={`h-full flex flex-row items-center ${
            isHeaderEnabled ? 'cursor-pointer' : 'cursor-not-allowed'
          }`}
          onClick={() => isHeaderEnabled && navigate('/')}
        >
          <EmberLogo />
        </div>
        {isDropdownVisible && isHeaderEnabled && <HeaderDropdown />}
      </div>
    </div>
  );
}

export default DashboardHeader;
