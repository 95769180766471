import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { ILocationDetails } from 'Colugo/interfaces/games/ILocationDetails';
import {
  ErrorType,
  IIoiValidationError
} from 'Colugo/interfaces/IIoiValidationError';
import EventOperations from 'Colugo/operations/events/EventOperations';
import ImprovedCheckbox from 'components/shared/ImprovedCheckbox';
import LocationModal from 'components/shared/LocationModal';
import LocationSnippet from 'components/shared/LocationSnippet';
import { useState } from 'react';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';

const eventOperations = container.resolve(EventOperations);

type Props = {
  localEvent: IEvent;
  setLocalEvent: (localEvent: IEvent) => void;
  updateEventAsync: (updatedEvent: IEvent) => Promise<boolean>;
  validationErrors: IIoiValidationError[];
};

function EventLocationTabContainer(props: Props) {
  const { localEvent, setLocalEvent, updateEventAsync, validationErrors } =
    props;
  const { errorToast } = useErrorToast();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [isListingAddress, setIsListingAddress] = useState<boolean>(
    !!localEvent?.inheritAddress
  );
  const [isLocationModalOpen, setIsLocationModalOpen] =
    useState<boolean>(false);

  const noOtherAddress =
    localEvent?.address && Object.keys(localEvent.address).length > 3
      ? localEvent.address
      : undefined;

  const currentEventWithLocationDetails: IEvent = {
    ...localEvent,
    location:
      isListingAddress && localEvent.listing
        ? localEvent.listing?.location
        : localEvent?.location,
    address:
      isListingAddress && localEvent.listing
        ? { ...localEvent.listing?.address, id: undefined }
        : noOtherAddress
  };

  async function handleUpdateLocationAsync(locationDetails: ILocationDetails) {
    setErrorMessage(undefined);
    const newEvent: IEvent = {
      ...localEvent,
      location: {
        latitude: locationDetails.location?.latitude,
        longitude: locationDetails.location?.longitude
      },
      address: { ...locationDetails.address, id: localEvent.address?.id }
    };

    if (
      locationDetails.location?.longitude !== localEvent.location?.longitude ||
      locationDetails.location?.latitude !== localEvent.location?.latitude
    ) {
      const { data: isUnique } =
        await eventOperations.checkNameForLocationAsync(newEvent);

      if (!isUnique) {
        setErrorMessage(
          'Event location already exists for this name. Please enter a different event location.'
        );
        return;
      }
    }

    setLocalEvent(newEvent);
    const isSuccessful = await updateEventAsync(newEvent);

    if (!isSuccessful) {
      errorToast('Failed to change location.');
      setLocalEvent({
        ...newEvent,
        location: {},
        address: { id: localEvent.address?.id }
      });
      return;
    }
  }

  async function handleOnChangeAddressTypeAsync() {
    const updatedEvent = {
      ...localEvent,
      inheritAddress: !isListingAddress,
      isActive:
        localEvent.isActive &&
        localEvent.address &&
        Object.keys(localEvent.address).length > 3
    };
    setLocalEvent(updatedEvent);
    await updateEventAsync(updatedEvent);
    setIsListingAddress(!isListingAddress);
    setErrorMessage('');
  }

  function getValidationErrorMessage(errorType: ErrorType): string | undefined {
    return validationErrors.find((ve) => ve.type === errorType)?.tabMessage;
  }

  const listingOrEventLocation = isListingAddress
    ? currentEventWithLocationDetails.listing?.location
    : currentEventWithLocationDetails.location;

  const listingOrEventAddress = isListingAddress
    ? currentEventWithLocationDetails.listing?.address
    : currentEventWithLocationDetails.address;

  return (
    <div className="flex flex-col rounded-lg px-6 py-8 w-full">
      <span className="w-57 text-inter text-2xl font-bold leading-9 text-left">
        Location
      </span>

      {localEvent.listing && (
        <div className="flex font-inter text-xs font-semibold mt-6 text-black">
          <div className="flex items-center gap-2">
            <ImprovedCheckbox
              type="radio"
              onChange={handleOnChangeAddressTypeAsync}
              unselectedBorderColour={'hot'}
              checked={isListingAddress}
              selectedBackgroundColour="primary"
              selectedColour="primary"
            />
            Business Address
          </div>

          <div className="flex items-center gap-2 ml-4">
            <ImprovedCheckbox
              type="radio"
              onChange={handleOnChangeAddressTypeAsync}
              unselectedBorderColour={'hot'}
              checked={!isListingAddress}
              selectedBackgroundColour="primary"
              selectedColour="primary"
            />
            Other Address
          </div>
        </div>
      )}
      <LocationModal
        isModalOpen={isLocationModalOpen}
        setIsModalOpen={setIsLocationModalOpen}
        onConfirmLocation={handleUpdateLocationAsync}
        initialLocationDetails={{
          address: listingOrEventAddress,
          location: listingOrEventLocation
        }}
      />
      <div className="flex flex-row mt-6">
        <div className="mr-6 w-full">
          <LocationSnippet
            latitude={listingOrEventLocation?.latitude}
            longitude={listingOrEventLocation?.longitude}
            address={listingOrEventAddress}
            openLocationModal={() => setIsLocationModalOpen(true)}
            canOpenLocationModal={!isListingAddress}
          />
          {errorMessage ? (
            <div className="text-error5 font-inter font-bold text-sm">
              {errorMessage}
            </div>
          ) : (
            <div className="text-error5 font-inter font-bold text-sm">
              {getValidationErrorMessage(ErrorType.Address)}
            </div>
          )}
        </div>

        <div className="border-r-2 border-r-gray mr-6"></div>
        <span className="ext-sm font-inter" style={{ width: '295px' }}>
          If you would like to update your address, please click on the “Edit
          Address” button.
        </span>
      </div>
    </div>
  );
}

export default EventLocationTabContainer;
