import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import { IAddress } from 'Colugo/interfaces/listing/IAddress';
import ky from 'ky';
import React, { useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { useAsyncDebounce } from 'react-table';
import { APP_OPENCAGE_KEY } from 'utility/constants/constants';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import { Location } from './Map';

type Props = {
  setSelected: (
    address: Location,
    listingAddress: IAddress,
    shouldCreate: boolean,
    shouldContinue: boolean
  ) => void;
  initialValue?: string;
  isDisabled?: boolean;
  inputValue?: string;
  onClickAdditionalOption?: () => void;
  additionalOption?: string;
};

export type OpenCageResponse = {
  results: OpenCageResult[];
};

type OpenCageResult = {
  formatted: string;
  geometry: OpenCageLocation;
  components: OpenCageComponent;
};

type OpenCageLocation = {
  lat: number;
  lng: number;
};

type OpenCageComponent = {
  _normalized_city: string;
  _type: string;
  county: string;
  country: string;
  state: string;
  town: string;
  postcode: string;
  house_number: string;
  house_name: string;
  road: string;
};

export async function searchOpenCageAsync(searchQuery: string) {
  return await ky
    .get(
      `https://api.opencagedata.com/geocode/v1/json?q=${searchQuery}&key=${APP_OPENCAGE_KEY}&countrycode=gb`
    )
    .json<OpenCageResponse>();
}

export default function PlacesAutocomplete(props: Props) {
  const {
    setSelected,
    initialValue,
    inputValue,
    isDisabled,
    onClickAdditionalOption,
    additionalOption
  } = props;

  const { errorToast } = useErrorToast();
  const [suggestions, setSuggestions] = useState<
    OpenCageResult[] | undefined
  >();
  const [queryTerm, setQueryTerm] = useState<string | undefined>(initialValue);

  const debouncedSearchOpenCageAsync = useAsyncDebounce(
    searchOpenCageAsync,
    500
  );

  useEffect(() => {
    async function getSuggestionsAsync() {
      if (queryTerm && queryTerm.length > 2) {
        try {
          const response: OpenCageResponse = await debouncedSearchOpenCageAsync(
            queryTerm
          );
          setSuggestions(response.results);
        } catch {
          errorToast('Something went wrong searching. Please try again.');
        }
      }
    }
    getSuggestionsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchOpenCageAsync, queryTerm]);

  function getListingAddress(result: OpenCageResult): IAddress {
    const components = result.components;

    const type = components._type;
    return {
      houseNumber: components.house_number,
      houseName:
        components.house_name ||
        (type !== 'road' ? components[components._type] : ''),
      street: components.road,
      city: components._normalized_city,
      postcode: components.postcode,
      country: components.country,
      county: components.county,
      addressString: result.formatted
    };
  }

  async function handleSelectKeyAsync(suggestionFormatted: string) {
    const suggestion = suggestions?.find(
      (s) => s.formatted === suggestionFormatted
    );
    if (suggestion) {
      await handleSelectAsync(suggestion);
    }
  }

  async function handleSelectAsync(
    openCageResult: OpenCageResult,
    shouldCreate: boolean = false,
    shouldContinue: boolean = false
  ) {
    setSuggestions(undefined);
    setQueryTerm(undefined);

    const lat = openCageResult.geometry.lat;
    const lng = openCageResult.geometry.lng;

    const listingAddress: IAddress = getListingAddress(openCageResult);

    setSelected &&
      setSelected({ lat, lng }, listingAddress, shouldCreate, shouldContinue);
  }

  const onKeyDownAsync = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && suggestions && suggestions.length > 0) {
      await handleSelectAsync(suggestions[0], event.ctrlKey, !event.shiftKey);
    }
  };

  return (
    <Combobox
      onSelect={handleSelectKeyAsync}
      className="relative"
      style={{ width: '682px' }}
    >
      <BsSearch
        className="absolute top-6 right-1 text-2xl text-black mr-2"
        style={{ zIndex: 60 }}
      />
      <ComboboxInput
        value={inputValue || queryTerm}
        onChange={(e) => setQueryTerm(e.target.value)}
        onFocus={() => initialValue && setQueryTerm(initialValue)}
        onKeyDown={async (event) => await onKeyDownAsync(event)}
        disabled={isDisabled}
        placeholder={'Search Address...'}
        size={10}
        className={`font-nunito font-normal top-4 z-10 left-4 shadow-md rounded-md text-xl p-2 mr-6 px-6  border-2 border-gray-400`}
        style={{
          position: 'absolute',
          zIndex: 50,
          fontSize: '16px',
          lineHeight: '22.4px',
          width: '682px'
        }}
      />
      <ComboboxPopover style={{ position: 'absolute', zIndex: 100 }}>
        <ComboboxList>
          {suggestions?.map((s) => (
            <ComboboxOption key={s.geometry.lat} value={s.formatted} />
          ))}
          <div
            className="flex flex-row cursor-pointer text-primary hover:bg-gray-200 w-full pl-1 py-0.5"
            onClick={onClickAdditionalOption}
          >
            +<span className="ml-1 ">{additionalOption}</span>
          </div>
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
}
