import { ErrorPage, Modal, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { IPromotion } from 'Colugo/interfaces/promotions/IPromotion';
import { useReqListTagsByFilterGroup } from 'Colugo/operations/tags';
import { FilterGroupContext } from 'provider/filterGroups/filterGroupsProvider';
import { PromotionsContext } from 'provider/promotions/promotionsProvider';
import { useContext } from 'react';
import CreateOrEditPromotion from './CreateOrEditPromotion';

export const promotionsFilterGroupName = 'Promotions';

type Props = {
  isCreateModalOpen: boolean;
  setIsCreateModalOpen: (isCreatePromotionModalOpen: boolean) => void;
  modalTitle: string;
  listing?: IListing;
  promotion?: IPromotion;
  onCreatePromotion?: ((promotion: IPromotion) => void) | undefined;
  onEditPromotion?: (promotion: IPromotion) => void;
  isEventPromotionsTabs?: boolean;
};

function CreateOrEditPromotionModal(props: Props) {
  const {
    setIsCreateModalOpen,
    isCreateModalOpen,
    modalTitle,
    listing,
    promotion,
    onCreatePromotion,
    onEditPromotion,
    isEventPromotionsTabs = false
  } = props;

  const { promotions, mutatePromotions } = useContext(PromotionsContext);
  const { promotionsFilterGroup } = useContext(FilterGroupContext);

  const {
    data: promotionTags,
    isLoading: isPromotionTagsLoading,
    isError: isPromotionTagsError
  } = useReqListTagsByFilterGroup(promotionsFilterGroup?.id || '');

  const isInEventPromotionsTabsOrIsEditingEvent =
    !!(promotion && promotion.event) || isEventPromotionsTabs;

  function handleEditPromotion(updatedPromotion: IPromotion) {
    mutatePromotions([
      ...promotions
        .filter((p) => p.id !== updatedPromotion!.id)
        .sort((a, b) => (a.order || 0) - (b.order || 0)),
      updatedPromotion
    ]);
  }

  if (isPromotionTagsLoading) {
    return <Spinner />;
  }

  if (isPromotionTagsError) {
    return (
      <ErrorPage>
        <span>{`Error retrieving tags.`}</span>
      </ErrorPage>
    );
  }

  return (
    <div className="relative">
      <Modal
        overlay={true}
        padding="none"
        isMenuOpen={isCreateModalOpen}
        className="bg-white -mt-24"
        size="xl"
        position="fixedCenter"
        styles={{
          borderRadius: '8px 8px 8px 8px'
        }}
      >
        <CreateOrEditPromotion
          setIsModalOpen={setIsCreateModalOpen}
          modalTitle={modalTitle}
          promotionTags={promotionTags ?? []}
          entity={listing}
          onCreatePromotion={onCreatePromotion}
          onEditPromotion={
            !!onEditPromotion ? onEditPromotion : handleEditPromotion
          }
          promotion={promotion}
          isEventPromotionsTabs={isInEventPromotionsTabsOrIsEditingEvent}
        />
      </Modal>
    </div>
  );
}

export default CreateOrEditPromotionModal;
