import { InputElement } from '@bindystreet/bindystreet.kit.react/dist/components/Input';
import React, { forwardRef, useState } from 'react';

export enum ImprovedTextAreaType {
  Text = 'text',
  Password = 'password'
}

export type ImprovedTextAreaProps = {
  name?: string;
  value?: string;
  label?: string;
  labelClassName?: string;
  inputType?: ImprovedTextAreaType;
  placeHolder?: string;
  className?: string;
  wrapperClassName?: string;
  error?: string;
  isError?: boolean;
  errorClassName?: string;
  attachedComponent?: JSX.Element;
  prependJsx?: JSX.Element;
  appendJsx?: JSX.Element;
  maxCharacterCount?: number;
  //Callbacks
  onChange?: (e: React.FormEvent<InputElement>) => void;
};

export type ImprovedTextAreaRef = {};

const ImprovedTextArea = forwardRef<ImprovedTextAreaRef, ImprovedTextAreaProps>(
  (props: ImprovedTextAreaProps): JSX.Element => {
    const {
      name,
      value,
      label,
      labelClassName,
      placeHolder = 'Write text here...',
      className,
      wrapperClassName,
      error,
      isError = false,
      errorClassName,
      attachedComponent,
      prependJsx,
      appendJsx,
      maxCharacterCount,
      //Callbacks
      onChange
    } = props;

    //State
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const focusedStyle = isFocused
      ? `border-${isError ? 'red' : 'onSurface'} transition-all duration-300`
      : `border-${isError ? 'red' : 'outline'} transition-all duration-300`;
    const containerClass = wrapperClassName
      ? `flex ${focusedStyle} ${wrapperClassName}`
      : `flex flex-row w-full py-4 px-6 border rounded-lg items-center ${focusedStyle}`;

    function handleFocusToggle() {
      if (isFocused) {
        return setIsFocused(!isFocused);
      }
      setIsFocused(true);
    }

    return (
      <div className={'flex flex-col w-full gap-2'}>
        {label && <label className={`${labelClassName}`}>{label}</label>}
        <div
          className={containerClass}
          onFocus={handleFocusToggle}
          onBlur={handleFocusToggle}
        >
          {prependJsx}
          <textarea
            name={name}
            value={value}
            className={`w-full font-nunito text-base ` + className}
            placeholder={placeHolder}
            onChange={onChange}
            maxLength={maxCharacterCount}
          />
          {appendJsx}
        </div>
        <div className="flex flex-row justify-between items-center">
          {error && isError && (
            <label className={`heading-semibold-sm-red ` + errorClassName}>
              {error}
            </label>
          )}
          <div />
          {maxCharacterCount && (
            <p className="text-surfaceContainerHighest text-base text-right">
              {value?.length}/{maxCharacterCount}
            </p>
          )}
        </div>
        {attachedComponent}
      </div>
    );
  }
);

export default ImprovedTextArea;
