import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { ListingSuggestionStatus } from 'Colugo/interfaces/suggestions/ListingSuggestionStatus';
import SuggestionOperations, {
  useReqGetSuggestionAsync,
  useReqListSuggestionsAsync
} from 'Colugo/operations/suggestions/SuggestionOperations';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { formatDateForCard } from 'utility/general/formatHelpers';
import DashboardSideBarContainer from '../sidebar/DashboardSideBarContainer';
import ConfirmManageModal from './ConfirmManageModal';
import SuggestionCard from './SuggestionCard';
import { IListingSuggestionBucket } from 'Colugo/interfaces/suggestions/IListingSuggestionBucket';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import { useErrorToast } from 'utility/hooks/useErrorToast';

const suggestionOperations = container.resolve(SuggestionOperations);

type SuggestionRouteParams = {
  suggestionBucketId: string;
};

function EditSuggestionContainer() {
  const { suggestionBucketId } = useParams<SuggestionRouteParams>();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isAccepting, setIsAccepting] = useState<boolean>(true);
  const navigate = useNavigate();
  const { errorToast } = useErrorToast();

  //TODO: we should ideally move this to a redux state as as context might not suit this anymore with the introduction of roles
  const {
    data: suggestionBuckets,
    isLoading: isSuggestionsLoading,
    isError: isSuggestionsError,
    mutate: mutateSuggestions
  } = useReqListSuggestionsAsync();

  const {
    data: suggestionBucket,
    isLoading,
    isError,
    mutate
  } = useReqGetSuggestionAsync(suggestionBucketId);

  if (
    isSuggestionsLoading ||
    !suggestionBucket ||
    isLoading ||
    !suggestionBuckets
  ) {
    return (
      <div className="absolute w-full h-screen flex flex-col items-center justify-center">
        <Spinner />;
      </div>
    );
  }

  if (isSuggestionsError || isError) {
    return (
      <ErrorPage>
        <span>{'Unable to load suggestions from server.'}</span>
      </ErrorPage>
    );
  }

  async function manageSuggestionBucketAsync(
    suggestionBuckets: IListingSuggestionBucket[]
  ) {
    if (!suggestionBucketId) {
      errorToast('No suggestion bucket found, please try again.');
      return;
    }
    const { error } = await suggestionOperations.manageBucketAsync(
      suggestionBucketId,
      isAccepting
    );
    if (error) {
      errorToast('Failed to apply all suggestions, please try again.');
      return;
    }
    navigate(PuffinRoutes.SuggestionsBucket);
    const newSuggestionBuckets = suggestionBuckets.filter(
      (s) => s.id !== suggestionBucketId
    );
    const updatedSuggestionBucket = {
      ...suggestionBucket!,
      status: isAccepting
        ? ListingSuggestionStatus.Approved
        : ListingSuggestionStatus.Rejected
    };
    mutateSuggestions([...newSuggestionBuckets, updatedSuggestionBucket]);
    toast.success(`SuggestionBucket ${isAccepting ? 'accepted' : 'rejected'}.`);
  }

  async function manageSuggestionAsync(
    suggestionId: string,
    isAccepting: boolean,
    suggestionTitle: string
  ) {
    if (!suggestionId) {
      errorToast('No suggestion found, please try again.');
      return;
    }
    const { error } = await suggestionOperations.manageAsync(
      suggestionId,
      isAccepting
    );
    if (error) {
      errorToast('Failed to apply suggestion, please try again.');
      return;
    }
    toast.success(
      `${suggestionTitle} ${isAccepting ? 'accepted' : 'rejected'}.`
    );
    mutate({
      ...suggestionBucket!,
      suggestions: suggestionBucket!.suggestions.filter(
        (suggestion) => suggestion.id !== suggestionId
      )
    });
  }

  const hasSuggestions = suggestionBucket.suggestions.some(
    (s) => s.status === ListingSuggestionStatus.Reported
  );

  return (
    <div className="flex flex-row h-screen w-screen">
      <DashboardSideBarContainer />
      <div className="overflow-visible flex overflow-x-hidden overflow-y-visible bg-theme1 w-screen">
        <div className="flex flex-col pt-4 mx-auto mt-7">
          <div className="flex flex-row">
            <div className="mx-auto">
              <div className="flex flex-row w-full" style={{ width: 900 }}>
                <span className="font-bold py-4 text-2xl">
                  User Suggestions for {suggestionBucket.listing?.name}
                </span>
                <div className="flex flex-grow" />
                {hasSuggestions && (
                  <div className="flex flex-row my-3 font-nunito text-lg">
                    <div
                      className="px-5 py-2 bg-primaryTeal cursor-pointer rounded-lg text-white"
                      onClick={() => {
                        setIsConfirmModalOpen(true);
                        setIsAccepting(true);
                      }}
                    >
                      Accept All
                    </div>
                    <span
                      className="pt-2 ml-3 mr-6 cursor-pointer text-lightCharcoal"
                      onClick={() => {
                        setIsConfirmModalOpen(true);
                        setIsAccepting(false);
                      }}
                    >
                      Reject All
                    </span>
                  </div>
                )}
              </div>

              {hasSuggestions ? (
                <div>
                  <div className="w-full bg-grayBorder mt-4 border" />
                  <div className="font-bold mt-4">
                    User:{' '}
                    <span className="font-normal text-lightCharcoal">
                      {suggestionBucket.user.username}
                    </span>
                  </div>
                  <div className="font-bold">
                    Submitted:{' '}
                    <span className="font-normal text-lightCharcoal">
                      {formatDateForCard(suggestionBucket.createdAt!)}
                    </span>
                  </div>
                  <div className="w-full bg-grayBorder mt-4 border" />
                  {suggestionBucket.suggestions
                    .filter(
                      (s) => s.status === ListingSuggestionStatus.Reported
                    )
                    .map((suggestion) => (
                      <SuggestionCard
                        key={suggestion.id}
                        suggestion={suggestion}
                        listing={suggestionBucket.listing}
                        onManageAsync={(isAccepting, suggestionTitle) =>
                          manageSuggestionAsync(
                            suggestion.id || '',
                            isAccepting,
                            suggestionTitle
                          )
                        }
                      />
                    ))}
                </div>
              ) : (
                <div>
                  <span>
                    No more suggestions for this Listing by this Ember user,
                    return to your
                  </span>
                  <span
                    className="text-primaryTeal cursor-pointer hover:underline ml-1"
                    onClick={() => navigate(PuffinRoutes.SuggestionsBucket)}
                  >
                    Suggestion Notifications?
                  </span>
                </div>
              )}
              <div className="flex flex-col text-black mb-4">
                <span className="text-lg font-inter font-bold">
                  Other comments
                </span>
                <span>
                  {' '}
                  {suggestionBucket.comment ||
                    'The user has not left any further comments'}
                </span>
              </div>
            </div>
          </div>
          <ConfirmManageModal
            isConfirmModalOpen={isConfirmModalOpen}
            setIsConfirmModalOpen={setIsConfirmModalOpen}
            onConfirm={async () =>
              await manageSuggestionBucketAsync(suggestionBuckets)
            }
            isAccepting={isAccepting}
          />
        </div>
      </div>
    </div>
  );
}

export default EditSuggestionContainer;
