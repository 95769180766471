/* ISC License (ISC). Copyright 2017 Michal Zalecki */
function storageFactory(storage: Storage) {
  let inMemoryStorage: { [key: string]: string } = {};
  const length = 0;

  function isSupported() {
    try {
      const testKey = '__some_random_key_you_are_not_going_to_use__';
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  function clear(): void {
    if (isSupported()) {
      storage.clear();
    } else {
      inMemoryStorage = {};
    }
  }

  function getItem(name: string): string | null {
    if (isSupported()) {
      return storage.getItem(name);
    }
    if (inMemoryStorage.hasOwnProperty(name)) {
      return inMemoryStorage[name];
    }
    return null;
  }

  function getItemAsObject(name: string): any | null {
    if (isSupported()) {
      try {
        let item = storage.getItem(name);
        if (item) {
          return JSON.parse(item);
        }
      } catch (ex) {
        console.error(ex);
        return null;
      }
      return null;
    }
  }

  function key(index: number): string | null {
    if (isSupported()) {
      return storage.key(index);
    } else {
      return Object.keys(inMemoryStorage)[index] || null;
    }
  }

  function removeItem(name: string): void {
    if (isSupported()) {
      storage.removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  }

  function setItem(name: string, value: any): void {
    const stringValue =
      typeof value === 'string' ? value : JSON.stringify(value);

    if (isSupported()) {
      storage.setItem(name, stringValue);
    } else {
      inMemoryStorage[name] = stringValue;
    }
  }

  return {
    getItemAsObject,
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    length
  };
}

const localStore = storageFactory(localStorage);
export default localStore;
