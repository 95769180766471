function DashboardFooter() {
  return (
    <div className="pb-6 mt-1">
      <div className="bottom-6 w-full left-0 right-0 flex flex-row items-center justify-center">
        <div className="flex flex-row justify-between text-sm font-inter font-normal">
          <span>{`© ${new Date().getFullYear()} Bindy Street Limited.`}</span>
          <a
            href="https://www.ember.london/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="hover:text-primaryTeal ml-1 underline cursor-pointer"
          >
            Privacy
          </a>
          <span className="ml-1">and</span>
          <a
            href="https://www.ember.london/terms-of-service"
            target="_blank"
            rel="noreferrer"
            className="hover:text-primaryTeal ml-1 underline cursor-pointer"
          >
            Terms
          </a>
        </div>
      </div>
    </div>
  );
}

export default DashboardFooter;
