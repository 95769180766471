import { IPromotion } from 'Colugo/interfaces/promotions/IPromotion';
import { useRef, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { FaCog } from 'react-icons/fa';
import { FaPen } from 'react-icons/fa6';
import { formatDate } from 'utility/general/formatHelpers';
import EntityImage from './EntityImage';
import ToggleIsActiveComponent from './ToggleIsActiveComponent';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { ITag } from 'Colugo/interfaces/tags';
import { RiMoneyPoundBoxFill } from 'react-icons/ri';
import { useOutsideClick } from '@bindystreet/bindystreet.kit.react';

type Props = {
  promotion: IPromotion;
  listing?: IListing;
  onClickDelete: () => void;
  onClickEdit: () => void;
  onClickListingIcon?: () => void;
  tags?: ITag[];
  width: string;
  canDrag?: boolean;
};

function PromotionCard(props: Props) {
  const {
    promotion,
    listing,
    onClickDelete,
    onClickEdit,
    onClickListingIcon,
    tags,
    width,
    canDrag = false
  } = props;

  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setIsSettingsOpen(false));

  function cardTop() {
    return (
      <div
        className="py-6 px-4"
        style={{ height: '107px' }}
        onClick={onClickEdit}
      >
        <div className="font-bold text-lg truncate flex flex-row rounded">
          <RiMoneyPoundBoxFill
            className="rounded mr-0.5"
            size={28}
            style={{ color: '#EF8088' }}
          />
          {promotion.title ||
            (tags || []).find((t) => t.id === promotion.tags[0].id)?.name}
        </div>
        <div className="text-l h-12 overflow-hidden">{promotion.details}</div>
      </div>
    );
  }

  function cardBottom() {
    return (
      <div
        className="flex flex-row items-center border-black border-opacity-10 border-t-2 w-full px-2"
        style={{ height: '56px' }}
      >
        {listing && (
          <div
            className="w-40 border-r-2 border-black border-opacity-10 truncate pt-2 text-black font-inter font-bold"
            style={{ height: '56px', width: '232px' }}
            onClick={onClickListingIcon}
          >
            <div className="hover:bg-gray-300 flex flex-row py-1 my-1 rounded mr-2">
              <div style={{ width: 34 }} className="ml-1.5">
                <EntityImage entity={promotion.listing!} imageProportion={24} />
              </div>
              <div className="truncate" style={{ width: 120 }}>
                {promotion.listing?.name}
              </div>
            </div>
          </div>
        )}
        <div
          className="flex flex-row items-center pl-3 relative w-full pr-2"
          style={{ height: '56px' }}
        >
          <ToggleIsActiveComponent isActive={promotion.isActive} />
          {promotion.duration?.startDate && (
            <div className="font-normal text-xs ml-2 text-secondaryText">
              {`From ${formatDate(promotion.duration?.startDate!)}`}
            </div>
          )}

          <div className="flex-grow" onClick={onClickEdit} />
          <FaCog
            className="text-secondaryText cursor-pointer"
            size={16}
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
          />
          {isSettingsOpen && (
            <div
              className="absolute bg-white rounded shadow-md bottom-10 right-0 border p-1 text-sm z-10"
              style={{ width: 192, height: 80 }}
              ref={ref}
            >
              <div
                className="flex flex-row w-full hover:bg-error5 hover:text-white px-1 rounded pb-1 cursor-pointer"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => {
                  onClickDelete();
                  setIsSettingsOpen(false);
                }}
              >
                <AiFillDelete
                  className={`ml-2 ${
                    isHover ? 'text-white' : 'text-primaryCharcoal'
                  } mt-2`}
                  size={14}
                />
                <div className="ml-2 pt-1.5">Delete</div>
              </div>
              <div className=" border-b-2 mt-1"></div>
              <div
                className="flex flex-row w-full mt-1 py-1 pl-1 cursor-pointer rounded hover:bg-gray-300"
                onClick={() => {
                  onClickEdit();
                  setIsSettingsOpen(false);
                }}
              >
                <FaPen className="ml-2 text-primaryCharcoal mt-1" size={12} />
                <div className="ml-2 pl-0.5">Edit</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-row bg-white border rounded-xl border-grayBorder hover:shadow-lg transition-all duration-200 ${
        canDrag ? 'cursor-grab' : 'cursor-pointer'
      }`}
      style={{ width: width }}
      onClick={() => isSettingsOpen && onClickEdit()}
    >
      <div className="flex flex-col font-nunito w-full">
        {cardTop()}
        {cardBottom()}
      </div>
    </div>
  );
}

export default PromotionCard;
