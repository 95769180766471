import { ReactComponent as ActiveEllipse } from 'images/shared/activeEllipse.svg';
import { ReactComponent as InactiveEllipse } from 'images/shared/inactiveEllipse.svg';

type Props = {
  isActive?: boolean;
};

function ToggleIsActiveComponent(props: Props) {
  const { isActive } = props;
  return (
    <div
      className={`flex flex-row items-center  ${
        isActive ? 'text-green' : 'text-red'
      }`}
    >
      {!!isActive ? <ActiveEllipse /> : <InactiveEllipse />}
      <h6 className="text-xs ml-1 font-normal font-inter pt-0.5">
        {isActive ? 'Active' : 'Inactive'}
      </h6>
    </div>
  );
}

export default ToggleIsActiveComponent;
