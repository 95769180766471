export enum ListingField {
  None,
  OpeningTimes,
  ActionLink,
  WebsiteLink,
  Facebook,
  Instagram,
  Twitter,
  TikTok,
  ContactNumber
}
