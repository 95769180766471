import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IUser } from 'Colugo/interfaces/identity';
import { useReqGetListingAsync } from 'Colugo/operations/listings/ListingOperations';
import DashboardFooter from 'components/dashboard/DashboardFooter';
import ProfileModal from 'components/dashboard/ProfileModal';
import AccountNameProfileButton from 'components/shared/AccountNameProfileButton';
import CustomFormHeader from 'components/shared/CustomFormHeader';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditListingEditor from './EditListingEditor';
import { UserContext } from 'provider/user/userProvider';

type ListingRouteParams = {
  listingId: string;
};

function EditListingContainer() {
  const { listingId } = useParams<ListingRouteParams>();
  const { isSuperAdmin } = useContext(UserContext);

  const navigate = useNavigate();
  const [businessUser, setBusinessUser] = useState<IUser | undefined>(
    undefined
  );

  const {
    data: listing,
    isLoading,
    isError,
    mutate
  } = useReqGetListingAsync(listingId);

  if (isError) {
    return (
      <ErrorPage>
        <span>{'Unable to load listing from server.'}</span>
      </ErrorPage>
    );
  }

  if (!listing || isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col w-screen h-screen bg-theme1">
      <CustomFormHeader
        title={listing.name}
        onPressClose={() => navigate('/')}
        onPressComponent={
          isSuperAdmin ? (
            <AccountNameProfileButton
              item={listing}
              setBusinessUser={setBusinessUser}
            />
          ) : undefined
        }
      />
      <div className="flex flex-col w-screen h-screen overflow-y-auto">
        {!!businessUser && (
          <ProfileModal
            businessUser={businessUser}
            onCloseModal={() => setBusinessUser(undefined)}
          />
        )}
        <div>
          <EditListingEditor listing={listing} mutate={mutate} />
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
}

export default EditListingContainer;
