import { useEffect } from 'react';

export default function NotFound() {
  useEffect(() => {
    window.location.reload();
  });
  return (
    <div className="bg-primary text-white h-screen flex w-full justify-center items-center text-4xl">
      NOT FOUND
    </div>
  );
}
