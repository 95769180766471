import { IBase } from 'Colugo/interfaces';
import { IGame } from '../games';
import { ITag } from '../tags';

export interface ICategory extends IBase {
  name: string;
  key: string;
  colour?: string;
  order?: number;
  discoverPageOrder?: number;
  discoverPageSize?: LobbyCategorySize;
  games?: IGame[];
  tags?: ITag[];
}

export interface ICategoryUserView extends ICategory {
  isGuideOrItemInCategory?: boolean;
}

export function convertCategoryUserViewToCategory(
  categoryUserView: ICategoryUserView
): ICategory {
  const category: ICategory = {
    id: categoryUserView.id,
    name: categoryUserView.name,
    key: categoryUserView.key,
    colour: categoryUserView.colour,
    order: categoryUserView.order,
    games: categoryUserView.games,
    tags: categoryUserView.tags
  };

  return category;
}

export enum CategoryKey {
  AnimalsNature = 'AnimalsNature',
  Culture = 'Culture',
  Activities = 'Activities',
  FoodDrink = 'FoodDrink',
  SportHealth = 'SportHealth',
  Hobbies = 'Hobbies'
}

export enum LobbyCategorySize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large'
}
