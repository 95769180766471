import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IPromotion } from 'Colugo/interfaces/promotions/IPromotion';
import PromotionOperations from 'Colugo/operations/promotions/PromotionOperations';
import { useReqListTagsByFilterGroup } from 'Colugo/operations/tags';
import ConfirmationPopup from 'components/shared/ConfirmationPopup';
import PromotionCard from 'components/shared/PromotionCard';
import { FilterGroupContext } from 'provider/filterGroups/filterGroupsProvider';
import { PromotionsContext } from 'provider/promotions/promotionsProvider';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';

export const promotionsFilterGroupName = 'Promotions';
const promotionOperations = container.resolve(PromotionOperations);

type Props = {
  setIsCreatePromotionModalOpen: (isCreatePromotionModalOpen: boolean) => void;
  promotionIdToDelete: string | undefined;
  setPromotionToEdit: (promotionToEdit: IPromotion | undefined) => void;
  setPromotionIdToDelete: (promotionIdToDelete: string | undefined) => void;
};

function RecentPromotionsEditor(props: Props) {
  const {
    promotionIdToDelete,
    setPromotionIdToDelete,
    setPromotionToEdit,
    setIsCreatePromotionModalOpen
  } = props;

  const { errorToast } = useErrorToast();
  const { promotions } = useContext(PromotionsContext);
  const { promotionsFilterGroup } = useContext(FilterGroupContext);
  const [localPromotions, setLocalPromotions] =
    useState<IPromotion[]>(promotions);

  const navigate = useNavigate();

  const {
    data: tags,
    isLoading: isTagsLoading,
    isError: isTagsError
  } = useReqListTagsByFilterGroup(promotionsFilterGroup?.id || '');

  useEffect(() => {
    if (!!promotions && !isTagsError) {
      setLocalPromotions(
        promotions.map((promotion) => {
          //TODO use promotion title here when doing this https://jirabindystreet.atlassian.net/browse/APP-1043
          promotion.type =
            tags?.find((t) => t.id === promotion.tags[0].id)?.name || '';
          return promotion;
        })
      );
    }
  }, [promotions, tags, isTagsError]);

  if (isTagsError) {
    return (
      <ErrorPage>
        <span>{`Error retrieving tags.`}</span>
      </ErrorPage>
    );
  }

  if (isTagsLoading) {
    return (
      <div className="absolute w-full h-full">
        <Spinner expand />
      </div>
    );
  }

  if (!promotionsFilterGroup || !promotionsFilterGroup.id) {
    return (
      <ErrorPage>
        <span>{`There is no filter group named "${promotionsFilterGroupName}". This is required to create a promotion.`}</span>
      </ErrorPage>
    );
  }
  if (!tags || tags.length === 0) {
    return (
      <ErrorPage>
        <span>{`Unable to load tags for filter group named "${promotionsFilterGroupName}".`}</span>
      </ErrorPage>
    );
  }

  function openCreatePromotionModal(promotion?: IPromotion) {
    if (promotionIdToDelete) {
      return;
    }
    setIsCreatePromotionModalOpen(true);
    setPromotionToEdit(promotion);
  }

  async function handleDeletePromotionAsync() {
    const promotionToBeRemoved = localPromotions.find(
      (p) => p.id === promotionIdToDelete
    );
    if (!promotionIdToDelete || !promotionToBeRemoved) {
      errorToast('Cannot find promotion, please try again.');
      return;
    }

    setLocalPromotions(
      localPromotions.filter((p) => p.id !== promotionIdToDelete)
    );

    setPromotionIdToDelete(undefined);

    const { error } = await promotionOperations.deleteAsync(
      promotionIdToDelete
    );

    if (error) {
      errorToast('Failed to delete promotion, please try again');
      setLocalPromotions(localPromotions);
      return false;
    }
  }

  return (
    <div className="flex flex-col pt-12" style={{ width: '672px' }}>
      <div className=" flex flex-row w-full py-1">
        <div
          className={`font-inter font-bold text-2xl`}
          style={{ lineHeight: '27px', fontSize: '18px' }}
        >
          Recently edited promotions
        </div>
        <div className="flex-grow" />
      </div>
      <div className="flex flex-row flex-wrap mt-1">
        {localPromotions?.slice(0, 2).map((promotion, i) => (
          <div key={i} className="pt-2">
            <PromotionCard
              promotion={promotion}
              listing={promotion.listing}
              onClickDelete={() => setPromotionIdToDelete(promotion.id)}
              onClickEdit={() => openCreatePromotionModal(promotion)}
              width="672px"
              onClickListingIcon={() =>
                promotion.listing &&
                navigate(`/listing/${promotion.listing.id}/general`)
              }
            />
          </div>
        ))}
      </div>
      {!!promotionIdToDelete && (
        <ConfirmationPopup
          isModalOpen={!!promotionIdToDelete}
          handleClickConfirmButton={async () =>
            await handleDeletePromotionAsync()
          }
          closeModal={() => setPromotionIdToDelete(undefined)}
          popupLabel={`Delete this Promotion?`}
          confirmButtonText="Delete Promotion"
          isErrorButton={true}
        >
          <div className="font-nunito text-primaryCharcoal font-normal text-base">
            If a Promotion is permanently deleted, it cannot be undone. Are you
            sure you want to delete this Promotion?
          </div>
        </ConfirmationPopup>
      )}
    </div>
  );
}

export default RecentPromotionsEditor;
