import {
  ActionLinkType,
  EventType,
  IEvent
} from 'Colugo/interfaces/event/IEvent';
import {
  linkType,
  linkUrlType
} from 'Colugo/interfaces/games/blocks/IExternalLinkBlockItem';
import EventOperations from 'Colugo/operations/events/EventOperations';
import ListingOperations from 'Colugo/operations/listings/ListingOperations';
import { EnumHelper } from 'Colugo/utility/helpers';
import { container } from 'tsyringe';
import { validateEvent } from 'utility/general/validators';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import ImportContainer from './ImportContainer';

const eventOperations = container.resolve(EventOperations);
const listingOperations = container.resolve(ListingOperations);

enum ColumnnHeader {
  ListingId,
  Name,
  Description,
  Location,
  InheritAddress,
  Category1,
  Category2,
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Type,
  StartDate,
  EndDate,
  OpeningTime,
  ClosingTime,
  TimeExplanation,
  ActionLinkType,
  ActionLinkUrl,
  Website,
  ContactNumber,
  FacebookLink,
  InstagramLink,
  TwitterLink,
  TikTokLink,
  PriorityTag1,
  PriorityTag2,
  PriorityTag3,
  Tag1,
  Tag2,
  Tag3,
  Tag4,
  Tag5
}

function ImportEventContainer() {
  const { errorToast } = useErrorToast();
  async function getEventFromDataAsync(
    parsedColumns: string[],
    getActionLinkType: (linkString: string) => ActionLinkType
  ): Promise<IEvent | undefined> {
    const event: IEvent = {
      name: parsedColumns[ColumnnHeader.Name].trim(),
      description: parsedColumns[ColumnnHeader.Description],
      isActive: true,
      inheritAddress: parsedColumns[ColumnnHeader.InheritAddress] === 'checked',
      images: parsedColumns
        .slice(ColumnnHeader.Image1, ColumnnHeader.Image5 + 1)
        .filter((c) => c !== ''),
      type:
        parsedColumns[ColumnnHeader.Type] === 'Single Date'
          ? EventType.SingleDate
          : EventType.DateRange,

      startDate: parseDate(parsedColumns[ColumnnHeader.StartDate]),
      endDate: parseDate(parsedColumns[ColumnnHeader.EndDate]),
      time: {
        isExplanationOnly: !!parsedColumns[ColumnnHeader.TimeExplanation],
        timeExplanation: parsedColumns[ColumnnHeader.TimeExplanation],
        times: {
          times: [
            {
              openingTime: parsedColumns[ColumnnHeader.OpeningTime],
              closingTime: parsedColumns[ColumnnHeader.ClosingTime]
            }
          ]
        }
      },
      contact: parsedColumns[ColumnnHeader.ContactNumber].trim(),
      social: {
        website: parsedColumns[ColumnnHeader.Website],
        actionLinkType: getActionLinkType(
          parsedColumns[ColumnnHeader.ActionLinkType]
        ),
        actionLinkUrl: parsedColumns[ColumnnHeader.ActionLinkUrl],
        socialLinks: [
          {
            type: linkType.url,
            urlType: linkUrlType.Facebook,
            value: parsedColumns[ColumnnHeader.FacebookLink]
          },
          {
            type: linkType.url,
            urlType: linkUrlType.Instagram,
            value: parsedColumns[ColumnnHeader.InstagramLink]
          },
          {
            type: linkType.url,
            urlType: linkUrlType.Twitter,
            value: parsedColumns[ColumnnHeader.TwitterLink]
          },
          {
            type: linkType.url,
            urlType: linkUrlType.TikTok,
            value: parsedColumns[ColumnnHeader.TikTokLink]
          }
        ]
      }
    };

    if (parsedColumns[ColumnnHeader.ListingId]) {
      const { data: listing, error } = await listingOperations.getAsync(
        parsedColumns[ColumnnHeader.ListingId]
      );

      if (listing?.isActive === false) {
        errorToast(`Listing for event: ${event.name} is inactive`);
        return undefined;
      }

      if (error || !listing) {
        if (error?.status === 404) {
          errorToast(
            `No listing exists for listing ID with event: ${event.name}`
          );
          return undefined;
        }
        errorToast(`Unable to parse event: ${event.name}`);
        console.error(error);
        return undefined;
      }

      event.listing = listing;
    }

    return event;
  }

  function parseDate(date?: string) {
    const dates = date?.split('/') || [];
    const day = dates[0];
    const month = dates[1];
    const year = dates[2];

    return year && month ? year + '/' + month + '/' + day : undefined;
  }

  const columnHeaderStrings: string[] = EnumHelper.getEnumValuesForFlyout(
    ColumnnHeader
  ).map((fmi) => fmi.label);

  return (
    <ImportContainer
      columnHeaders={columnHeaderStrings}
      entityString="Event"
      locationIndex={ColumnnHeader.Location}
      priorityTag1Index={ColumnnHeader.PriorityTag1}
      priorityTag2Index={ColumnnHeader.PriorityTag2}
      priorityTag3Index={ColumnnHeader.PriorityTag3}
      tag1Index={ColumnnHeader.Tag1}
      tag2Index={ColumnnHeader.Tag2}
      tag3Index={ColumnnHeader.Tag3}
      tag4Index={ColumnnHeader.Tag4}
      tag5Index={ColumnnHeader.Tag5}
      nameIndex={ColumnnHeader.Name}
      category1Index={ColumnnHeader.Category1}
      category2Index={ColumnnHeader.Category2}
      validateEntity={validateEvent}
      getEntityAsync={getEventFromDataAsync}
      createForCsvUploadAsync={async (events: IEvent[]) =>
        await eventOperations.createForCsvUploadAsync(events)
      }
      checkNameForLocationAsync={async (event) =>
        await eventOperations.checkNameForLocationAsync(event)
      }
    />
  );
}

export default ImportEventContainer;
