import '@reach/combobox/styles.css';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  DEFAULT_ZOOM_LEVEL,
  defaultLatitude,
  defaultLongitude
} from 'utility/constants/constants';
import PlacesAutocomplete from './PlacesAutocomplete';
import { IAddress } from 'Colugo/interfaces/listing/IAddress';
type Props = {
  locationName?: string;
  markerLongitude?: number;
  markerLatitude?: number;
  initialValue?: string;
  shouldDropMarker?: boolean;
  shouldMoveMap?: boolean;
  zoom?: number;
  canDraw?: boolean;
  mapLatitude?: number;
  mapLongitude?: number;
  height?: number;
  onSearchComplete: (
    newLongitude: number,
    newLatitude: number,
    address: IAddress,
    shouldCreate: boolean,
    shouldContinue: boolean
  ) => void;
  shouldShowMarker: boolean;
  isDisabled?: boolean;
  inputValue?: string;
  shouldShowAutocomplete?: boolean;
  isDraggableAndScrollable?: boolean;
  panToMoveMarker?: boolean;
};

export interface Location {
  lat: number;
  lng: number;
  label?: string;
  id?: string;
  colour?: string;
}

export default function Map(props: Props) {
  const {
    locationName,
    markerLongitude = defaultLongitude,
    markerLatitude = defaultLatitude,
    mapLatitude = defaultLatitude,
    mapLongitude = defaultLongitude,
    initialValue,
    shouldDropMarker = true,
    shouldMoveMap = true,
    zoom = DEFAULT_ZOOM_LEVEL,
    canDraw,
    height,
    isDisabled = false,
    onSearchComplete,
    shouldShowMarker,
    inputValue,
    shouldShowAutocomplete = true,
    isDraggableAndScrollable = true
  } = props;

  const initialMapCenter = useMemo(
    () => ({ lat: mapLatitude, lng: mapLongitude }),
    [mapLatitude, mapLongitude]
  );
  const [markerLocation, setMarkerLocation] = useState<Location>({
    lat: markerLatitude,
    lng: markerLongitude
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const [mapCenter, setMapCenter] = useState<Location>();

  useEffect(() => {
    if (markerLatitude || markerLongitude) {
      setMarkerLocation({ lat: markerLatitude, lng: markerLongitude });
      setMapCenter({ lat: markerLatitude, lng: markerLongitude });
    }
  }, [markerLatitude, markerLongitude]);

  const options: google.maps.MapOptions = {
    draggable: isDraggableAndScrollable,
    scrollwheel: true,
    keyboardShortcuts: false,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: false,
    streetViewControl: false,
    clickableIcons: false,
    disableDoubleClickZoom: true,
    draggableCursor: canDraw ? 'crosshair' : 'grab'
  };

  const onSelectDropDownLocation = (
    address: Location,
    listingAddress: IAddress,
    shouldCreate: boolean,
    shouldContinue: boolean
  ) => {
    shouldDropMarker && setMarkerLocation(address);
    shouldMoveMap && setMapCenter(address);
    onSearchComplete &&
      onSearchComplete(
        address.lng,
        address.lat,
        listingAddress,
        shouldCreate,
        shouldContinue
      );
  };

  const handleMapLoad = (map: google.maps.Map | null) => {
    mapRef.current = map;
  };

  function handleDrag() {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter();
      if (newCenter) {
        setMarkerLocation({ lat: newCenter.lat(), lng: newCenter.lng() });
      }
    }
  }

  function handleDragEnd() {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter();
      if (newCenter) {
        onSearchComplete(
          markerLocation.lng,
          markerLocation.lat,
          {},
          false,
          false
        );
      }
    }
  }

  return (
    <div className="w-full h-full relative">
      {shouldShowAutocomplete && (
        <div>
          <PlacesAutocomplete
            setSelected={onSelectDropDownLocation}
            initialValue={initialValue}
            isDisabled={isDisabled}
            inputValue={inputValue}
          />
        </div>
      )}
      <GoogleMap
        mapContainerClassName="w-full h-full"
        zoom={zoom}
        mapContainerStyle={{
          height: height
        }}
        center={mapCenter || initialMapCenter}
        options={options}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        onLoad={handleMapLoad}
      >
        {markerLocation && shouldShowMarker && (
          <Marker position={markerLocation} draggable={false} />
        )}
      </GoogleMap>
      <div className="h-10">{locationName}</div>
    </div>
  );
}
