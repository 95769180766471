import { IAbstractBlockItem } from './index';
import { IModelReference } from 'Colugo/interfaces/index';
import { v4 as uuidv4 } from 'uuid';
import { BlockItemTypeEnum } from './IAbstractBlockItem';
import { ActionLinkType } from 'Colugo/interfaces/event/IEvent';

//Will need block item type
export default interface IExternalLinkBlockItem extends IAbstractBlockItem {
  links?: ILinkItem[];
}

export interface ILinkItem extends IModelReference {
  name?: string;
  icon?: string;
  image?: string;
  text?: string;
  value?: string;
  urlType?: linkUrlType;
  actionLinkType?: ActionLinkType;
  type?: linkType;
}

export enum linkType {
  url = 1,
  phoneNumber = 2,
  email = 3
}

export enum linkUrlType {
  None = 0,
  Facebook = 1,
  Twitter = 2,
  Instagram = 3,
  TikTok = 4
}

export const getLinkBlockItem = (): IExternalLinkBlockItem => ({
  $type: BlockItemTypeEnum.Link,
  id: uuidv4(),
  centre: { x: 0, y: 0 },
  size: { x: 0, y: 0 }
});

export const getLinkItem = (): ILinkItem => ({
  id: uuidv4()
});
