import { IUser } from 'Colugo/interfaces/identity';
import { useReqGetUser } from 'Colugo/operations/identity';
import DashboardSidebar from 'components/sidebar/DashboardSidebar';
import { AuthContext } from 'provider/auth/authProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as firebaseAnalytics from 'utility/analytics/firebaseAnalytics';

function DashboardSideBarContainer() {
  const { handleSignout, token, setToken } = useContext(AuthContext);
  const { setUser } = useContext(UserContext);

  const { data: currentUser } = useReqGetUser(!!token);
  const navigate = useNavigate();

  async function handleOnSignOutAsync(currentUser: IUser) {
    const isSignOutSuccessful = handleSignout();
    if (isSignOutSuccessful) {
      setUser(undefined);
      setToken(null);
      firebaseAnalytics.signOutEvent(currentUser.email);
      navigate('/');
    }
  }

  return (
    <DashboardSidebar
      onClickSignout={() => handleOnSignOutAsync(currentUser!)}
    />
  );
}

export default DashboardSideBarContainer;
