import { ILocationDetails } from 'Colugo/interfaces/games/ILocationDetails';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { NameLocationResult } from 'Colugo/interfaces/NameLocationResult';
import ListingOperations from 'Colugo/operations/listings/ListingOperations';
import ImprovedSwitch from 'components/shared/ImprovedSwitch';
import LocationModal from 'components/shared/LocationModal';
import LocationSnippet from 'components/shared/LocationSnippet';
import { useState } from 'react';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';

const listingOperations = container.resolve(ListingOperations);

type Props = {
  localListing: IListing;
  setLocalListing: (localListing: IListing) => void;
  updateListingAsync: (updatedListing: IListing) => Promise<boolean>;
};

function LocationTabContainer(props: Props) {
  const { localListing, setLocalListing, updateListingAsync } = props;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const { errorToast } = useErrorToast();
  const [useAddress, setUseAddress] = useState<boolean>(
    localListing.location !== undefined
  );
  const [isLocationModalOpen, setIsLocationModalOpen] =
    useState<boolean>(false);

  async function handleUpdateLocationAsync(locationDetails: ILocationDetails) {
    setErrorMessage(undefined);
    const newListing: IListing = {
      ...localListing,
      location: {
        latitude: locationDetails.location?.latitude,
        longitude: locationDetails.location?.longitude
      },
      address: { ...locationDetails.address, id: localListing.address?.id }
    };

    await checkAndUpdateListingLocationAsync(
      newListing,
      'Listing location already exists for this name. Please enter a different listing location.'
    );
  }

  async function checkAndUpdateListingLocationAsync(
    newListing: IListing,
    errorMessage: string
  ) {
    if (
      localListing.location?.longitude !== newListing.location?.longitude ||
      localListing.location?.latitude !== newListing.location?.latitude
    ) {
      const { data: nameLocationResult } =
        await listingOperations.checkNameLocationAsync(newListing);

      if (nameLocationResult !== NameLocationResult.Unique) {
        setErrorMessage(errorMessage);
        return;
      }
    }

    setLocalListing(newListing);
    const isSuccessful = await updateListingAsync(newListing);

    if (!isSuccessful) {
      errorToast('Failed to change location.');
      setLocalListing({
        ...newListing,
        location: {},
        address: { id: localListing.address?.id }
      });
      return;
    }
  }

  async function onSwitchAddressAsync() {
    if (useAddress && (!!localListing.location || !!localListing.address)) {
      if (localListing.events?.some((e) => e.inheritAddress === true)) {
        errorToast(
          'Unable to remove business address because event exists that inherits this address'
        );
        return;
      }
      setErrorMessage(undefined);
      const newListing: IListing = {
        ...localListing,
        location: {},
        address: { id: localListing.address?.id }
      };

      await checkAndUpdateListingLocationAsync(
        newListing,
        'Business already exists for this name without a location.'
      );
    }
    setUseAddress(!useAddress);
  }

  return (
    <div className="flex flex-col rounded-lg px-6 py-8 w-full">
      <span className="w-57 text-inter text-2xl font-bold leading-9 text-left">
        Location
      </span>
      <LocationModal
        isModalOpen={isLocationModalOpen}
        setIsModalOpen={setIsLocationModalOpen}
        onConfirmLocation={handleUpdateLocationAsync}
        initialLocationDetails={{
          address: localListing?.address,
          location: localListing.location
        }}
      />
      <div className="flex flex-row mt-6">
        <div className="mr-6 w-full">
          <div className="flex flex-row mb-2">
            <ImprovedSwitch
              checked={useAddress}
              onChange={async () => await onSwitchAddressAsync()}
            />
            <p className="font-inter text-onSurface font-bold text-base ml-2 -mt-0.5">
              Business Address
            </p>
          </div>
          {useAddress ? (
            <LocationSnippet
              latitude={localListing.location?.latitude}
              longitude={localListing.location?.longitude}
              address={localListing.location ? localListing.address : {}}
              openLocationModal={() => setIsLocationModalOpen(true)}
            />
          ) : (
            <span className="text-onSurfaceVariant">
              Not having a location will make it harder for users to find your
              business on the map.
            </span>
          )}
          {errorMessage && (
            <div className="text-error5 font-inter font-bold text-sm">
              {errorMessage}
            </div>
          )}
        </div>
        <div className="border-r-2 border-r-gray mr-6"></div>
        <span className="ext-sm font-inter" style={{ width: '295px' }}>
          If you would like to update your address, please click on the “Edit
          Address” button.
        </span>
      </div>
    </div>
  );
}

export default LocationTabContainer;
