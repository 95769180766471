import { IAddress } from 'Colugo/interfaces/listing/IAddress';
import GoogleMapListingLocationContainer from 'components/listings/location/GoogleMapListingLocationContainer';
import ImprovedInput from 'components/shared/ImprovedInput';
import { AiFillPlusCircle } from 'react-icons/ai';
import { FaMapMarkerAlt, FaRegEdit } from 'react-icons/fa';
import { formatAddress } from 'utility/general/formatHelpers';
import { GiPadlock } from 'react-icons/gi';

type Props = {
  latitude: number | undefined;
  longitude: number | undefined;
  address: IAddress | null | undefined;
  openLocationModal: () => void;
  error?: string;
  canOpenLocationModal?: boolean;
};

export default function LocationSnippet(props: Props) {
  const {
    latitude,
    longitude,
    address,
    openLocationModal,
    error,
    canOpenLocationModal = true
  } = props;
  const hasAddress =
    !!address &&
    !!(address.postcode || address.addressString || address.houseName);

  return (
    <div>
      {latitude && longitude && (
        <GoogleMapListingLocationContainer
          onLocationChange={() => undefined}
          latitude={latitude}
          longitude={longitude}
          shouldShowAutocomplete={false}
          isDraggableAndScrollable={false}
        />
      )}
      <div className="mt-4" />
      <ImprovedInput
        className="h-full py-4 focus:outline-none bg-white cursor-pointer text-onSurface"
        wrapperClassName={`border rounded-lg bg-white flex-row items-center focus:border-onSurface px-6 ${
          canOpenLocationModal && 'hover:shadow-lg cursor-pointer'
        }`}
        value={address ? formatAddress(address) : ''}
        placeHolder=""
        disabled
        error={error}
        isError={Boolean(error)}
        onContainerClick={canOpenLocationModal ? () =>  openLocationModal() :undefined}
        prependJsx={
          <div
            className="flex flex-col items-center h-full justify-center cursor-pointer"
            onClick={() => canOpenLocationModal && openLocationModal()}
          >
            <div
              className={`flex flex-row h-full justify-center pr-2 text-onSurfaceVariant 
                ${!hasAddress && canOpenLocationModal && 'w-48'}
              `}
            >
              <FaMapMarkerAlt size="1.33em" />
              {!hasAddress && canOpenLocationModal && (
                <span className="text-onSurface ml-2 text-md w-full ">
                  Add Address...
                </span>
              )}
            </div>
          </div>
        }
        appendJsx={
          <div className="flex-col h-full cursor-pointer">
            <div onClick={() => canOpenLocationModal && openLocationModal()}>
              {canOpenLocationModal ? (
                hasAddress ? (
                  <FaRegEdit size={20} className="text-onSurface" />
                ) : (
                  <AiFillPlusCircle size={22} className="ml-2 text-primary" />
                )
              ) : (
                <GiPadlock className="cursor-not-allowed" />
              )}
            </div>
          </div>
        }
      />
    </div>
  );
}
