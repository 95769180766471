import { Input } from '@bindystreet/bindystreet.kit.react';
import { IIoiValidationError } from 'Colugo/interfaces/IIoiValidationError';
import { NameLocationResult } from 'Colugo/interfaces/NameLocationResult';
import { ICategoryUserView } from 'Colugo/interfaces/common';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import ListingOperations from 'Colugo/operations/listings/ListingOperations';
import ConfirmationPopup from 'components/shared/ConfirmationPopup';
import { produce } from 'immer';
import { useMemo, useState } from 'react';
import { FaSave } from 'react-icons/fa';
import CategorySelector from 'storybook/CategorySelector';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';

const listingOperations = container.resolve(ListingOperations);

type Props = {
  localListing: IListing;
  setLocalListing: (localListing: IListing) => void;
  updateListingAsync: (updatedListing: IListing) => Promise<boolean>;
  categories: ICategoryUserView[];
  handleRemoveCategoryAsync: (
    index: number,
    categoryId: string
  ) => Promise<void>;
  handleAddCategoryAsync: (categoryId: string) => Promise<boolean>;
  validationErrors: IIoiValidationError[];
};

function GeneralSettings(props: Props) {
  const {
    localListing,
    setLocalListing,
    updateListingAsync,
    categories,
    handleRemoveCategoryAsync,
    handleAddCategoryAsync,
    validationErrors
  } = props;

  const { errorToast } = useErrorToast();

  const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [listingName, setListingName] = useState<string | undefined>(
    localListing.name
  );
  const [isNameError, setIsNameError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const maxNameLength = 70;
  const maxDescriptionLength = 500;

  const updatedCategories = useMemo(() => {
    return categories.map((category) => {
      if (localListing.categories?.some((c) => c.id === category.id)) {
        return produce(category, (draft) => {
          draft.isGuideOrItemInCategory = true;
        });
      }
      return category;
    });
  }, [categories, localListing]);

  const updatedCategoriesCount = updatedCategories.filter(
    (uc) => uc.isGuideOrItemInCategory
  ).length;

  async function handleOnEditNameAsync() {
    if (!listingName) {
      setErrorMessage(
        'No Listing name provided, please enter a name for your Listing'
      );
      setIsNameError(true);
      setListingName(localListing.name);
      return;
    }
    if (listingName.length > maxNameLength) {
      return;
    }
    setIsNameError(false);

    const listingToUpdate: IListing = {
      ...localListing,
      name: listingName
    };

    setIsLoading(true);

    const { data: nameLocationResult } =
      await listingOperations.checkNameLocationAsync(listingToUpdate);

    if (nameLocationResult !== NameLocationResult.Unique) {
      setErrorMessage(
        'Listing name already exists for this location. Please enter a different Listing name.'
      );
      setIsNameError(true);
      setIsLoading(false);
      return;
    }

    const isSuccessful = await updateListingAsync(listingToUpdate);
    setIsLoading(false);

    if (isSuccessful) {
      setIsEditNameModalOpen(false);
    } else {
      errorToast('Unable to edit listing.');
    }
  }

  function handleCloseNameChangeModal() {
    setIsEditNameModalOpen(false);
    setListingName(localListing.name);
    setIsNameError(false);
  }

  return (
    <div className="flex flex-col w-full">
      <div className="font-inter text-2xl font-bold">General Settings</div>
      <div className="flex flex-col w-full">
        <div className="mt-3 text-base font-inter font-bold text-primaryCharcoal">
          Business Name
        </div>
        <div className="flex flex-row w-full mt-2" style={{ height: '84px' }}>
          <div
            className="bg-theme3 rounded-lg flex flex-col items-start px-6 py-4 text-secondaryText"
            style={{ width: '85%', height: '54px', borderColor: '#0000001a' }}
          >
            {listingName}
          </div>
          <div className="border-r-2 border-r-gray ml-6"></div>
          <div
            style={{ width: '247px' }}
            className="ml-6 w-full text-sm font-inter font-normal flex-col flex"
          >
            Any change to your Business Name must be requested, as it will be
            subject to our review process.
            <span
              className="text-primary cursor-pointer"
              onClick={() => setIsEditNameModalOpen(true)}
            >
              Request change?
            </span>
          </div>
        </div>
      </div>

      {isEditNameModalOpen && (
        <ConfirmationPopup
          isModalOpen={isEditNameModalOpen}
          handleClickConfirmButton={handleOnEditNameAsync}
          closeModal={handleCloseNameChangeModal}
          popupLabel={'Edit Business Name'}
          confirmButtonText="Change Business Name"
          Icon={FaSave}
          isErrorButton={false}
          height="260px"
          isLoading={isLoading}
        >
          <div>
            <div className="text-left text-base font-inter text-primaryCharcoal font-bold">
              Listing Name
            </div>
            <Input
              isMandatory={true}
              value={listingName}
              placeholder="Enter listing name"
              size="lg"
              error={isNameError && errorMessage}
              showErrorText={true}
              onChange={(e) => {
                console.log(e.currentTarget.value);
                setListingName(e.currentTarget.value);
              }}
              maxCharacterCount={maxNameLength}
            />
          </div>
        </ConfirmationPopup>
      )}

      <div className="mt-6 flex flex-col w-full">
        <div className="mt-3 text-base font-inter font-bold text-primaryCharcoal">
          Short Description
        </div>
        <div className="flex flex-row w-full mt-2" style={{ height: '136px' }}>
          <div style={{ width: '85%' }}>
            <Input
              value={localListing?.description}
              className="-mt-2 overflow-y-auto"
              multiline
              placeholder="Enter a short description of your business"
              size="xl"
              onBlurWithinCharacterLimit={async () =>
                await updateListingAsync(localListing)
              }
              onChange={(e) =>
                setLocalListing({
                  ...localListing,
                  description: e.currentTarget.value
                })
              }
              maxCharacterCount={maxDescriptionLength}
              resizable={false}
            />
          </div>
          <div className="border-r-2 border-r-gray ml-6"></div>
          <div
            style={{ width: '247px' }}
            className="ml-6 w-full text-sm font-inter font-normal"
          >
            Your short description should be a fun, punchy introduction or
            explanation about your business. Please try to keep this to a
            minimum of 3 - 5 sentences long.
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full">
        <div className="mt-3 text-base font-inter font-bold text-primaryCharcoal">
          Category
        </div>
        <div
          className="flex flex-row w-full mt-2"
          style={{ minHeight: '134px' }}
        >
          <div className="flex flex-col relative" style={{ width: '85%' }}>
            <div className="-ml-1">
              <CategorySelector
                categories={updatedCategories}
                handleRemoveCategoryAsync={handleRemoveCategoryAsync}
                handleAddCategoryAsync={handleAddCategoryAsync}
                pillWidth="385px"
                validationErrors={validationErrors}
              />
            </div>

            <div className="text-base font-inter font-normal text-primaryCharcoal absolute top-32 right-4">
              {` ${updatedCategoriesCount}/2 categories`}
            </div>
          </div>

          <div className="border-r-2 border-r-gray ml-6"></div>
          <div
            style={{ width: '247px' }}
            className="ml-6 w-full text-sm font-inter font-normal"
          >
            Select up to two categories that are relevant to your business.
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralSettings;
