export enum ErrorType {
  Category = 'Category',
  Image = 'Image',
  Tag = 'Tag',
  StartDate = 'Start Date',
  EndDate = 'End Date',
  OpeningTime = 'Opening Time',
  ClosingTime = 'Closing Time',
  TimeExplanation = 'Time Explanation',
  UrlExplanation = 'Url Explanation',
  Description = 'Description',
  Name = 'Name',
  Address = 'Valid Address',
  Location = 'Location',
  ContactNumber = 'ContactNumber'
}

export interface IIoiValidationError {
  tabMessage: string;
  type: ErrorType;
  name?: string;
}
