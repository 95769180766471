import React from 'react';
import { ITab } from './TabList';

type Props = {
  activeTabName: string;
  index: number;
  tab: ITab;
  onClickTab: (tab: ITab) => void;
};

function TabComponent(props: Props) {
  const { tab, activeTabName, index, onClickTab } = props;

  const isActive = tab.name === activeTabName;

  return (
    <div
      className="flex flex-row justify-start h-auto"
      key={index}
      onClick={() => onClickTab(tab)}
    >
      <div
        className={`flex flex-row items-start px-6 py-3 cursor-pointer
          ${isActive ? 'bg-onSurface' : 'bg-theme3 hover:bg-theme9'}
            ${index > 0 && 'ml-0.5'}`}
        style={{ borderRadius: '8px 8px 0px 0px' }}
      >
        <div>{tab.icon}</div>
        <div
          className={`ml-2 text-white ${
            isActive ? 'text-white' : 'text-onSurfaceVariant'
          }
              font-inter text-base font-medium`}
        >
          {tab.name}
        </div>
      </div>
    </div>
  );
}

export default TabComponent;
