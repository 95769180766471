import { useOutsideClick } from '@bindystreet/bindystreet.kit.react';
import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import EntityImage from 'components/shared/EntityImage';
import ToggleIsActiveComponent from 'components/shared/ToggleIsActiveComponent';
import dayjs from 'dayjs';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useRef, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { FaCog, FaPen } from 'react-icons/fa';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { RiMoneyPoundBoxFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'utility/general/formatHelpers';
import {
  getBaseEventRoute,
  getBaseListingRoute
} from 'utility/general/routeHelpers';

export function getEditEventRoute(event: IEvent, isSuperAdmin: boolean) {
  return getBaseEventRoute(event, isSuperAdmin) + '/general';
}

function getEditEventPromotionRoute(event: IEvent, isSuperAdmin: boolean) {
  return getBaseEventRoute(event, isSuperAdmin) + '/promotions';
}
function getEditListingRoute(listing: IListing, isSuperAdmin: boolean) {
  return getBaseListingRoute(listing, isSuperAdmin) + '/general';
}

type Props = {
  event: IEvent;
  listing?: IListing;
  onClickDelete: () => void;
  width?: string;
  //NOTE: hasPromotion is nullable to provide a third option.
  //True - "Promotions", False - "No Promotions", null - ""
  hasPromotion?: boolean;
};

function EventCard(props: Props) {
  const { event, listing, onClickDelete, width, hasPromotion } = props;
  const { isSuperAdmin } = useContext(UserContext);

  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setIsSettingsOpen(false));

  function onClickEdit() {
    navigate(getEditEventRoute(event, isSuperAdmin));
  }

  function onClickEditPromotion(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    navigate(getEditEventPromotionRoute(event, isSuperAdmin));
  }
  function onClickListing(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    event.listing && navigate(getEditListingRoute(event.listing, isSuperAdmin));
  }

  function cardTop() {
    return (
      <div className="py-4 px-4" style={{ height: '107px' }}>
        <div className="truncate flex flex-row rounded items-center">
          <div className="mr-4">
            <EntityImage entity={event} imageProportion={80} />
          </div>
          <div className="w-80 h-full font-bold text-lg">{event.name}</div>
          <div className="flex flex-grow" />
          {hasPromotion !== undefined && (
            <div
              className="flex flex-row border-2 rounded-lg border-grayBorder py-1 px-1.5"
              onClick={onClickEditPromotion}
            >
              <RiMoneyPoundBoxFill
                className="rounded mr-0.5"
                size={28}
                style={{ color: '#EF8088' }}
              />
              <span className="mt-0.5 ml-0.5">
                {hasPromotion ? '' : 'No'} Promotions
              </span>
              <MdKeyboardArrowRight size={24} className="mt-0.5" />
            </div>
          )}
        </div>
      </div>
    );
  }

  function cardBottom() {
    return (
      <div
        className="flex flex-row items-center border-black border-opacity-10 border-t-2 w-full px-2"
        style={{ height: '56px' }}
      >
        {listing && (
          <div
            className="w-40 border-r-2 border-black border-opacity-10 truncate pt-2 text-black font-inter font-bold"
            style={{ height: '56px', width: '232px' }}
            onClick={onClickListing}
          >
            <div className="hover:bg-gray-300 flex flex-row py-1 my-1 rounded mr-2">
              <div style={{ width: 34 }} className="ml-1.5">
                <EntityImage entity={event.listing!} imageProportion={24} />
              </div>
              <div className="truncate" style={{ width: 120 }}>
                {event.listing?.name}
              </div>
            </div>
          </div>
        )}
        <div
          className="flex flex-row items-center pl-3 relative w-full pr-2"
          style={{ height: '56px' }}
        >
          <ToggleIsActiveComponent isActive={event.isActive} />
          {dayjs(event.endDate).isBefore(dayjs()) ? (
            <div className="font-normal text-xs ml-2 mt-0.5 text-inactiveRed">
              Expired
            </div>
          ) : (
            event.startDate && (
              <div className="font-normal text-xs ml-2 mt-0.5 text-secondaryText">
                {`From ${formatDate(event?.startDate!)}`}
              </div>
            )
          )}
          <div className="flex-grow" onClick={onClickEdit} />
          <FaCog
            className="text-secondaryText cursor-pointer"
            size={16}
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
          />
          {isSettingsOpen && (
            <div
              className="absolute bg-white rounded shadow-md bottom-10 right-0 border p-1 text-sm z-10"
              style={{ width: 192, height: 80 }}
              ref={ref}
            >
              <div
                className="flex flex-row w-full hover:bg-error5 hover:text-white px-1 rounded pb-1 cursor-pointer"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => {
                  setIsSettingsOpen(false);
                  onClickDelete();
                }}
              >
                <AiFillDelete
                  className={`ml-2 ${
                    isHover ? 'text-white' : 'text-primaryCharcoal'
                  } mt-2`}
                  size={14}
                />
                <div className="ml-2 pt-1.5">Delete</div>
              </div>
              <div className=" border-b-2 mt-1"></div>
              <div
                className="flex flex-row w-full mt-1 py-1 pl-1 cursor-pointer rounded hover:bg-gray-300"
                onClick={() => {
                  onClickEdit();
                  setIsSettingsOpen(false);
                }}
              >
                <FaPen className="ml-2 text-primaryCharcoal mt-1" size={12} />
                <div className="ml-2 pl-0.5">Edit</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className="flex flex-row bg-white border rounded-xl border-grayBorder mt-2 hover:shadow-lg cursor-pointer transition-all duration-200"
      style={{ width: width, userSelect: 'none' }}
      onClick={onClickEdit}
    >
      <div className="flex flex-col font-nunito w-full">
        {cardTop()}
        {cardBottom()}
      </div>
    </div>
  );
}

export default EventCard;
