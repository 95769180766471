import {
  ClaimRequestStatus,
  IBaseListingRequest
} from 'Colugo/interfaces/business/IBaseListingRequest';
import { IBusinessRequest } from 'Colugo/interfaces/business/IBusinessRequest';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import { swrRequestResponse } from 'Colugo/provider/useGetHttpClient';
import ManagerClaimRequestSearchBar from 'components/shared/ManagerClaimRequestSearchBar';
import { ITab } from 'components/shared/TabList';
import TopTab from 'components/shared/TopTab';
import { useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AcceptedRejectedRequests from './AcceptedRejectedRequests';
import NewRequests from './NewRequest';
import RequestsSearchResult from './RequestsSearchResult';

export enum ManageAccountSection {
  None,
  BusinessRequests,
  BusinessClaims
}

export enum ClaimOrRequestTabNames {
  New = 'New',
  Accepted = 'Accepted',
  Rejected = 'Rejected'
}

export const tabs: ITab[] = [
  {
    name: ClaimOrRequestTabNames.New,
    path: PuffinRoutes.NewBusinessClaimOrRequests
  },
  {
    name: ClaimOrRequestTabNames.Accepted,
    path: PuffinRoutes.AcceptedBusinessClaimOrRequests
  },
  {
    name: ClaimOrRequestTabNames.Rejected,
    path: PuffinRoutes.RejectedBusinessClaimOrRequests
  }
];

type Props<T> = {
  useListingRequestsAsync(
    claimRequestStatus: ClaimRequestStatus,
    take?: number,
    cursorId?: string
  ): swrRequestResponse<T[]>;
  accountSection: ManageAccountSection;
  pageName: string;
};

function ListingClaimsOrRequests<T extends IBaseListingRequest>({
  useListingRequestsAsync,
  accountSection,
  pageName
}: Props<T>) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredData, setFilteredData] = useState<IBusinessRequest[]>([]);

  const {
    data: businessOrClaimRequests,
    isLoading: isLoadingNewRequests,
    isError: isErrorNewRequests,
    mutate: mutateNewRequests
  } = useListingRequestsAsync(ClaimRequestStatus.Requested);

  const {
    data: acceptedBusinessOrClaimRequests,
    isLoading: isLoadingAcceptedRequests,
    isError: isErrorAcceptedequests,
    mutate: mutateAcceptedRequests
  } = useListingRequestsAsync(ClaimRequestStatus.Approved);

  const {
    data: rejectedBusinessOrClaimRequests,
    isLoading: isLoadingRejectedRequests,
    isError: isErrorRejectedequests,
    mutate: mutateRejectedRequests
  } = useListingRequestsAsync(ClaimRequestStatus.Rejected);

  const currentTabName =
    tabs.find((tab) => location.pathname.includes(tab.path))?.name ?? '';

  const allBusinessOrClaimRequests = businessOrClaimRequests?.concat(
    acceptedBusinessOrClaimRequests ?? [],
    rejectedBusinessOrClaimRequests ?? []
  );

  function handleOnClickTab(tab: ITab) {
    navigate(`${tab.path}`);
  }

  function getTabCount(tabName: string) {
    switch (tabName) {
      case ClaimOrRequestTabNames.New:
        return `${businessOrClaimRequests?.length.toString() ?? '0'}`;
      case ClaimOrRequestTabNames.Accepted:
        return `${acceptedBusinessOrClaimRequests?.length.toString() ?? '0'}`;
      case ClaimOrRequestTabNames.Rejected:
        return `${rejectedBusinessOrClaimRequests?.length.toString() ?? '0'} `;
      default:
        return '0';
    }
  }

  return (
    <div className="pb-10 w-full h-auto" style={{ width: '1000px' }}>
      <div className="fixed w-full bg-theme1 pt-8" style={{ width: '1000px' }}>
        <p className="font-inter text-background text-3xl font-bold">
          {pageName}
        </p>
        <div className="w-full">
          <ManagerClaimRequestSearchBar<IBusinessRequest>
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setFilteredData={setFilteredData}
            allRequestsOrClaims={allBusinessOrClaimRequests ?? []}
          />
        </div>
        {searchQuery.length === 0 && (
          <>
            <div className="flex flex-row mt-4 w-full">
              {tabs.map((tab, i) => (
                <TopTab
                  key={i}
                  tab={tab}
                  index={i}
                  activeTabName={currentTabName}
                  tabName={tab.name}
                  tabItemsCount={getTabCount(tab.name)}
                  onClickTab={() => handleOnClickTab(tab)}
                />
              ))}
            </div>
            <div
              className="flex-grow w-full border-b border-outline"
              style={{ marginTop: '-2px' }}
            />
          </>
        )}
      </div>
      {searchQuery.length > 0 ? (
        <div className="mt-40">
          <RequestsSearchResult<IBusinessRequest>
            searchResults={filteredData}
          />
        </div>
      ) : (
        <div className="mt-56">
          <div className="bg-theme1 flex flex-col items-start w-full">
            <Routes>
              <Route
                path={PuffinRoutes.NewBusinessClaimOrRequests}
                element={
                  <NewRequests<T>
                    manageAccountSection={accountSection}
                    requests={businessOrClaimRequests}
                    isLoadingRequests={isLoadingNewRequests}
                    isError={isErrorNewRequests}
                    mutateNewRequests={mutateNewRequests}
                    mutateAcceptedRequests={mutateAcceptedRequests}
                    mutateRejectedRequests={mutateRejectedRequests}
                  />
                }
              />
              <Route
                path={PuffinRoutes.AcceptedBusinessClaimOrRequests}
                element={
                  <AcceptedRejectedRequests<T>
                    manageAccountSection={accountSection}
                    requests={acceptedBusinessOrClaimRequests}
                    isLoadingRequests={isLoadingAcceptedRequests}
                    isError={isErrorAcceptedequests}
                  />
                }
              />
              <Route
                path={PuffinRoutes.RejectedBusinessClaimOrRequests}
                element={
                  <AcceptedRejectedRequests<T>
                    manageAccountSection={accountSection}
                    requests={rejectedBusinessOrClaimRequests}
                    isLoadingRequests={isLoadingRejectedRequests}
                    isError={isErrorRejectedequests}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListingClaimsOrRequests;
