import { IBusinessClaim } from 'Colugo/interfaces/business/IBusinessClaim';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import ClaimOperations from 'Colugo/operations/claims/ClaimOperations';
import CustomFormHeader from 'components/shared/CustomFormHeader';
import ListingCard from 'components/shared/ListingCard';
import { ManagerContext } from 'provider/manager/managerProvider';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { container } from 'tsyringe';
import { validateFields } from 'utility/general/validators';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import BusinessRequestClaimFooter, {
  BusinessClaimOrRequestInput,
  BusinessValidationErrors,
  businessValidationRequiredErrors
} from '../common/BusinessRequestClaimFooter';
import ThankYou from './ThankYou';

const claimOperations = container.resolve(ClaimOperations);

function SubmitClaim() {
  // Hooks
  const { errorToast } = useErrorToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { mutateOwnsClaimsRequests, businessOwnsClaimsRequests } =
    useContext(ManagerContext);

  // State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showThankYou, setShowThankYou] = useState<boolean>(false);
  const [errors, setErrors] = useState<BusinessValidationErrors | null>(null);
  const [claim, setClaim] = useState<BusinessClaimOrRequestInput>({
    isYourBusiness: null,
    businessConnection: '',
    hasAlreadyContacted: null,
    clarification: ''
  });

  const listing = location.state;

  async function handleSubmitAsync() {
    setErrors(null);

    const required = (
      claim.isYourBusiness
        ? Object.keys(claim).filter((i) => i !== 'businessConnection')
        : Object.keys(claim)
    ) as (keyof BusinessClaimOrRequestInput)[];

    const errors = validateFields(
      claim,
      required,
      businessValidationRequiredErrors
    );

    if (Object.keys(errors).length > 0) {
      return setErrors(errors as unknown as BusinessValidationErrors);
    }
    setIsLoading(true);
    const { data: createdClaim, error } = await claimOperations.createAsync({
      ...claim,
      listing
    } as IBusinessClaim);

    if (!createdClaim || error) {
      errorToast('An error occured, please try again');
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    mutateOwnsClaimsRequests({
      ...businessOwnsClaimsRequests,
      claims: [
        ...businessOwnsClaimsRequests.claims,
        { ...createdClaim, listing: listing }
      ]
    });
    setShowThankYou(true);
  }

  useEffect(() => {
    if (!listing) {
      return navigate(PuffinRoutes.ManageBusiness);
    }
  }, [listing, navigate]);

  if (!listing) {
    return <></>;
  }

  if (showThankYou) {
    return <ThankYou type={'Claim'} />;
  }

  return (
    <div className="flex flex-col bg-pageColour h-screen max-h-screen overflow-y-hidden">
      <CustomFormHeader title="Submit Claim" isActionLoading={isLoading} />
      <div className="overflow-y-auto">
        <div className="flex-col mx-auto" style={{ maxWidth: '714px' }}>
          <div
            className="mt-10 text-lg font-inter font-normal text-onSurfaceVariant"
            style={{ lineHeight: '22px' }}
          >
            <div>
              <p className="font-inter text-onSurfaceVariant font-mesium text-lg">
                Please note, information gathered during a claim will only be
                used internally for verification purposes, and will not be shown
                publicly without express consent.
              </p>
            </div>
            <div className="mt-6">
              <p className="font-inter text-onSurface font-bold text-base mb-2">
                Submit Claim
              </p>
              {listing && (
                <ListingCard
                  listing={listing}
                  key={listing.id}
                  styles={{ width: '100%' }}
                  containerClassNames="py-4 border-0 mb-3 justify-between items-center"
                />
              )}
            </div>
            <BusinessRequestClaimFooter
              setClaimOrRequestInput={setClaim}
              claimOrRequestInput={claim}
              errors={errors}
              onPressSubmit={async () => await handleSubmitAsync()}
              isSubmitLoading={isLoading}
              type="Claim"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmitClaim;
