import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';

export function getBaseEventRoute(event: IEvent, isSuperAdmin: boolean) {
  return isSuperAdmin
    ? PuffinRoutes.EditEvent.split(':')[0] + event.id
    : PuffinRoutes.ManagerEditEvent.split(':')[0] + event.id;
}

export function getBaseListingRoute(listing: IListing, isSuperAdmin: boolean) {
  return isSuperAdmin
    ? PuffinRoutes.EditListing.split(':')[0] + listing.id
    : PuffinRoutes.ManagerEditEvent.split(':')[0] + listing.id;
}
